import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DbService } from '../storage/db.service';
import { ApiService } from '../api-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JsonPipe } from '@angular/common';
import * as FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root'
})

/*
 *  Handles requests and responses with the REST-Service
 */
export class ProjectService {

  constructor(public router: Router, private storage: DbService, private api: ApiService, private dbService: DbService, private http: HttpClient) { }
  private user: any;


  public uploadSignature(json) {
    let data = [{
      "signature": json
    }]

    return this.api.post('update/signature', data);
  }
  async getCustomers(user, customerList) {
    let data = [{
      "email": JSON.parse(user).email,
      "customerList": customerList,
    }]

    let customerData = await this.api.post('getUserCustomers', data).toPromise();
    return customerData;
  }

  async setUser(user) {
    this.user = user;
    return "done"
  }

  getUser() {
    console.log(this.user)
    return this.user;
  }
  getUserId() {
    if (undefined == this.user) {
      return this.user.id
    }
    return this.user.id
  }
  async getTax() {
    let data;
    let tax = await this.api.post('getCurrentTax', data).toPromise();
    return tax
  }
  async getGuarantee() {
    let data;
    let tax = await this.api.post('getGuarantee', data).toPromise();
    console.log(tax)
    return tax
  }

  async getPDFs() {
    let pdfSrcSecurity = "https://salesapp-backend-test.trianel-va.de/security";
    let pdfSrcRefusal = "https://salesapp-backend-test.trianel-va.de/refusal";
    let pdfSrcProductInformation = "https://salesapp-backend-test.trianel-va.de/productInformation";
    let pdfSrcAgb = "https://salesapp-backend-test.trianel-va.de/agb";
    let pdfSrcAdvertisement = "https://salesapp-backend-test.trianel-va.de/advertisement";
    let pdfSrcpdfSrcAgbGas = "https://salesapp-backend-test.trianel-va.de/agb/gas";
    // this.dbService.setObject("pdfSrcSecurity", pdfSrcSecurity)
    // this.dbService.setObject("pdfSrcRefusal", pdfSrcRefusal)
    // this.dbService.setObject("pdfSrcProductInformation", pdfSrcProductInformation)
    // this.dbService.setObject("pdfSrcAgb", pdfSrcAgb)
    // this.dbService.setObject("pdfSrcAdvertisement", pdfSrcAdvertisement)
    // this.dbService.setObject("pdfSrcpdfSrcAgbGas", pdfSrcpdfSrcAgbGas)
    this.http.post('https://salesapp-backend-test.trianel-va.de/security', '', { responseType: 'blob' }).subscribe(res => {
      var blob = new Blob([res], { type: "application/pdf;charset=utf-8" });
      let reader = new FileReader();
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        this.dbService.setObject("pdfSrcSecurity", reader.result.toString())
      }
      return
    })
    this.http.post('https://salesapp-backend-test.trianel-va.de/refusal', '', { responseType: 'blob' }).subscribe(res => {
      var blob = new Blob([res], { type: "application/pdf;charset=utf-8" });
      let reader = new FileReader();
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        this.dbService.setObject("pdfSrcRefusal", reader.result.toString())
      }
      return
    })
    this.http.post('https://salesapp-backend-test.trianel-va.de/productInformation', '', { responseType: 'blob' }).subscribe(res => {
      var blob = new Blob([res], { type: "application/pdf;charset=utf-8" });
      let reader = new FileReader();
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        this.dbService.setObject("pdfSrcProductInformation", reader.result.toString())
      }
      return
    })
    this.http.post('https://salesapp-backend-test.trianel-va.de/agb', '', { responseType: 'blob' }).subscribe(res => {
      var blob = new Blob([res], { type: "application/pdf;charset=utf-8" });
      let reader = new FileReader();
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        this.dbService.setObject("pdfSrcAgb", reader.result.toString())
      }
      return
    })
    this.http.post('https://salesapp-backend-test.trianel-va.de/advertisement', '', { responseType: 'blob' }).subscribe(res => {
      var blob = new Blob([res], { type: "application/pdf;charset=utf-8" });
      let reader = new FileReader();
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        this.dbService.setObject("pdfSrcAdvertisement", reader.result.toString())
      }
      return
    })
    this.http.post('https://salesapp-backend-test.trianel-va.de/agb/gas', '', { responseType: 'blob' }).subscribe(res => {
      var blob = new Blob([res], { type: "application/pdf;charset=utf-8" });
      let reader = new FileReader();
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        this.dbService.setObject("pdfSrcpdfSrcAgbGas", reader.result.toString())
      }
      return
    })
    this.http.post('https://salesapp-backend-test.trianel-va.de/terms-of-use', '', { responseType: 'blob' }).subscribe(res => {
      var blob = new Blob([res], { type: "application/pdf;charset=utf-8" });
      let reader = new FileReader();
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        this.dbService.setObject("pdfSrcTermsOfUse", reader.result.toString())
      }
      return
    })
  }

  async uploadCustomerContract(customer) {
    let user = this.getUser()
    let mail;
    if (undefined == this.user) {
      mail = this.user.email;
    } else {
      mail = user.email;
    }
    let data = [{
      "customer": customer,
      "userMail": mail,
      "sendCustomerContract": false,
    }]

    let response = await this.api.post('uploadCustomerContract', data).toPromise();
    return response;
  }
  async sendCustomerContract(customer,product) {
    let user = this.getUser()
    let mail;
    if (undefined == this.user) {
      mail = this.user.email;
    } else {
      mail = user.email;
    }
    let data = [{
      "customer": customer,
      "userMail": mail,
      "product": product,
      "sendCustomerContract": true,
    }]
    let response = await this.api.post('sendCustomerContract', data).toPromise();
    return response;
  }

  async checkHashs(selector, validator) {
    let data = [{
      "selector": selector,
      "validator": validator,
    }]
    let response = await this.api.post('checkHashs', data).toPromise();
    return response;
  }
  async getConfirmData(selector, validator){
    let data = [{
      "selector": selector,
      "validator": validator,
    }]
    let response = await this.api.post('getConfirmData', data).toPromise();
    return response;
  }
  async confirmContract(selector, validator){
    let data = [{
      "selector": selector,
      "validator": validator,
    }]
    let response = await this.api.post('confirmContract', data).toPromise();
    return response;
  }
  async savePictures(customer,product){
    let data = [{
      "customer": customer,
      "user": this.user,
      "product": product
    }]
    let response = await this.api.post('savePictures', data).toPromise();
    return response;
  }
}
