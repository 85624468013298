<ion-app>
    <ion-content class="has-header">
        <div class="page-content grey_bubble_background">
        <img class="top-logo-2020" src="../../assets/images/top-logo.jpg">
        <div class="p-1" *ngIf="generateLink">
            <h2>Auftragsübermittlung</h2>
            <p class="margin-right">Ihr individueller Auftrag wird sicher an die Server der Stadtwerke Hamm übermittelt. Im Anschluss erhalten Sie eine Zusammenfassung Ihres Auftrags und aller angegebenen Daten.</p>
        </div>
        <div class="p-1" *ngIf="!generateLink">
            <h2>Bestätigungslink senden</h2>
            <p class="margin-right">Ihr individueller Bestätigungslink wird an die von Ihnen angegebene E-Mail-Adresse „{{customer.email}}“ versendet. Der Link ist zwei Wochen gültig.</p>
        </div>
        <div class="ion-text-center">
            <img class="ghost" src="../../assets/images/transmission_ghost.svg">
        </div>
        <div class="ion-text-center">
            <ion-button *ngIf="generateLink" mode="md" (click)="syncAndRoute(true)">AUFTRAGSÜBERMITTLUNG</ion-button>
            <ion-button *ngIf="!generateLink" mode="md" (click)="syncAndRoute(false)">BESTÄTIGUNGSLINK SENDEN</ion-button>

        </div>
    </div>
    </ion-content>
</ion-app>