import { Component, Input, NgModule } from '@angular/core';
import { fabric } from 'fabric';
import { ModalController } from '@ionic/angular';
import { OnInit } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { DbService } from 'src/services/storage/db.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from '../components/components.module';
import { BrowserModule } from '@angular/platform-browser';
import { GlossarPageRoutingModule } from '../glossar/glossar-routing.module';
import { ProjectService } from 'src/services/project/project-service';
import { Router } from '@angular/router';
@Component({
  selector: 'bonus-modal',
  templateUrl: './bonus.modal.html',
  styleUrls: ['./bonus.modal.scss'],

})
  /*
   *  Handles adding of Bonus as well as the calculation of price-comparison
   */
export class BonusModal {
  // Data passed in by componentProps
  @Input() modalType: string;

  constructor(private modalController: ModalController, private dbService: DbService,private projectService: ProjectService,private router: Router) {
  }
  public bonus: any;

  netto: any;
  nettoRAM: any;
  nettoBool: Boolean;
  workPrice: any;
  basePrice: any;
  fullPrice: any;

  wpPerYear: any;
  result: any;
  productName: string ="";
  ngOnInit() {
    this.netto = 0;
    this.nettoBool = false;
    
    this.projectService.getTax().then(data => {
      //@ts-ignore
      this.nettoRAM = data;
    });
  }
  ionViewWillEnter() {


    this.dbService.getObject("selectedProduct"+this.projectService.getUserId()).then(product => {
      this.productName = product.name;
      switch (product.product_type.name) {

        case "Strom":
          this.bonus = 50;
          break;
        case "Erdgas":
          this.bonus = 95;
          break;
      }
    })
  }
  dismiss() {
    this.modalController.dismiss({
      });
  }

  changeNetto(event) {
    if(this.nettoBool == true) {
      this.netto = this.nettoRAM;
      console.log('Ja');
    } else {
      this.netto = 0;
      console.log('Nö');
    }
    console.log(this.nettoBool);
    console.log(this.netto);
  }

  addBonus() {
    this.dbService.getObject("selectedProduct"+this.projectService.getUserId()).then(product => {
      console.log(product);
      if(!product.hasBonus || product.hasBonus == undefined) {
        product.bonus = this.bonus;
      }
      product.hasBonus = true
      this.dbService.setObject("selectedProduct"+this.projectService.getUserId(),product).then(d => {
        this.modalController.dismiss({
          'dismissed': true,
        });
      });
    })
  }

  async calculateUsage() {
      this.dbService.getObject("selectedProduct"+this.projectService.getUserId()).then(product => {
        if(this.fullPrice == null){
          if(this.workPrice == null ||this.basePrice == null){
            alert("Sie müssen die Felder Arbeitspreis und Grundpreis befüllen. Alternativ können Sie auch nur den Gesamtpreis angeben");
            return;
          }
        }else if(this.workPrice == null && this.basePrice == null){
          if(this.fullPrice == null){
            alert("Sie müssen die Felder Arbeitspreis und Grundpreis befüllen. Alternativ können Sie auch nur den Gesamtpreis angeben");
            return;
          }
        }
        console.log("shouldnt land here")
        product.workPrice = this.workPrice;
        product.basePrice = this.basePrice;
        product.netto = this.netto;
        if(this.fullPrice) {
          product.fullPrice = this.fullPrice;
        } else {
          product.fullPrice = (this.workPrice/100) * product.kwh + this.basePrice;
        }
        console.log(product)
        this.dbService.setObject("selectedProduct"+this.projectService.getUserId(),product);
        this.wpPerYear = this.workPrice * product.kwh;
        this.result = this.wpPerYear + this.basePrice;

        this.router.navigate(['/price-comparison']);      
        this.modalController.dismiss({
          'dismissed': true,
        });
      })
  }
  
}