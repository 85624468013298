import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProjectService } from 'src/services/project/project-service';

@Component({
  selector: 'app-confirm-link',
  templateUrl: './confirm-link.page.html',
  styleUrls: ['./confirm-link.page.scss'],
})
export class ConfirmLinkPage implements OnInit {

  selector;
  validator;
  valid = null;
  name;
  product;
  base_price;
  work_price;
  meter_number;
  price_maintenance;


  constructor(private router: Router, private route: ActivatedRoute, private projectService: ProjectService) {
    this.route.queryParams.subscribe(params => {
      this.selector = params['selector'];
      this.validator = params['validator']
    });
  }

  ngOnInit() {
    this.projectService.checkHashs(this.selector, this.validator).then(value => {
      if (value == true) {
        this.projectService.getConfirmData(this.selector, this.validator).then(data => {
          this.name = data['name'] + " " + data['surname'];
          this.product = data['product'][0]["name"];
          this.base_price = data['product'][0]["pivot"]["base_price"].toString().replace(".",",");
          this.work_price = data['product'][0]["pivot"]["labour_price"].toString().replace(".",",");
          this.price_maintenance = "Ja";
          this.price_maintenance = data['price_guarantee'];
          this.valid = value;
        });
      } else {
        this.valid = value;
      }
    })
  }
  /**
   * It takes the selector and validator from the form and sends them to the projectService, which then
   * sends them to the backend
   */
  sendContract() {
    this.projectService.confirmContract(this.selector, this.validator).then(value => {
      if (value == true) {
        this.router.navigate(['/contract-success']);
      } else {
        alert("Hier ist etwas schief gelaufen. Bitte probieren Sie es spter noch einmal oder wenden Sie sich an unseren Support")
      }
    });
  }

}
