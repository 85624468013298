import { Component, OnInit } from '@angular/core';
import { DbService } from 'src/services/storage/db.service';
import { Router } from '@angular/router';
import { ProjectService } from 'src/services/project/project-service';

@Component({
  selector: 'app-conclusion',
  templateUrl: './conclusion.page.html',
  styleUrls: ['./conclusion.page.scss'],
})
export class ConclusionPage implements OnInit {
  customer:any = [{
      products: [],
  }];


  constructor(private dbService: DbService, private router: Router,private projectService: ProjectService) { }
 

  ngOnInit() {
  }
  /*
   *  Fill Contractlist and calculate/parse existing prices to correct format
   */
  ionViewWillEnter() {
      this.dbService.getObject("currentCustomer"+this.projectService.getUserId()).then(customer => {
          console.log(customer)
          customer.products.forEach(product => {
            console.log(product)
              if(undefined != product.bonus){
                let tmp;
                tmp = product.price.replace(/[^\d\,\-]/g, "");
                tmp = tmp.replace(',','.');
                tmp = parseFloat(tmp) - product.bonus;
                if(new Intl.NumberFormat('de-DE').format(tmp) == 'NaN' ){
                  product.price = tmp.toLocaleString("de-DE", {maximumFractionDigits: 2,minimumFractionDigits: 2});;
                }else{
                  console.log(tmp);
                  product.price = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR'}).format(tmp);
                }
            }else{
                if(new Intl.NumberFormat('de-DE').format(product.price) == 'NaN' ){
                  product.price = product.price.toLocaleString("de-DE", {maximumFractionDigits: 2,minimumFractionDigits: 2});;
                }else{
                  product.price = new Intl.NumberFormat('de-DE' , { style: 'currency', currency: 'EUR'}).format(product.price);
                }

            }
            product.labour_price = +product.labour_price
            product.base_price = +product.base_price
            product.labour_price = product.labour_price.toFixed(2).toString().replace('.',',').toLocaleString("de-DE", {maximumFractionDigits: 2,minimumFractionDigits: 2});
            product.base_price = product.base_price.toFixed(2).toString().replace('.',',').toLocaleString("de-DE", {maximumFractionDigits: 2,minimumFractionDigits: 2});
          });
          this.customer = customer;
      })
  }

  ionViewDidEnter() {
    this.dbService.setObject("currentPage"+this.projectService.getUserId(),this.router.url);
  }

}
