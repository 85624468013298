import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AppointmentListPage } from './appointment-list/appointment-list.page';
import { BenefitsPage } from './benefits/benefits.page';
import { CalculatorPage } from './calculator/calculator.page';
import { CheckoutPage } from './checkout/checkout.page';
import { ConclusionPage } from './conclusion/conclusion.page';
import { ConfirmLinkPage } from './confirm-link/confirm-link.page';
import { CustomerDataPage } from './customer-data/customer-data.page';
import { CustomerListPage } from './customer-list/customer-list.page';
import { DataTransferPage } from './data-transfer/data-transfer.page';
import { ExpertKnowledgePage } from './expert-knowledge/expert-knowledge.page';
import { ForgotPasswordPage } from './forgot-password/forgot-password.page';
import { GlossarPage } from './glossar/glossar.page';
import { HomePage } from './home/home.page';
import { ImpressumPage } from './impressum/impressum.page';
import { InfluencingFactorsPage } from './influencing-factors/influencing-factors.page';
import { LandingPagePage } from './landing-page/landing-page.page';
import { LoginPage } from './login/login.page';
import { PriceComparisonGasPage } from './price-comparison-gas/price-comparison-gas.page';
import { PriceComparisonPage } from './price-comparison/price-comparison.page';
import { PrivacyAgreementPage } from './privacy-agreement/privacy-agreement.page';
import { ProductDetailsPage } from './product-details/product-details.page';
import { ProductItemSelectionPage } from './product-item-selection/product-item-selection.page';
import { ProductSelectionPage } from './product-selection/product-selection.page';
import { TermsOfUsePage } from './terms-of-use/terms-of-use.page';
import { ViewContractPage } from './view-contract/view-contract.page';


  /*
   *  Handles routes in the whole Project
   *  Sets Start-Page
   */
const routes: Routes = [
  {
      path: '',
      component: HomePage
  },
  {
    path: 'login',
    component: LoginPage
  },
  {
    path: 'home',
    component: HomePage
  },
  {
    path: 'price-comparison',
    component: PriceComparisonPage
  },
  {
    path: 'price-comparison-gas',
    component: PriceComparisonGasPage
  },
  {
    path: 'glossar',
    component: GlossarPage
  },
  {
    path: 'benefits',
    component: BenefitsPage
  },
  {
    path: 'expert-knowledge',
    component:ExpertKnowledgePage
  },
  {
    path: 'product-selection/:type',
    component:ProductSelectionPage
  },
  {
    path: 'product-selection',
    component:ProductSelectionPage
  },
  {
    path: 'calculator/:type',
    component:CalculatorPage
  },
  {
    path: 'privacy-agreement',
    component:PrivacyAgreementPage
  },
  {
    path: 'customer-data',
    component:CustomerDataPage
  },
  {
    path: 'customer-list',
    component:CustomerListPage
  },
  {
    path: 'appointment-list',
    component:AppointmentListPage
  },
  {
    path: 'product-item-selection/:type',
    component:ProductItemSelectionPage
  },
  {
    path: 'product-details',
    component:ProductDetailsPage
  },
  {
    path: 'checkout',
    component:CheckoutPage
  },
  {
    path: 'data-transfer/:generateLink',
    component:DataTransferPage
  },
  {
    path: 'conclusion',
    component:ConclusionPage
  },
  {
    path: 'impressum',
    component:ImpressumPage
  },
  {
    path: 'influencing-factors',
    component:InfluencingFactorsPage
  },
  {
    path: 'landing-page',
    component:LandingPagePage
  },
  {
    path: 'forgot-password',
    component:ForgotPasswordPage
  },
  {
    path: 'terms-of-use',
    component:TermsOfUsePage
  },
  {
    path: 'view-contract',
    component: ViewContractPage
  },
  {
    path: 'confirm-link',
    component: ConfirmLinkPage
  },  {
    path: 'contract-success',
    loadChildren: () => import('./contract-success/contract-success.module').then( m => m.ContractSuccessPageModule)
  },
  {
    path: 'photo-contract',
    loadChildren: () => import('./photo-contract/photo-contract.module').then( m => m.PhotoContractPageModule)
  },
  {
    path: 'photo-conclusion',
    loadChildren: () => import('./photo-conclusion/photo-conclusion.module').then( m => m.PhotoConclusionPageModule)
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload',preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
