<ion-app>
    <ion-content class="has-header">
        <div class="page-content">
        <div class="p-1">
            <h2>Übersicht Vertragsabschluss & Wechselservice</h2>
            <p>Sobald alle im Rahmen der Auftragsbearbeitung erforderlichen Prüfprozesse abgeschlossen sind, werden wir 
                den Lieferantenwechsel mit allen Formalitäten für Sie abwickeln. Insbesondere werden wir, falls erforderlich, 
                Ihren aktuellen Vertrag bei Ihrem bisherigen Lieferanten kündigen und die Netznutzung bei Ihrem 
                zuständigen Netzbetreiber anmelden.</p>
        </div>
        <div class="content">
            <h2>Meine Produkte</h2>
            <ion-grid class="maingrid" *ngFor="let item of customer.products">
                <ion-row>
                    <ion-col size="4">
                        <ion-card mode="md">
                            <ion-card-header>
                                <ion-card-title class="ion-text-center">{{item.name}}</ion-card-title>
                                <ion-card-subtitle class="ion-text-center"></ion-card-subtitle>
                            </ion-card-header>

                            <ion-card-content class="ion-text-center">
                                <span class="ecoPrice ion-text-center">
                                    <span class="underline-fat">{{item.price}}</span><span class="year"> €/Jahr*</span><br>
                                    <span class="smaller">*Bei einem Jahresverbrauch von {{item.kwh}} kWh</span><br>
                                    <span class="smaller small-br" *ngIf="item.hasBonus == true">Preis beinhaltet {{item.bonus}} € Neukundenbonus im ersten Jahr</span>
                                </span>
                            </ion-card-content>
                            <ion-row class="cardfooter ion-text-center">
                                <ion-col>
                                    <ng-container *ngIf="item.eco == 0 || item.eco == 'Nein'; else elseTemplate">
                                        <span class="footerText">Standard Tarif</span>
                                    </ng-container>
                                    <ng-template #elseTemplate>
                                        <span class="footerText">Öko Tarif</span>
                                    </ng-template>
                                </ion-col>
                            </ion-row>
                        </ion-card>
                    </ion-col>
                    <ion-col size="8">
                        <h2 class="grey">{{item.name}}</h2>
                        <ng-container *ngIf="item.type_id == 3; else typeTemplate">
                            <ng-container *ngIf="item.eco == 0 || item.eco == 'Nein'; else ecoPower">
                                <p class="grey">hammerSTROM ist das Produkt für Kunden, die ein besonderes Augenmerk auf einen günstigen Preis haben. Außerdem ist hammerSTROM zu über 49 % regenerativ erzeugt.</p>
                            </ng-container>
                            <ng-template #ecoPower>
                                <p class="grey">hammerSTROMklima ist unser Ökostromprodukt. Hier haben Sie die Sicherheit, dass der 
                                    Strom zu 100 % aus regenerativen Energien erzeugt wird und damit CO<sub>2</sub>-frei ist. 
                                    hammerSTROMklima wird unter der Dachmarke Renewable Plus geführt und gemäß 
                                    den Kriterien der TÜV Rheinland zertifiziert. Das lassen wir uns jedes Jahr neu bestätigen. </p>
                            </ng-template>
                            
                        </ng-container>
                        <ng-template #typeTemplate>
                            <ng-container *ngIf="item.eco == 0 || item.eco == 'Nein' ; else ecoGas">
                                <p class="grey"> hammerGAS ist unser klassisches Erdgasprodukt für alle, die preiswert und zuverlässig versorgt werden möchten. Ob beim Waschen, Kochen oder Heizen: hammerGAS bietet vielseitige Einsatzmöglichkeiten.</p>
                            </ng-container>
                            <ng-template #ecoGas>
                                <p class="grey">hammerGASklima ist unser Ökogasprodukt. Hier haben Sie die Sicherheit, dass Sie sich für 100% Kohlenstoffdioxid neutrale Verbrennung des Erdgases in Ihrer Heizungsanlage entscheiden. hammerGASklima wird jährlich durch den TÜV Rheinland zertifiziert.</p>
                            </ng-template>
                            
                        </ng-template>
                        
                        <ion-row class="test">
                            <ion-col class="grey">Mein Tarif</ion-col>
                            <ion-col><b>{{item.name}}</b></ion-col>
                            <ion-col>
                                <ng-container *ngIf="item.hasBonus; else bonusTemplate">
                                    <ion-row class="product-price">{{item.price}} €/Jahr (inkl. {{item.bonus}} € Neukundenbonus im ersten Lieferjahr)</ion-row>
                                </ng-container>
                                <ng-template #bonusTemplate>
                                    <ion-row class="product-price justify-content-start">{{item.price}} €/Jahr</ion-row>
                                </ng-template>
                                <ion-row class="justify-content-start"><span>Arbeitspreis: {{item.labour_price}} Ct/kWh</span><br></ion-row>
                                <ion-row class="justify-content-start"><span>Grundpreis: {{item.base_price}} €/Jahr</span></ion-row>
                            </ion-col>
                        </ion-row>
                    </ion-col>
                </ion-row>
            </ion-grid>
            <ion-row class="ion-text-center">
                <ion-button  mode="md" [routerLink]="['/product-selection/cross-selling']" routerDirection="forward">Weiteres Produkt hinzufügen</ion-button>
            </ion-row>
        </div>
    </div>
    </ion-content>
</ion-app>