<ion-app>
    <ion-content class="has-header">
        <div class="page-content">
            <img class="green_bubble" src="../../assets/images/bubble-grey.svg">
            <div class="p-1">
                <h2>Ihre Vorteile auf einen Blick</h2>
                <p>Die Stadtwerke Hamm - Ihr zuverlässiger Energieversorger</p>
                
            </div>
            <div class="benefit">
                <div class="container" *ngIf="pageLoaded">
                    <img class="banner p-1" src="../../assets/images/benefits-content.svg">
                    <span id="infobox1"
                        (click)="openModal(tooltip1)" class="infobox1"><img class="glossar1"
                            src="../../assets/images/glossar-icon.svg">
                        <p>{{infoItems[0].title}}</p>
                    </span>
                    <span id="infobox2"
                        (click)="openModal(tooltip2)" positionV="bottom" class="infobox3"><img class="glossar1"
                            src="../../assets/images/glossar-icon.svg">
                        <p>{{infoItems[2].title}}</p>
                    </span>
                    <span id="infobox3"
                        (click)="openModal(tooltip3)" class="infobox2"><img class="glossar1"
                            src="../../assets/images/glossar-icon.svg">
                        <p>{{infoItems[1].title}}</p>
                    </span>
                    <span id="infobox4"
                        (click)="openModal(tooltip4)" class="infobox4"><img class="glossar1"
                            src="../../assets/images/glossar-icon.svg">
                        <p>{{infoItems[3].title}}</p>
                    </span>
                    <span id="infobox5"
                        (click)="openModal(tooltip5)" class="infobox5"><img class="glossar1"
                            src="../../assets/images/glossar-icon.svg">
                        <p>{{infoItems[4].title}}</p>
                    </span>
                    <span id="infobox6"
                        (click)="openModal(tooltip6)" class="infobox6"><img class="glossar1"
                            src="../../assets/images/glossar-icon.svg">
                        <p>{{infoItems[5].title}}</p>
                    </span>
                    <span id="infobox7"
                        (click)="openModal(tooltip7)" class="infobox7"><img class="glossar1"
                            src="../../assets/images/glossar-icon.svg">
                        <p>{{infoItems[6].title}}</p>
                    </span>
                    <span id="infobox8"
                        (click)="openModal(tooltip8)" class="infobox8"><img class="glossar1"
                            src="../../assets/images/glossar-icon.svg">
                        <p>{{infoItems[7].title}}</p>
                    </span>
                </div>
            </div>
        </div>
    </ion-content>
</ion-app>