import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DbService } from 'src/services/storage/db.service';
import { ProjectService } from 'src/services/project/project-service';

@Component({
  selector: 'app-price-comparison-gas',
  templateUrl: './price-comparison-gas.page.html',
  styleUrls: ['./price-comparison-gas.page.scss'],
})
export class PriceComparisonGasPage implements OnInit {
  guarantee;
  constructor(private router: Router, private dbService: DbService,private projectService: ProjectService) {
  }

  ngOnInit() {
    this.guarantee = this.dbService.getObject("guarantee");
  }
  ionViewDidEnter() {
    this.dbService.setObject("currentPage"+this.projectService.getUserId(),this.router.url);
  }
}
