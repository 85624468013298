<ion-app>
    <ion-content class="has-header">
        <div class="p-1">
            <h2>Termine</h2>
        </div>
            <div class="customerList">
                <ion-grid class="ion-margin-top grid-margin">
                    <ion-row>
                        <ion-col class="ion-text-start"><h3>Datum & Uhrzeit</h3></ion-col>
                        <ion-col class="ion-text-start table-header"><span >Kunde</span></ion-col>
                        <ion-col class="ion-text-center table-header"><span >Beratung starten</span></ion-col>
                        <ion-col class="ion-text-center table-header"><span >Gesprächsvorbereitung</span></ion-col>
                    </ion-row>
                    <ion-row *ngFor="let customer of customers;">
                        <ion-col class="ion-text-start">{{customer.formatted_appointment_date}}</ion-col>
                        <ion-col class="ion-text-start">{{customer.name}} {{customer.surname}}</ion-col>
                        <ng-container *ngIf= "currentCustomer != null && currentCustomer != false && customer.name == currentCustomer.name && customer.lastname == currentCustomer.lastname; else elseTemplate">
                            <ion-col class="ion-text-center" (click)="startConsultation(customer)"><img class="hover" src="../../assets/icon/icon-consultation-active.svg"></ion-col>
                        </ng-container>
                        <ng-template #elseTemplate>
                            <ion-col class="ion-text-center" (click)="startConsultation(customer)"><img class="hover" src="../../assets/icon/icon-consultation.svg"></ion-col>
                        </ng-template>
                        <ion-col class="ion-text-center" (click)="routeToCustomerdata(customer)"><img class="hover" src="../../assets/icon/icon-pen.svg"></ion-col>
                    </ion-row>
                </ion-grid>
            </div>
    </ion-content>
</ion-app>