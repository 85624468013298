import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DbService } from '../storage/db.service';
import { ApiService } from '../api-service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(public router: Router, private storage: DbService,private api: ApiService) { }


  public login(user) {
    let email = user.email;
    let password = user.password;

    let data = [{
      "email": email,
      "password": password
    }]

    return this.api.post('login/user', data);
  }

}
