<ion-app>
    <ion-content class="has-header">
        <div class="p-1">
            <h2 (click)="backClicked()"> <img class="glossar-icon" src="../../assets/icon/icon_glossar_arrow.svg">Kundendaten</h2>
        </div>
        <div class="customerList">
            <div class=button-header>
                <div class="grid-margin addCustomer" (click)="routeToCustomerdata()" routerDirection="forward"><img class="hover margin-right" src="../../assets/icon/icon-plus.svg"><span class="hover"> Neuen Kunden anlegen</span></div>
                <div class="grid-margin addCustomer" (click)="startBlankConsultation()" routerDirection="forward"><img class="hover margin-right" src="../../assets/icon/icon-plus-green.svg"><span class="hover"> Neue Beratung starten</span></div>
            </div>
            <ion-grid class="ion-margin-top grid-margin">
                <ion-row>
                    <ion-col>Kunde</ion-col>
                    <ion-col class="ion-text-center">Beratung starten</ion-col>
                    <ion-col class="ion-text-center">Datensatz</ion-col>
                    <ion-col class="ion-text-center">Bearbeiten</ion-col>
                </ion-row>
                <ion-row *ngFor="let customer of customers; index as i;">
                    <ng-container *ngIf="customer.credit_worthiness == 0; else negativeBoni">
                        <ion-col (click)="routeToCustomerdata(customer)"><span class="negative_boni max_name_width"><span>{{customer.name}} {{customer.surname}}</span></span></ion-col>
                    </ng-container>
                    <ng-template #negativeBoni>
                        <ion-col (click)="routeToCustomerdata(customer)"><span class="max_name_width">{{customer.name}} {{customer.surname}}</span></ion-col>
                    </ng-template>
                    <ng-container *ngIf="currentCustomer != null && currentCustomer != false && (customer.name == currentCustomer.name && customer.surname == currentCustomer.surname); else elseTemplate">
                        <ion-col class="ion-text-center" (click)="startConsultation(customer)"><img class="hover" src="../../assets/icon/icon-consultation-active.svg"></ion-col>
                    </ng-container>
                    <ng-template #elseTemplate>
                        <ion-col class="ion-text-center" (click)="startConsultation(customer)"><img class="hover" src="../../assets/icon/icon-consultation.svg"></ion-col>
                    </ng-template>
                    <ng-container *ngIf="customer.dataComplete; else incompleteTemplate">
                        <ion-col class="ion-text-center" ><img  src="../../assets/icon/icon-data-complete.svg"></ion-col>
                    </ng-container>
                    <ng-template #incompleteTemplate>
                        <ion-col class="ion-text-center" ><img  src="../../assets/icon/icon-data-incomplete.svg"></ion-col>
                    </ng-template>
                    <ion-col class="ion-text-center" (click)="routeToCustomerdata(customer)"><img class="hover" src="../../assets/icon/icon-pen.svg"></ion-col>
                </ion-row>
            </ion-grid>
        </div>
    </ion-content>
</ion-app>