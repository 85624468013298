import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common'

@Injectable({
    providedIn: 'root'
})

export class ApiService {

    // DEFINE API_KEY IF NECCESSARY
    API_KEY = 'YOUR_API_KEY';

    // DEFINE API_SERVER URL IN ENVIRONMENT
    API_SERVER = environment.apiUrl;

    // DEFINE CUSTOM HEADER
    HTTP_OPTIONS = {
        headers: new HttpHeaders({
            "Accept": 'application/json',
            'Content-Type': 'application/json'
        })
    }

    constructor(private httpClient: HttpClient) { }

    /**
     * Returns data from a HTTP get route
     * @param route 
     * @param data 
     */
    public get(route: string, data: any) {
        return this.httpClient.get(this.API_SERVER + route + data);
    }

    /**
     * POST data to a HTTP Post route
     * @param route 
     * @param data 
     */
    public post(route: string, data: any) {
        
        return this.httpClient.post(this.API_SERVER + route, data, this.HTTP_OPTIONS);
    }

    /**
     * PUT data to a HTTP Put route
     * @param route
     * @param data 
     */
    public put(route: string, data: any) {
        return this.httpClient.put(this.API_SERVER + route, data, this.HTTP_OPTIONS)
    }

    /**
     * DELETE data from a HTTP detele route
     * @param route
     * @param data 
     */
    public delete(route: string, data: any) {
        return true // implementing
    }

    /**
     * UPDATE data to a HTTP update route
     * @param route
     * @param data 
     */
    public update(route: string, data: any) {
        return true // implementing
    }

    /*
    ###### TODO, every method in its own service and use above functions #####
    */
}