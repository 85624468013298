import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NetworkInterface } from '@awesome-cordova-plugins/network-interface/ngx';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IonicStorageModule } from '@ionic/storage';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { TooltipModule } from '@cloudfactorydk/ng2-tooltip-directive';
import { Device } from '@awesome-cordova-plugins/device';
import { Camera} from '@awesome-cordova-plugins/camera/ngx';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { WebcamModule } from 'ngx-webcam';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
      BrowserModule,
      IonicModule.forRoot({
        mode : "md",
      }), 
      IonicStorageModule.forRoot(),
      AppRoutingModule,
      FormsModule, 
      ReactiveFormsModule,
      HttpClientModule,
      NgbModule,
      BrowserAnimationsModule,
      TooltipModule ,
      CommonModule,
      WebcamModule,
      ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    NetworkInterface,
    StatusBar,
    SplashScreen,
    Network,
    Camera,
    Diagnostic,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },

  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
