import { Component, Input, NgModule } from '@angular/core';
import { fabric } from 'fabric';
import { ModalController } from '@ionic/angular';
import { OnInit } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { DbService } from 'src/services/storage/db.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from '../components/components.module';
import { BrowserModule } from '@angular/platform-browser';
import { GlossarPageRoutingModule } from '../glossar/glossar-routing.module';
import { ProjectService } from 'src/services/project/project-service';
@Component({
  selector: 'pdf-modal',
  templateUrl: './pdf.modal.html',
  styleUrls: ['./pdf.modal.scss'],

})
  /*
   *  PDF Popup Model. Implements PDFViewer
   */
export class PDFModal {
  // Data passed in by componentProps
  @Input() modalType: string;
  productInformation: boolean = false;

  constructor(private modalController: ModalController, private dbService: DbService,private projectService: ProjectService) {
  }
  public bonus: any;

  workPrice: any;
  basePrice: any;
  fullPrice: any;

  wpPerYear: any;
  result: any;
  guarantee: any;
  pdfSrc: any
  currentProduct: any = Object;
  productList:Array<any> = [];
  ngOnInit() {
    // this.pdfSrc = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";
  }
    /*
   *  Show  right PDF that is saved in local Storage
   */
  ionViewWillEnter() {
    this.dbService.getObject("guarantee").then(value => {
      this.guarantee = value;
    })
      switch(this.modalType){
          case "datasecurity":
            // this.pdfSrc = "https://salesapp-backendtrianel-va.de/security";
            this.dbService.getObject('pdfSrcSecurity').then(src => {
              src = src.split(',')
              console.log(src[1])
              this.pdfSrc = this.base64ToArrayBuffer(src[1]);  
            })
          break;
          case "refusal":
            this.dbService.getObject('pdfSrcRefusal').then(src => {
              src = src.split(',')
              console.log(src[1])
              this.pdfSrc = this.base64ToArrayBuffer(src[1]);  
            })
          break;
          case "productinformation":
            this.productInformation = true;
            this.dbService.getObject("selectedProduct"+ this.projectService.getUserId()).then( selectedProduct => {
              this.dbService.getObject("productList").then(productList => {
                  productList.forEach(product => {
                    if(product.name == selectedProduct.name ){
                      product.price_tables.forEach(priceTable => {
                        if(priceTable.postal_code == selectedProduct.postal_code){
                          this.productList.push(priceTable);
                        }
                      });
                    }
                  });
              })
              this.currentProduct = selectedProduct;
            })

          break;
          case "agb":
              this.dbService.getObject('pdfSrcAgb').then(src => {
              src = src.split(',')
              console.log(src[1])
              this.pdfSrc = this.base64ToArrayBuffer(src[1]);  
            })
          break;
          case "advertisement":
                this.dbService.getObject('pdfSrcAdvertisement').then(src => {
              src = src.split(',')
              console.log(src[1])
              this.pdfSrc = this.base64ToArrayBuffer(src[1]);  
            })
            break;
          case "agb_gas":
              this.dbService.getObject('pdfSrcpdfSrcAgbGas').then(src => {
              src = src.split(',')
              console.log(src[1])
              this.pdfSrc = this.base64ToArrayBuffer(src[1]);  
            })
          break;
      }
  }
  dismiss() {
    this.modalController.dismiss({
      });
  }
    /*
   *  Parses saved base64 to usable ArrayBufferObject for PDFViewer
   */
   base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
   }
}