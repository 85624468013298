  /*
   *  Both Electricity-Models with given Data
   */

import { initialize } from "@ionic/core";


export class Electricity {
    guarantee: string = "23.654.215";

    constructor(guarantee) {
        this.guarantee = guarantee;
        this.init();

      }
    subtitle: string = "hammerSTROM ist unser klassisches Stromprodukt für alle, die ein besonderes Augenmerk auf einen günstigen Preis legen. hammerSTROM wird zu über 49 % regenerativ erzeugt und leistet damit auch einen gewissen Beitrag zum Umwelt- und Klimaschutz."

    benefits: {}[] = [
    ]

    background_informations: any = [
    ]
    init() {
        this.benefits = [
          
        {
            title: "Preisgarantie",
            subtitle: "Bis zum "+this.guarantee,
        },
        {
            title: "keine Kaution",
            subtitle: "weil uns gegenseitiges Vertrauen in einer Geschäftsbeziehung wichtig ist"
        },
        {
            title: "keine Vorauszahlung",
            subtitle: "sondern Sie zahlen erst, wenn Sie von uns beliefert werden"
        },
        {
            title: "monatliche Zahlungsweise",
            subtitle: "über einen übersichtlichen Abschlagsplan"
        },
        {
            title: "einfacher Wechsel",
            subtitle: "weil wir alle Formalitäten für Sie übernehmen"
        },
        {
            title: "volle Online-Kontrolle",
            subtitle: "über unser modernes Online Kundenzentrum"
        },
        {
            title: "guter Kundenservice",
            subtitle: "denn wir sind für Sie online und telefonisch erreichbar",

        }
        ]
      }
}
export class Electricity_Eco {

    guarantee: string = "23.654.215";

    constructor(guarantee) {
        this.guarantee = guarantee;
        this.init();
      }


    subtitle: string = "hammerSTROMklima ist unser Ökostromprodukt und wird zu 100 % aus regenerativen Energien erzeugt. Das besonders umwelt- und klimafreundliche Stromprodukt wird unter der Dachmarke Renewable Plus geführt, ist CO<sub>2</sub>-frei und TÜV-zertifiziert. Das lassen wir uns jedes Jahr aufs Neue bestätigen."

    benefits: {}[] = []

    background_informations: any = [
        {
            title: "RenewablePLUS",
            content: "Mit RenewablePLUS erwerben Sie ein besonders hochwertiges Ökostromprodukt, das auf Herkunftsnachweisen basiert. Durch eine Investmentgarantie wird sichergestellt, dass über das gesetzliche Maß hinaus in den Ausbau erneuerbarer Energien investiert wird. Auch Emissionen, die bei dem Bau oder dem Betrieb einer Anlage anfallen, werden durch entsprechende Emissionsminderungszertifikate ausgeglichen. Diese Zertifikate stammen ausschließlich aus nachhaltigen Projekten des vom TÜV Rheinland zertifizierten Labels ÖkoPLUS. Werden alle Kriterien erfüllt, vergibt der TÜV jährlich eine individuelle TÜV ID sowie die TÜV Rheinland Zertifizierungsurkunde. RenewablePLUS ist damit zu 100 % klimaneutral!",
            expanded: false,

        },
        {
            title: "Wir wird Ökostrom erzeugt?",
            content: "Der Großteil an Ökostrom wird von Wind- und Wasserkraftwerken geliefert. Auch Biogas-, Biomasse-, Geothermie- und Solar-Anlagen sind wertvolle Ökostrom-Lieferanten. Das Besondere beim Ökostrom aus Wind-, Wasser- und Solarenergie: Die Erzeugung verursacht keine Treibhausgase – im Gegensatz zu Strom aus fossilen Energieträgern. Ihr Ökostromprodukt RenewablePLUS wird durch norwegische Wasserkraft erzeugt (zum kleinen Teil aus Windkraft). Norwegen, ein Land mit unzähligen Flüssen und Fjorden, nutzt damit seinen geografischen Vorteil voll aus. Norwegen gehört zu einem der fünf größten Wasserkraftproduzenten der Welt. Über die Hälfte der gesamten europäischen Speicherkapazität für Wasserkraftwerke liegt in Norwegen. Die umweltfreundliche Stromerzeugung erfolgt dabei unter strengen gesetzlichen Vorschriften, um den Eingriff in die Natur möglichst gering zu halten. Die Eigentümer der Anlagen sind überwiegend Bürger oder Kommunen. Dabei ist die klimafreundliche Stromerzeugung nicht nur in öffentlicher und Bürgerhand, sondern auch ohne Verlinkung zur Atom- und Kohlewirtschaft. In Deutschland ist dies aufgrund der gesetzlichen Lage fast unmöglich.",
            expanded: false,
        },
        {
            title: "Was sind Herkunftsnachweise?",
            content: "Ein Herkunftsnachweis funktioniert wie ein Garantieschein, der die Erzeugung von Strom aus erneuerbaren Energien bestätigt. Dieser wird elektronisch erstellt und verbrieft. Pro 1 MWh wird ein Herkunftsnachweis ausgewiesen. Gleichzeitig soll durch die Nutzung von Herkunftsnachweisen sichergestellt werden, dass die Eigenschaft „produziert aus erneuerbaren Energien“ nur einmal verkauft und eine Doppelvermarktung ausgeschlossen werden kann. Die Herkunftsnachweise werden in dem Herkunftsnachweisregister (HKNR) des Umweltbundesamtes (UBA) verwaltet und entwertet.",
            expanded: false,
        },
        {
            title: "Warum gibt es Herkunftsnachweise?",
            content: "Die meisten europäischen Länder sind durch ein europaweites Stromnetz miteinander verbunden. In dieses Netz speisen sowohl konventionelle als auch Kraftwerke auf Basis erneuerbarer Energien Ihre produzierten Strommengen ein. Es ist daher physikalisch nicht möglich, eine bestimmte eingespeiste Menge an einen bestimmten Endverbraucher zu leiten. Herkunftsnachweise sollen dieses Problem der Vermischung lösen. Es wird daher bei der Erzeugung von erneuerbaren Energien die Eigenschaft „Grün“ von der physikalischen Lieferung getrennt und als Herkunftsnachweis verbrieft. Nur so kann eine klare Aussage zur Qualität des verkauften Stroms getroffen werden.",
            expanded: false,
        }
    ]
    init() {
        this.benefits = [
            {
                title: "Ökostrom",
                subtitle: "aus 100 % Wasserkraft, durch den TÜV NORD / TÜV Rheinland zertifiziert"
            },
            {
                title: "Preisgarantie",
                subtitle: "bis zum "+this.guarantee
            },
            {
                title: "Keine Kaution",
                subtitle: "weil uns gegenseitiges Vertrauen in einer Geschäftsbeziehung wichtig ist"
            },
            {
                title: "Keine Vorauszahlung",
                subtitle: "sondern Sie zahlen erst, wenn Sie von uns beliefert werden"
            },
            {
                title: " Zahlungsweise",
                subtitle: "über einen übersichtlichen Abschlagsplan"
    
            },
            {
                title: "Einfacher Wechsel",
                subtitle: "weil wir alle Formalitäten für Sie übernehmen"
            },
            {
                title: "Volle Online-Kontrolle",
                subtitle: "über unser modernes Online Kundenzentrum"
            },
            {
                title: "Guter Kundenservice",
                subtitle: "denn wir sind für Sie online und telefonisch erreichbar",
    
            }
        ]
      }
}