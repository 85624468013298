import { Component, OnInit, NgModule, HostListener } from '@angular/core';
import { DbService } from 'src/services/storage/db.service';
import { Router } from '@angular/router';
import { fabric } from 'fabric';
import { ProjectService } from 'src/services/project/project-service';
import { ModalController } from '@ionic/angular';
import { SignatureModal } from './signature.modal';
import * as moment from 'moment';
import { UserService } from 'src/services/user/user-service';
import { PDFModal } from './pdf.modal';
import { Ionic4DatepickerModalComponent } from '@logisticinfotech/ionic4-datepicker';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';

@Component({
    selector: 'app-checkout',
    templateUrl: './checkout.page.html',
    styleUrls: ['./checkout.page.scss'],
})
export class CheckoutPage implements OnInit {
    productName: any;
    productPrice: any;
    public currentTab: any;
    public firstname: string;
    public lastname: string;
    public formOfAddress: string;
    public email: string;
    public telephone: string;
    public date_of_birth: string;
    public iban: string;
    public postal_code: string;
    public city: any[] = [];
    public temp: string;
    public appointment: string;
    public street: string;
    public ibanError: boolean = true;
    public bic: string;
    public bill_address_check: boolean = true;
    public email_bill: string;
    public mobile_number: string;
    public formOfAddress_bill: string;
    public street_bill: string;
    public city_bill: string;
    public postal_code_bill: string;
    public name_bill: string;
    public title_bill: string;

    public surname_bill: string;
    public house_number_bill: string;

    public agb: boolean = false;
    public withdrawal: boolean = false;
    public data_privacy: boolean = false;
    public newsletter: boolean = false;
    public productinformation: boolean = false;
    public additional: string;
    public startPage: string;
    public newCustomer: boolean = true;
    signature: any;
    static canvas: any;
    public sepa: any;
    public meter_reading: any;
    public meter_number: any;
    public date_of_change: any;
    public consent: any;
    public canceled: any;
    public current_provider: any;
    public reason: any;
    public floor: any;
    public house_number: any;
    public payment_method: any;
    public account_owner: any;
    public title: any;
    public old_customer_number: any;
    public billing_address: boolean = true;
    public date_of_cancel: any;
    public productType: any;
    public organization: any;
    public malo_number: any;
    cityValue: any;
    fromDateChange: any = new Date();
    fromDateMoving: any = new Date();


    /*
   *  Holiday List
   *  Needs to be Updated every year
   */
    disabledDates: Date[] = [
        //American Fomat
        new Date('10-02-2020'), // Short format
        new Date('12-24-2020'), // Short format
        new Date('12-25-2020'), // Short format
        new Date('12-31-2020'), // Short format
        new Date('01-01-2021'), // Short format
        new Date('01-06-2021'), // Short format
        new Date('03-08-2021'), // Short format
        new Date('04-02-2021'), // Short format
        new Date('04-05-2021'), // Short format
        new Date('05-13-2021'), // Short format
        new Date('05-24-2021'), // Short format
        new Date('06-03-2021'), // Short format
        new Date('09-20-2021'), // Short format
        new Date('11-01-2021'), // Short format
        new Date('12-24-2021'), // Short format
        new Date('12-31-2021'), // Short format

        new Date('01-06-2023'), // Short format
        new Date('03-08-2023'), // Short format
        new Date('04-07-2023'), // Short format
        new Date('04-10-2023'), // Short format
        new Date('05-18-2023'), // Short format
        new Date('05-29-2023'), // Short format
        new Date('10-03-2023'), // Short format
        new Date('12-24-2023'), // Short format
        new Date('12-31-2023'), // Short format
        new Date('01-01-2024'), // Short format


    ];
    /*
 *  DatePicket Object for Moving picker
 */
    datePickerObjMoving: any = {
        inputDate: new Date(), // default new Date()
        fromDate: new Date(), // default null
        // toDate: new Date(), // default null
        // showTodayButton: false, // default true
        closeOnSelect: true, // default false
        mondayFirst: true, // default false
        todayLabel: 'Heute', // default 'Today'
        closeLabel: 'Schließen', // default 'Close'
        clearButton: false,
        showTodayButton: false,
        titleLabel: 'Wählen Sie ein Datum aus', // default null
        monthsList: ["Jan", "Feb", "März", "April", "Mai", "Juni", "Juli", "Aug", "Sept", "Okt", "Nov", "Dez"],
        weeksList: ["S", "M", "D", "M", "D", "F", "S"],
        dateFormat: 'DD.MM.YYYY', // default DD MMM YYYY
        momentLocale: 'de', // Default 'en-US'
        btnProperties: {
            expand: 'block', // Default 'block'
            fill: '', // Default 'solid'
            size: '', // Default 'default'
            disabled: '', // Default false
            strong: '', // Default false
            color: 'primary' // Default ''
        },
        arrowNextPrev: {
            nextArrowSrc: 'assets/images/arrow_right.svg',
            prevArrowSrc: 'assets/images/arrow_left.svg'
        }, // This object supports only SVG files.
        highlightedDates: [
        ], // Default [],
        isSundayHighlighted: {
            fontColor: '#ee88bf' // Default null
        } // Default {}
    };
    config = {
        displayKey: "name", //if objects array passed which key to be displayed defaults to description
        search: true, //true/false for the search functionlity defaults to false,
        height: '18rem', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
        placeholder: 'Vorlieferant', // text to be displayed when no item is selected defaults to Select,
        limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
        moreText: 'mehr', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
        noResultsFound: 'Keine Ergebnisse gefunden !', // text to be displayed when no items are found while searching
        searchPlaceholder: 'Suche', // label thats displayed in search input,
        searchOnKey: 'name'
    }
    dropdownOptions = []

    /*
   *  Specific configuration for cancelation 
   */
    datePickerObjCancel: any = {
        inputDate: new Date(), // default new Date()
        // fromDate: new Date(), // default null
        // toDate: new Date(), // default null
        // showTodayButton: false, // default true
        closeOnSelect: true, // default false
        mondayFirst: true, // default false
        todayLabel: 'Heute', // default 'Today'
        closeLabel: 'Schließen', // default 'Close'
        clearButton: false,
        showTodayButton: false,
        titleLabel: 'Wählen Sie ein Datum aus', // default null
        monthsList: ["Jan", "Feb", "März", "April", "Mai", "Juni", "Juli", "Aug", "Sept", "Okt", "Nov", "Dez"],
        weeksList: ["S", "M", "D", "M", "D", "F", "S"],
        dateFormat: 'DD.MM.YYYY', // default DD MMM YYYY
        momentLocale: 'de', // Default 'en-US'
        btnProperties: {
            expand: 'block', // Default 'block'
            fill: '', // Default 'solid'
            size: '', // Default 'default'
            disabled: '', // Default false
            strong: '', // Default false
            color: 'primary' // Default ''
        },
        arrowNextPrev: {
            nextArrowSrc: 'assets/images/arrow_right.svg',
            prevArrowSrc: 'assets/images/arrow_left.svg'
        }, // This object supports only SVG files.
        highlightedDates: [
        ], // Default [],
        isSundayHighlighted: {
            fontColor: '#ee88bf' // Default null
        } // Default {}
    };

    datePickerBirthCancel: any = {
        inputDate: new Date(), // default new Date()
        // fromDate: new Date(), // default null
        // toDate: new Date(), // default null
        // showTodayButton: false, // default true
        closeOnSelect: true, // default false
        mondayFirst: true, // default false
        todayLabel: 'Heute', // default 'Today'
        closeLabel: 'Schließen', // default 'Close'
        clearButton: false,
        showTodayButton: false,
        titleLabel: 'Wählen Sie ein Datum aus', // default null
        monthsList: ["Jan", "Feb", "März", "April", "Mai", "Juni", "Juli", "Aug", "Sept", "Okt", "Nov", "Dez"],
        weeksList: ["S", "M", "D", "M", "D", "F", "S"],
        dateFormat: 'DD.MM.YYYY', // default DD MMM YYYY
        momentLocale: 'de', // Default 'en-US'
        btnProperties: {
            expand: 'block', // Default 'block'
            fill: '', // Default 'solid'
            size: '', // Default 'default'
            disabled: '', // Default false
            strong: '', // Default false
            color: 'primary' // Default ''
        },
        arrowNextPrev: {
            nextArrowSrc: 'assets/images/arrow_right.svg',
            prevArrowSrc: 'assets/images/arrow_left.svg'
        }, // This object supports only SVG files.
        highlightedDates: [
        ], // Default [],
        isSundayHighlighted: {
            fontColor: '#ee88bf' // Default null
        } // Default {}
    };

    /*
 *  DatePickerObject for changing date (providers)
 */
    datePickerObjChange: any = {
        inputDate: new Date(), // default new Date()
        fromDate: new Date(), // default null
        // toDate: new Date(), // default null
        // showTodayButton: false, // default true
        closeOnSelect: true, // default false
        showTodayButton: false,
        disableWeekDays: [], // default []
        mondayFirst: true, // default false
        todayLabel: 'Heute', // default 'Today'
        closeLabel: 'Schließen', // default 'Close'
        clearButton: false,
        disabledDates: this.disabledDates, // default []
        titleLabel: 'Wählen Sie ein Datum aus', // default null
        monthsList: ["Jan", "Feb", "März", "April", "Mai", "Juni", "Juli", "Aug", "Sept", "Okt", "Nov", "Dez"],
        weeksList: ["S", "M", "D", "M", "D", "F", "S"],
        dateFormat: 'DD.MM.YYYY', // default DD MMM YYYY
        momentLocale: 'de', // Default 'en-US'
        btnProperties: {
            expand: 'block', // Default 'block'
            fill: '', // Default 'solid'
            size: '', // Default 'default'
            disabled: '', // Default false
            strong: '', // Default false
            color: 'primary' // Default ''
        },
        arrowNextPrev: {
            nextArrowSrc: 'assets/images/arrow_right.svg',
            prevArrowSrc: 'assets/images/arrow_left.svg'
        }, // This object supports only SVG files.
        highlightedDates: [
            // { date: new Date('2019-09-10'), color: '#ee88bf', fontColor: '#fff' },
            // { date: new Date('2019-09-12'), color: '#50f2b1', fontColor: '#fff' }
        ], // Default [],
        isSundayHighlighted: {
            fontColor: '#ee88bf' // Default null
        } // Default {}
    };
    kWh: any;
    currentProductType: any;
    hasBonus: boolean;
    currentBonus: any;
    deviceId: string;
    intitialDeviceId: string;
    screenHeight;
    screenWidth;
    public showWebcam = false;
    public allowCameraSwitch = true;
    public multipleWebcamsAvailable = false;
    public videoOptions: MediaTrackConstraints = {
        // width: {ideal: 1024},
        // height: {ideal: 576}
    };
    public errors: WebcamInitError[] = [];

    // latest snapshot
    public webcamImage: WebcamImage = null;

    // webcam snapshot trigger
    private trigger: Subject<void> = new Subject<void>();
    // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
    private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();
    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.screenHeight = window.innerHeight;
        this.screenWidth = window.innerWidth;
    }


    constructor(private dbService: DbService, private userService: UserService, private router: Router, private projectService: ProjectService, private modalController: ModalController) {
        moment.locale('de');
        this.getScreenSize();
    }
    ngOnInit() {
        WebcamUtil.getAvailableVideoInputs()
        .then((mediaDevices: MediaDeviceInfo[]) => {
          this.intitialDeviceId = mediaDevices[0].deviceId;
          this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
        });
    }

    ngAfterViewInit(): void {
        CheckoutPage.canvas = new fabric.Canvas('canvas');
        CheckoutPage.canvas.isDrawingMode = 1;
        CheckoutPage.canvas.freeDrawingBrush.color = "black";
        CheckoutPage.canvas.freeDrawingBrush.width = 1;
        CheckoutPage.canvas.renderAll();

    }
    /*
     *  Set Checkout with right and previously saved Data
     *  Sets from - to Dates of DatePickers 
     */
    ionViewWillEnter() {
        this.agb = false;
        this.data_privacy = false;
        this.withdrawal = false;
        this.productinformation = false;
        let holidays;
        function subtractYears(date, years) {
            date.setFullYear(date.getFullYear() - years);
            return date;
        }

        // Feb 20, 20222
        const date = new Date();

        const newDate = subtractYears(date, 100);
        this.datePickerBirthCancel.fromDate = newDate;

        switch (this.fromDateChange.getDay()) {
            case 0:
                this.datePickerObjChange.fromDate = this.fromDateChange.getTime() + (86400000 * 16) + this.calculateHoliday(16); // default null
                break;
            case 1:
                this.datePickerObjChange.fromDate = this.fromDateChange.getTime() + (86400000 * 16) + this.calculateHoliday(16); // default null
                break;
            case 2:
                this.datePickerObjChange.fromDate = this.fromDateChange.getTime() + (86400000 * 16) + this.calculateHoliday(16); // default null
                break;
            case 3:
                this.datePickerObjChange.fromDate = this.fromDateChange.getTime() + (86400000 * 16) + this.calculateHoliday(16); // default null
                break;
            case 4:
                this.datePickerObjChange.fromDate = this.fromDateChange.getTime() + (86400000 * 16) + this.calculateHoliday(16); // default null
                break;
            case 5:
                this.datePickerObjChange.fromDate = this.fromDateChange.getTime() + (86400000 * 17) + this.calculateHoliday(17); // default null
                break;
            case 6:
                this.datePickerObjChange.fromDate = this.fromDateChange.getTime() + (86400000 * 17) + this.calculateHoliday(17); // default null
                break;
        }
        this.datePickerObjMoving.fromDate = this.fromDateMoving.setDate(this.fromDateMoving.getDate() - 6 * 7)
        this.currentTab = 1;
        this.dbService.getObject("selectedProduct" + this.projectService.getUserId()).then(product => {
            this.currentProductType = product.product_type.name;
            if (product.hasBonus == true) {
                this.hasBonus = true;
                this.currentBonus = product.bonus;
            }
            console.log(this.currentProductType);
            this.productName = product.name;
            this.kWh = product.kwh;
            if (undefined != product.bonus) {
                let tmp;
                tmp = product.price.replace(/[^\d\,\-]/g, "");
                tmp = tmp.replace(',', '.');
                tmp = parseFloat(tmp) - product.bonus;
                this.productPrice = tmp.toLocaleString("de-DE", {maximumFractionDigits: 2, minimumFractionDigits: 2 });
            } else {
                let tmp;
                tmp = product.price.replace(/[^\d\,\-]/g, "");
                tmp = tmp.replace(',', '.');
                tmp = parseFloat(tmp);
                this.productPrice = product.price.toLocaleString("de-DE", { maximumFractionDigits: 2,minimumFractionDigits: 2 });;
            }
            this.productType = product.eco
            this.payment_method = "IBAN";
            this.dbService.getObject("currentCustomer" + this.projectService.getUserId()).then(customer => {
                this.firstname = customer.name;
                this.lastname = customer.surname;
                this.account_owner = customer.name + ' ' + customer.surname
                this.formOfAddress = customer.form_of_address;
                this.email = customer.email;
                this.organization = customer.organization;
                this.telephone = customer.address.telephone_number;
                this.postal_code = product.postal_code;
                this.house_number = customer.address.house_number
                this.test();
                this.cityValue = customer.address.city;
                this.street = customer.address.street;
                this.mobile_number = customer.address.mobile_number;
                this.firstname = customer.name;
                this.date_of_birth = customer.date_of_birth
                console.log(this.date_of_birth);
                this.title = customer.title;
                this.city_bill = customer.address.city;
                this.name_bill = customer.name;
                this.title = customer.title;

                this.surname_bill = customer.surname;
                this.street_bill = customer.address.street;
                this.postal_code_bill = product.postal_code
                this.house_number_bill = customer.address.house_number;
                this.formOfAddress_bill = customer.form_of_address;
                if (product.type_id == 3) {
                    this.dbService.getObject("electricityProviders").then(electricityProviders => {
                        this.dropdownOptions = electricityProviders;
                    })
                } else {
                    this.dbService.getObject("gasProviders").then(gasProviders => {
                        this.dropdownOptions = gasProviders;
                    })
                }

            })
        })
    }
    ionViewDidEnter() {
        this.dbService.setObject("currentPage" + this.projectService.getUserId(), this.router.url);
    }
    async openDatePickerChange() {
        const datePickerModal = await this.modalController.create({
            component: Ionic4DatepickerModalComponent,
            cssClass: 'li-ionic4-datePicker',
            componentProps: {
                'objConfig': this.datePickerObjChange,
                'selectedDate': this.date_of_change
            }
        });
        await datePickerModal.present();

        datePickerModal.onDidDismiss()
            .then((data) => {
                if (data.data.date != "Invalid date") {
                    this.date_of_change = data.data.date;
                }
            });
    }
    async openDatePickerMoving() {
        const datePickerModal = await this.modalController.create({
            component: Ionic4DatepickerModalComponent,
            cssClass: 'li-ionic4-datePicker',
            componentProps: {
                'objConfig': this.datePickerObjMoving,
                'selectedDate': this.date_of_change
            }
        });
        await datePickerModal.present();

        datePickerModal.onDidDismiss()
            .then((data) => {
                if (data.data.date != "Invalid date") {
                    this.date_of_change = data.data.date;
                }
            });
    }
    async openDatePickerCancel() {
        const datePickerModal = await this.modalController.create({
            component: Ionic4DatepickerModalComponent,
            cssClass: 'li-ionic4-datePicker',
            componentProps: {
                'objConfig': this.datePickerObjCancel,
                'selectedDate': this.date_of_cancel
            }
        });
        await datePickerModal.present();

        datePickerModal.onDidDismiss()
            .then((data) => {
                if (data.data.date != "Invalid date") {
                    this.date_of_cancel = data.data.date;
                }
            });
    }
    async openDatePickerBirth() {
        const datePickerModal = await this.modalController.create({
            component: Ionic4DatepickerModalComponent,
            cssClass: 'li-ionic4-datePicker',
            componentProps: {
                'objConfig': this.datePickerBirthCancel,
                'selectedDate': this.date_of_birth
            }
        });
        await datePickerModal.present();

        datePickerModal.onDidDismiss()
            .then((data) => {
                if (data.data.date != "Invalid date") {
                    this.date_of_birth = data.data.date;
                }
            });
    }
    clearDateOfChange() {
        this.date_of_change = null;
    }
    /*
     *  Checks the holidays that are in the given time persion
     *  if holiday add a day
     */
    calculateHoliday(iterator) {
        let holidays = 0;
        this.disabledDates.forEach(holiday => {
            if (holiday.getTime() >= this.fromDateChange.getTime() && holiday.getTime() <= this.fromDateChange.getTime() + (86400000 * iterator)) {
                holidays += 86400000;
            }
        });
        console.log(holidays / 86400000)
        return holidays;
    }
    /*
     *  Switches Tabs and checks if all needed Data is provided
     */
    openTab(tab) {
        switch (tab) {
            case 1:
                this.currentTab = tab;
                break;
            case 2:
                this.checkCustomerData(tab, false);

                break;
            case 3:
                if (this.postal_code && this.street && this.house_number && this.city) {
                    if (this.city[0] == undefined) {
                        this.city_bill = this.cityValue;
                    } else {
                        this.city_bill = this.city[0];
                    }
                    this.street_bill = this.street;
                    this.postal_code_bill = this.postal_code;
                    this.house_number_bill = this.house_number;
                    this.currentTab = tab;
                } else {
                    alert("Bitte füllen sie alle mit '*' benötigten Felder aus.")
                }
                break;
            case 4:
                if (this.reason) {
                    if (this.reason == "change") {
                        if (this.date_of_change && this.current_provider) {
                            console.log(this.current_provider);
                            if (this.canceled == true) {
                                if (this.date_of_cancel)
                                    this.currentTab = tab;
                                else
                                    alert("Geben Sie das Kündigungsdatum an.")
                            } else {
                                this.currentTab = tab;
                            }
                        } else {
                            alert("Bitte füllen sie alle mit '*' benötigten Felder aus.")
                        }
                    } else {
                        //MOVING
                        if (this.date_of_change) {
                            this.currentTab = tab;
                        } else {
                            alert("Bitte wählen Sie einen Wechselgrund an.")
                        }
                    }
                } else {
                    alert("Bitte wählen Sie einen Wechselgrund aus.")
                }
                break;
            case 5:
                if (this.payment_method) {
                    if (this.payment_method == "IBAN") {
                        if (this.iban && this.account_owner && this.sepa) {
                            let ibanCheck = this.isValidIBANNumber(this.iban)
                            if (ibanCheck == 1) {
                                this.currentTab = tab;
                            } else {
                                alert("Die eingegebene IBAN ist fehlerhaft.")
                                return;
                            }
                        } else {
                            alert("Bitte füllen sie alle mit '*' benötigten Felder aus.")
                            return;
                        }
                    } else {
                        this.currentTab = tab;
                    }
                } else {
                    alert("Bitte wählen Sie eine Bezahlmethode aus")
                    return;
                }
                break;
            case 6:
                if (this.city_bill &&
                    this.name_bill &&
                    this.surname_bill &&
                    this.street_bill &&
                    this.postal_code_bill &&
                    this.house_number_bill) {
                    this.currentTab = tab;
                } else {
                    alert("Bitte füllen sie alle mit '*' benötigten Felder aus.")
                }
                break;

        }
    }
    checkCustomerData(tab, emailBool) {
        if (this.firstname && this.lastname) {
            if (emailBool == true) {
                return true;
            }
            if ((this.email || emailBool) && this.telephone) {

                if (this.formOfAddress == "Herr" || this.formOfAddress == "Frau" || this.formOfAddress == "Eheleute") {
                    let tempDate = this.date_of_birth;
                    let parsed = moment(tempDate, "DD/MM/YYYY");
                    var years = moment().diff(parsed, 'years')
                    if (years >= 18) {
                        let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
                        let searchfind = regexp.test(this.email);
                        if (searchfind) {
                            this.email_bill = this.email;
                            this.surname_bill = this.lastname;
                            this.account_owner = this.firstname + ' ' + this.lastname;
                            this.name_bill = this.firstname;
                            this.title_bill = this.title;
                            this.formOfAddress_bill = this.formOfAddress
                            this.currentTab = tab;
                            return true;
                        } else {
                            alert("Email ist nicht valide.")
                            return false;
                        }
                    } else {
                        alert("Sie müssen mindestens 18 Jahre alt sein, um einen Vertrag abzuschließen.")
                        return false;
                    }
                } else {
                    let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
                    let searchfind = regexp.test(this.email);
                    if (searchfind) {
                        this.email_bill = this.email;
                        this.surname_bill = this.lastname;
                        this.title_bill = this.title;
                        this.account_owner = this.firstname + ' ' + this.lastname;
                        this.name_bill = this.firstname;
                        this.formOfAddress_bill = this.formOfAddress
                        this.currentTab = tab;
                        return true;
                    } else {
                        alert("Email ist nicht valide.")
                        return false;
                    }
                }
            } else {
                alert("Bitte füllen sie alle mit '*' benötigten Felder aus.")
                return false;
            }

        } else {
            alert("Bitte füllen sie alle mit '*' benötigten Felder aus.")
            return false;
        }
    }
    saveSignature() {
        // let signature = JSON.stringify(CheckoutPage.canvas.toJSON());
        // this.projectService.uploadSignature(signature).subscribe(res => {
        //     console.log("RESPONSE RECEIVED")
        // });
    }
    checkForm() {
        if (this.agb && this.data_privacy && this.withdrawal && this.productinformation) {
            return true
        } else {
            return false
        }
    }
    /*
         * Returns 1 if the IBAN is valid 
         * Returns FALSE if the IBAN's length is not as should be (for CY the IBAN Should be 28 chars long starting with CY )
         * Returns any other number (checksum) when the IBAN is invalid (check digits do not match)
         */
    isValidIBANNumber(input) {
        this.ibanError = true;
        var CODE_LENGTHS = {
            AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
            CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
            FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
            HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
            LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
            MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
            RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26
        };
        var iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, ''), // keep only alphanumeric characters
            code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
            digits;
        // check syntax and length
        if (!code) {
            alert("Ihre IBAN besitzt eine falsche Formatierung! Beispiel: DE21 3012 0400 0000 0152 28")
            return
        }
        if (iban.length !== CODE_LENGTHS[code[1]]) {
            alert("Ihre IBAN besitzt eine falsche Formatierung! Beispiel: DE21 3012 0400 0000 0152 28")
            return
        }
        this.ibanError = false;
        // rearrange country code and check digits, and convert chars to ints
        //@ts-ignore
        digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
            return letter.charCodeAt(0) - 55;
        });
        // final check
        return this.mod97(digits);
    }
    clickedBill(event) {
        console.log(event)
        let checked = event.target.checked;
        if (checked) {
            this.city[0] != null ? this.city_bill = this.city[0] : this.city_bill = this.cityValue;
            this.name_bill = this.firstname;
            this.surname_bill = this.lastname;
            this.street_bill = this.street;
            this.title_bill = this.title;
            this.postal_code_bill = this.postal_code
            this.house_number_bill = this.house_number;
            this.formOfAddress_bill = this.formOfAddress;
        } else {
            this.city_bill = null;
            this.name_bill = null;
            this.surname_bill = null;
            this.street_bill = null;
            this.title_bill = null;
            this.postal_code_bill = null
            this.house_number_bill = null;
            this.formOfAddress_bill = null;
        }
    }
    /**
     * It takes a string, and returns the remainder of the string divided by 97.
     * 
     * The first two characters of the string are stored in the variable checksum. Then, the function loops
     * through the string, starting at the third character, and ending at the last character. Each time
     * through the loop, the function takes the value of checksum, concatenates it with the next seven
     * characters of the string, and stores the result in the variable fragment. Then, the function takes
     * the value of fragment, converts it to a number, and divides it by 97. The remainder of this division
     * is stored in the variable checksum.
     * 
     * When the loop is finished, the function returns the value of checksum.
     * 
     * The function is called with the string
     * "12345678901234567890123456789012345678901234567890123456789012345678901
     * @param string - The string to calculate the checksum for.
     * @returns The checksum of the IBAN.
     */
    mod97(string) {
        var checksum = string.slice(0, 2), fragment;
        for (var offset = 2; offset < string.length; offset += 7) {
            fragment = String(checksum) + string.substring(offset, offset + 7);
            checksum = parseInt(fragment, 10) % 97;
        }
        return checksum;
    }

    test() {
        let that = this;
        let city = [];
        this.dbService.getObject("productList").then(productList => {
            for (let i = 0; i < productList.length; i++) {
                for (let x = 0; x < productList[i].price_tables.length; x++) {
                    if (productList[i].price_tables[x].postal_code == that.postal_code) {
                        if (!city.includes(productList[i].price_tables[x].city)) {
                            city.push(productList[i].price_tables[x].city);
                        }
                    }
                }
            }
            if (city.length == 0) {
                that.city = [];
                that.cityValue = null;

            } else if (city.length == 1) {
                that.cityValue = city;
            } else {
                that.city = city
            }
        })
    }
    /**
     * Create a modal
     * @param name - the name of the PDF file to be opened.
     */
    async openPDF(name) {
        const modal = await this.modalController.create({
            component: PDFModal,
            componentProps: { 'modalType': name },
            cssClass: 'bonus-modal',
        });

        modal.present();
        let { data } = await modal.onWillDismiss();
    }


    /**
     * It checks if the form is valid, if it is, it checks if the user wants to sign the form, if he does,
     * it opens a modal, if he doesn't, it continues, 
     * 
     * I'm not sure if this is the best way to do it, but it works.
     * 
     * I hope this helps someone.
     * @param signBool - boolean
     */
    async checkAndRoute(signBool) {
        if (signBool == true) {
            var formAccepted = this.checkForm();

        } else {
            var formAccepted = true;
        }
        if (formAccepted) {
            if (signBool == true) {
                const modal = await this.modalController.create({
                    component: SignatureModal,
                    cssClass: 'my-custom-class'
                });

                modal.present();
                var { data } = await modal.onWillDismiss();
            }

            if (undefined != data || signBool == false) {
                this.dbService.getObject("currentCustomer" + this.projectService.getUserId()).then(customer => {
                    this.dbService.getObject("customerList" + this.projectService.getUserId()).then(customerList => {
                        Object.keys(customerList).forEach((key) => {
                            if ((customerList[key].email == customer.email) && (customerList[key].street == customer.street) &&
                                (customerList[key].postal_code == customer.postal_code) && (customerList[key].name == customer.name) &&
                                (customerList[key].surname == customer.surname)) {

                                customer.name = this.firstname;
                                customer.surname = this.lastname;
                                customer.form_of_address = this.formOfAddress;
                                customer.email = this.email;
                                customer.date_of_birth = this.date_of_birth
                                customer.newsletter = this.newsletter;
                                customer.organization = this.organization;
                                console.log(this.title);
                                customer.title = this.title;
                                customer.malo_number = this.malo_number;
                                customer.birthdate = moment(this.date_of_birth).format('DD/MM/YYYY');

                                if (customer.address == undefined || customer.address == null) {
                                    customer.address = {};
                                }
                                customer.address.telephone_number = this.telephone;
                                customer.address.postal_code = this.postal_code;
                                if (this.city[0] == undefined) {
                                    customer.address.city = this.cityValue[0];
                                } else {
                                    customer.address.city = this.city[0];

                                }
                                customer.address.street = this.street;
                                customer.address.house_number = this.house_number;
                                this.dbService.getObject("selectedProduct" + this.projectService.getUserId()).then(product => {
                                    if (product.change_data == undefined || customer.change_data == null) {
                                        product.change_data = {};
                                    }
                                    product.change_data.reason = this.reason;
                                    product.change_data.previous_supplier = this.old_customer_number;
                                    product.change_data.meter_number = this.meter_number;
                                    product.change_data.date_of_change = this.date_of_change;
                                    product.change_data.dateOfChangePHP = moment(this.date_of_change).format('DD/MM/YYYY')
                                    product.change_data.date_of_cancel = this.date_of_cancel;
                                    product.change_data.meter_reading = this.meter_reading;
                                    if (this.current_provider != undefined)
                                        product.change_data.current_provider = this.current_provider.name;
                                    product.change_data.canceled = this.canceled;
                                    this.dbService.setObject("selectedProduct" + this.projectService.getUserId(), product);
                                })
                                if (customer.billing == undefined || customer.billing == null) {
                                    customer.billing = {};
                                }
                                customer.billing.type = this.payment_method;
                                customer.billing.iban = this.iban;
                                customer.billing.account_owner = this.account_owner;
                                customer.billing.bic = this.bic;
                                customer.billing.city_bill = this.city_bill[0];
                                customer.billing.name_bill = this.name_bill;
                                customer.billing.surname_bill = this.surname_bill;
                                customer.billing.title_bill = this.title_bill;
                                customer.billing.street_bill = this.street_bill;
                                customer.billing.postal_code_bill = this.postal_code_bill;
                                customer.billing.house_number_bill = this.house_number_bill;
                                customer.billing.formOfAddress_bill = this.formOfAddress_bill;
                                signBool == true ? customer.signature = data['signatureData'] : customer.signature = "";
                                customerList[key] = customer;
                                if (this.title != null && this.formOfAddress != "Firma")
                                    this.userService.changeMessage(this.title + ' ' + this.firstname + ' ' + this.lastname);
                                else
                                    this.userService.changeMessage(this.firstname + ' ' + this.lastname);
                                this.dbService.setObject("customerList" + this.projectService.getUserId(), customerList);
                                this.dbService.get("usage").then(usage => {
                                    customer.kWh = usage;
                                    this.dbService.setObject("currentCustomer" + this.projectService.getUserId(), customer).then(d => {
                                        if (signBool == true) {
                                            this.router.navigate(['/data-transfer/true'])
                                        } else {
                                            this.router.navigate(['/data-transfer/false'])
                                        }
                                    })
                                })
                            }
                        });
                    });
                })
            }
        } else {
            alert("Bitte füllen sie alle mit '*' benötigten Felder aus.")
        }
    }

    addPicture(base64Image) {

        this.dbService.getObject("currentCustomer" + this.projectService.getUserId()).then(customer => {
            this.dbService.getObject("customerList" + this.projectService.getUserId()).then(customerList => {
                Object.keys(customerList).forEach((key) => {
                    console.log(customer)
                    console.log(customerList[key])

                    if ( (customerList[key].name == customer.name) &&
                        (customerList[key].surname == customer.surname)) {
                        if (customer.pictures == undefined) customer.pictures = [];
                        customer.name = this.firstname;
                        customer.surname = this.lastname;
                        customer.form_of_address = this.formOfAddress;
                        customer.email = this.email;
                        customer.date_of_birth = this.date_of_birth
                        customer.newsletter = this.newsletter;
                        customer.organization = this.organization;
                        customer.title = this.title;
                        customer.malo_number = this.malo_number;
                        customer.birthdate = moment(this.date_of_birth).format('DD/MM/YYYY');
                        customer.pictures.push(base64Image)
                        customerList[key] = customer;
                        this.dbService.setObject("customerList" + this.projectService.getUserId(), customerList);
                        this.dbService.setObject("currentCustomer" + this.projectService.getUserId(), customer).then(d => {
                            this.router.navigate(['/photo-contract'])
                        })
                    }
                });
            });
        });
    }
    routeToCheckout() {
        this.router.navigate(['/checkout'])
    }
    public triggerSnapshot(): void {
        this.trigger.next();
    }

    public toggleWebcam(): void {
        this.showWebcam = !this.showWebcam;
    }

    public handleInitError(error: WebcamInitError): void {
        this.errors.push(error);
        if(error.message == 'Could not start video source'){
            alert("Ein Fehler ist beim Wechseln der Kamera aufgetreten.\r\nStandardkamera wird wieder ausgewählt.")
            this.nextWebcam.next(this.intitialDeviceId);
        }
    }

    public showNextWebcam(directionOrDeviceId: boolean | string): void {
        // true => move forward through devices
        // false => move backwards through devices
        // string => move to device with given deviceId
        this.nextWebcam.next(directionOrDeviceId);
    }

    public handleImage(webcamImage: WebcamImage): void {
        this.addPicture(webcamImage.imageAsDataUrl);

    }

    public cameraWasSwitched(deviceId: string): void {
        console.log('active device: ' + deviceId);
        this.deviceId = deviceId;
    }

    public get triggerObservable(): Observable<void> {
        return this.trigger.asObservable();
    }

    public get nextWebcamObservable(): Observable<boolean | string> {
        return this.nextWebcam.asObservable();
    }
    async openCamera() {
        if (this.checkCustomerData(1, true))
            this.toggleWebcam();
    }
}
