<ion-app>
    <ion-content class="has-header">
        <div class="p-1">
            <!-- <h2>{{customer.name}}</h2> -->
        </div>
        <div class="p-1">
            <h2>Persönliche Daten</h2>
        </div>
        <div class="customer-container">
            <ion-grid class="margin-left">
                <ion-row>
                    <ion-col>
                        <ion-select mode="md" class="customer-form" cancelText="Abbrechen" okText="Bestätigen" [(ngModel)]="formOfAddress" id="formOfAddress" name="formOfAddress"  placeholder="Anrede">
                            <ion-select-option value="Herr">Herr</ion-select-option>
                            <ion-select-option value="Frau">Frau</ion-select-option>
                            <ion-select-option value="Firma">Firma</ion-select-option>
                            <ion-select-option value="Eheleute">Eheleute</ion-select-option>
                            <ion-select-option value="Eigentümergemeinschaft">Eigentümergemeinschaft</ion-select-option>
                            <ion-select-option value="Wohngemeinschaft">Wohngemeinschaft</ion-select-option>
                            <ion-select-option value="Hausverwaltung">Hausverwaltung</ion-select-option>
                            <ion-select-option value="Bürogemeinschaft">Bürogemeinschaft</ion-select-option>
                            <ion-select-option value="Erbengemeinschaft">Erbengemeinschaft</ion-select-option>
                            <ion-select-option value="Immobilienverwaltung">Immobilienverwaltung</ion-select-option>
                            <ion-select-option value="Wohnungsgesellschaft">Wohnungsgesellschaft</ion-select-option>
                            <ion-select-option value="Bauunternehmung">Bauunternehmung</ion-select-option>
                            <ion-select-option value="Bauherrengemeinschaft">Bauherrengemeinschaft</ion-select-option>
                            <ion-select-option value="Praxisgemeinschaft">Praxisgemeinschaft</ion-select-option>
                            <ion-select-option value="Grundstücksgemeinschaft">Grundstücksgemeinschaft</ion-select-option>
                            <ion-select-option value="Hausgemeinschaft">Hausgemeinschaft</ion-select-option>
                            <ion-select-option value="Kostengemeinschaft">Kostengemeinschaft</ion-select-option>
                            <ion-select-option value="Sozietät">Sozietät</ion-select-option>
                            <ion-select-option value="">-</ion-select-option>
                          </ion-select>
                    </ion-col>
                    <ng-container *ngIf="formOfAddress != 'Herr' && formOfAddress != 'Frau' && formOfAddress != 'Eheleute'; else noOrgaTemplate">

                        <ion-col><input mode="md" autocomplete="off" maxlength="55" class="customer-form" [(ngModel)]="title" type="text" id="title"
                            name="title" value="" placeholder="Firma/Organisation"></ion-col>
                    </ng-container>
                    <ng-template #noOrgaTemplate>

                        <ion-col><input mode="md" autocomplete="off" maxlength="30" class="customer-form" [(ngModel)]="title" type="text" id="title"
                            name="title" value="" placeholder="Titel"></ion-col>
                    </ng-template> 
                </ion-row>
                <ion-row>
                    <ion-col>
                        <input mode="md" maxlength="30" class="customer-form" [(ngModel)]="firstname" type="text" id="firstname" name="firstname"
                            value="" placeholder="Vorname*">
                    </ion-col>
                    <ion-col>
                        <input mode="md" maxlength="30" class="customer-form" [(ngModel)]="lastname" type="text" id="lastname" name="lastname"
                            value="" placeholder="Nachname*">
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <input mode="md" *ngIf='this.formOfAddress == "Herr" || this.formOfAddress == "Frau" ||this.formOfAddress == "Eheleute"' autocomplete="off" readonly maxlength="30" class="customer-form" (click)="openDatePickerBirthday()" [(ngModel)]="date_of_birth" type="text"
                            id="birthday" name="birthday" value=""
                            placeholder="Geburtsdatum">
                    </ion-col>
                    <ion-col>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </div>
        <div class="p-1">
            <h2>Angaben zur Verbrauchsstelle</h2>
        </div>
        <div class="customer-container">
            <ion-grid class="margin-left">
                <ion-row>
                    <ion-col>
                        <input mode="md" maxlength="30" class="customer-form" [(ngModel)]="postal_code" type="text" id="postal_code"
                            name="postal_code" value="" placeholder="PLZ" 
                            (ngModelChange)="getCity()" >
                             

                    </ion-col>
                    <!-- <ion-col>
                        <ion-button (click)="testZIPS()">TEST</ion-button>
                    </ion-col> -->
                    <ion-col>
                        <ng-container *ngIf="city.length < 2 || city.length == null; else elseTemplate">
                            <input mode="md" maxlength="30" class="customer-form" [(ngModel)]="cityValue" type="text" id="city" name="city" value=""
                            placeholder="Ort"
                            [disabled]="true">
                        </ng-container>
                        <ng-template #elseTemplate>
                            <ion-select mode="md" class="customer-form" cancelText="Abbrechen" okText="Bestätigen" [(ngModel)]="cityValue" id="formOfAddress" name="formOfAddress"  placeholder="Wählen Sie eine Stadt aus">
                                <div *ngFor="let city of city">
                                    <ion-select-option value="{{city}}">{{city}}</ion-select-option>
                                </div>
                              </ion-select>
                        </ng-template> 
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <input mode="md" maxlength="30" class="customer-form" [(ngModel)]="street" type="text" id="street" name="street" value=""
                            placeholder="Straße">
                    </ion-col>
                    <ion-col>
                        <input mode="md" maxlength="30" class="customer-form" [(ngModel)]="house_number" type="text" id="house_number" name="house_number" value=""
                            placeholder="Hausnummer">
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                    </ion-col>
                    <ion-col>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </div>
        <div class="p-1">
            <h2>Kontaktdaten</h2>
        </div>
        <div class="customer-container">
            <ion-grid class="margin-left">
                <ion-row>
                    <ion-col>
                        <input mode="md" maxlength="45" class="customer-form" [(ngModel)]="email" type="text" id="email" name="email" value=""
                            placeholder="E-Mail">
                    </ion-col>
                    <ion-col>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <input mode="md" maxlength="30" class="customer-form" [(ngModel)]="telephone" type="text" id="telephone" name="telephone"
                            value="" placeholder="Telefon">
                    </ion-col>
                    <ion-col>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </div>
        <div class="p-1">
            <h2>Vertriebsgespräch Start</h2>
        </div>
        <div class="customer-container">
            <ion-grid class="margin-left">
                <ion-row>
                    <ion-col>
                        <ion-select class="customer-form" cancelText="Abbrechen" okText="Bestätigen"
                        [(ngModel)]="startPage" id="d" name="startPage"
                        placeholder="Gesprächseinstieg">
                        <ion-select-option value="/home">Home</ion-select-option>
                        <ion-select-option value="/benefits">Vorteile</ion-select-option>
                        <ion-select-option *ngIf="expertKnowledge" value="/expert-knowledge">Engagement</ion-select-option>
                        <ion-select-option *ngIf="expertKnowledge2" value="/expert-knowledge2">Stromfaktoren</ion-select-option>
                        <ion-select-option value="/product-selection">Produktauswahl</ion-select-option>

                    </ion-select>
                    </ion-col>
                    <ion-col>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <!-- <ion-datetime mode="md" [(ngModel)]="appointment" 
                            placeholder="Datum Termin*" max="2120" displayFormat="DD/MM/YYYY H:mm" doneText="Bestätigen" minuteValues="0,15,30,45"
                            cancelText="Abbrechen"></ion-datetime> -->
                        <input mode="md" autocomplete="off" readonly maxlength="30" class="customer-form" (click)="openDatePickerAppointment()" [(ngModel)]="appointment" type="text"
                            id="appointment" name="appointment" value=""
                            placeholder="Datum Termin*">
                    </ion-col>
                    <ion-col>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </div>
        <div class="p-1">
            <h2>Notizen</h2>
        </div>
        <div class="customer-container">
            <ion-grid class="margin-left">
                <ion-textarea class="notices" [(ngModel)]="notice" placeholder="Schreiben Sie etwas..."></ion-textarea>
            </ion-grid>
        </div>
        <div class="form-submit-row">
            <ion-button class="submit margin-right save" mode="md" (click)="save()">SPEICHERN</ion-button>
            <ion-button class="submit" [disabled]="boni == 0" mode="md" (click)="testForm()">SPEICHERN + STARTEN</ion-button>
        </div>
    </ion-content>
</ion-app>