<ion-app>
    <ion-content class="has-header has-footer">
        <div class="page-content">
        <img class="green_bubble" src="../../assets/images/bubble-grey.svg">
        <div class="p-1">
            <h2>Hammer Preise für Ihren Tarif</h2>
            <p>Die Stadtwerke Hamm - Ihr zuverlässiger Energieversorger</p>
        </div>
        <div class="calc-container">
            <div class="calculator-header ion-text-center">
                <h3>Preisrechner</h3>
                <span>Bestens versorgt mit {{type}}</span>
            </div>
            <div class="calculator-input">
                <ion-row >
                    <ion-col size="6" class="ion-text-start"> 
                        <ion-row class="align-center">Postleitzahl</ion-row>
                        <ion-row class="align-center"><input class="calc-input" type="number" inputmode="numeric" [(ngModel)]="postal_code"></ion-row>
                    </ion-col>
                    <ion-col size="6" class="ion-text-start">
                        <ion-row class="align-center">Jahresverbrauch (kWh)</ion-row>
                        <ion-row class="align-center"><input class="calc-input kwh-input" type="number" inputmode="numeric" [(ngModel)]="kWh"></ion-row>
                    </ion-col>
                </ion-row>
                <div *ngIf="selectCity == true">
                    <ion-row class="mt-2">
                        <ion-col size="6" class="ion-text-start">
                        <ion-select mode="md" class="customer-form" cancelText="Abbrechen" okText="Bestätigen" [(ngModel)]="cityValue" id="formOfAddress" name="formOfAddress"  placeholder="Wählen Sie eine Stadt aus">
                            <div *ngFor="let city of cityList">
                                <ion-select-option value="{{city}}">{{city}}</ion-select-option>
                            </div>
                          </ion-select>
                        </ion-col>
                        <ion-col size="6">
                        
                        </ion-col>
                    </ion-row>
                </div>
            </div>
            <ng-container *ngIf="type == 'hammerSTROM'; else elseTemplate">
                <div class="slider">
                    <ion-range [(ngModel)]="sliderValue" min="1" max="4" step="1" snaps="true" color="medium" (ionChange)="getSliderValue()">
                        <ion-label slot="start"><img src="../../assets/images/icon-awesome-user-circle.svg"></ion-label>
                        <ion-label slot="end"></ion-label>
                    </ion-range>
                    <ion-row class="labels">
                            <div [ngClass]="sliderValue == 1 ? 'label1 active-label' : 'label1'">1 Person</div>
                            <div [ngClass]="sliderValue == 2 ? 'label2 active-label' : 'label2'">2 Personen</div>
                            <div [ngClass]="sliderValue == 3 ? 'label3 active-label' : 'label3'">3 Personen</div>
                            <div [ngClass]="sliderValue == 4 ? 'label4 active-label' : 'label4'">4 Personen</div>
                    </ion-row>
                </div>
            </ng-container>
            <ng-template #elseTemplate>
                <div class="slider">
                    <ion-range [(ngModel)]="sliderValue" min="1" max="4" step="1" snaps="true" color="medium" (ionChange)="getSliderValueGas()">
                        <ion-label slot="start"><img src="../../assets/images/icon-awesome-user-circle.svg"></ion-label>
                        <ion-label slot="end"></ion-label>
                    </ion-range>
                    <ion-row class="labels">
                            <div [ngClass]="sliderValue == 1 ? 'label1gas active-label' : 'label1gas'">50 qm</div>
                            <div [ngClass]="sliderValue == 2 ? 'label2gas active-label' : 'label2gas'">85 qm</div>
                            <div [ngClass]="sliderValue == 3 ? 'label3gas active-label' : 'label3gas'">120 qm</div>
                            <div [ngClass]="sliderValue == 4 ? 'label4gas active-label' : 'label4gas'">150 qm</div>
                    </ion-row>
                </div>
            </ng-template>
            
            <ion-button mode="md" class="calculator-button" (click)="calculateAndRoute()">TARIF BERECHNEN</ion-button>
            <!-- <img class="banner slider-background p-1" src="../../assets/images/slider-background.svg"> -->
        </div>
        </div>
    </ion-content>
</ion-app>