import { Component, OnInit } from '@angular/core';
import { DbService } from 'src/services/storage/db.service';
import { Router } from '@angular/router';
import { ProjectService } from 'src/services/project/project-service';
import { LoadingController } from '@ionic/angular';

@Component({
    selector: 'app-calculator',
    templateUrl: './calculator.page.html',
    styleUrls: ['./calculator.page.scss'],
})
export class CalculatorPage implements OnInit {

    public sliderValue: number = 1;
    public kWh: number = null;
    public postal_code: number = null;
    public type: any = "";
    public selectCity: boolean;
    public cityList: any[] = [];
    public cityValue: any = null;
    constructor(private dbService: DbService, private router: Router, private projectService: ProjectService,public loadingController: LoadingController) {
    }
    ionViewWillEnter() {
        this.dbService.getObject("currentCustomer" + this.projectService.getUserId()).then(customer => {
            if (undefined != customer.address && undefined != customer.address.postal_code) {
                this.postal_code = customer.address.postal_code
            }
            let input = this.router.url;
            let gasIsFound = input.indexOf("gas") != -1 ? true : false;
            if (gasIsFound) {
                this.type = "hammerGAS";
            } else {
                this.type = "hammerSTROM";
            }
        })

    }
    ngOnInit() {
    }
    ionViewWillLeave() {
        this.cityValue = null;
        this.cityList = [];
        this.selectCity = false;
    }
    ionViewDidEnter() {
        this.dbService.setObject("currentPage" + this.projectService.getUserId(), this.router.url);
    }
    /*
   *  Set Slider values
   */
    getSliderValue() {
        switch (this.sliderValue) {

            case 1:
                this.kWh = 1500;
                break;
            case 2:
                this.kWh = 2500;
                break;
            case 3:
                this.kWh = 3500;
                break;
            case 4:
                this.kWh = 4500;
                break;
        }
    }
    /*
     *  Set Slider values
     */
    getSliderValueGas() {
        console.log(this.sliderValue)
        switch (this.sliderValue) {

            case 1:
                this.kWh = 7000;
                break;
            case 2:
                this.kWh = 12000;
                break;
            case 3:
                this.kWh = 20000;
                break;
            case 4:
                this.kWh = 30000;
                break;
        }
    }
    /*
   *  Checks productList for matching Products for the User
   *  Also compares it to given postal_code
   */
    async calculateAndRoute() {
        let that = this;
        if (this.postal_code != null && this.kWh != null && !isNaN(this.postal_code) && !isNaN(this.kWh)) {
            const loading = await this.loadingController.create({
                cssClass: 'my-custom-class',
                message: 'Der Tarif wird berechnet.',
                backdropDismiss: false,
              });
            await loading.present();
            this.dbService.getObject("productList").then(productList => {
                let matchedProducts = [];
                let input = this.router.url;
                let gasIsFound = input.indexOf("gas") != -1 ? true : false;
                let powerIsFound = input.indexOf("power") != -1 ? true : false;

                if (gasIsFound) {
                    //"GasProducts";
                    productList.forEach(product => {
                        product.price_tables.forEach((price_table, index) => {
                            if (price_table.postal_code == this.postal_code && price_table.consumption_lower_limit <= this.kWh && this.kWh <= price_table.consumption_upper_limit && product.type_id == 1) {
                                if (this.cityValue == null) {
                                    product.kwh = this.kWh;
                                    let tmp = product;
                                    tmp.postal_code = tmp.price_tables[index].postal_code;
                                    tmp.city = tmp.price_tables[index].city;
                                    tmp.consumption_upper_limit = tmp.price_tables[index].consumption_upper_limit;
                                    tmp.consumption_lower_limit = tmp.price_tables[index].consumption_lower_limit;
                                    tmp.export_ident = tmp.price_tables[index].export_ident;
                                    tmp.base_price = tmp.price_tables[index].base_price;
                                    tmp.labour_price = tmp.price_tables[index].labour_price;
                                    tmp.price_tables = [];
                                    matchedProducts.push(tmp);
                                } else {
                                    if (this.cityValue == price_table.city) {
                                        product.kwh = this.kWh;
                                        let tmp = product;
                                        tmp.postal_code = tmp.price_tables[index].postal_code;
                                        tmp.city = tmp.price_tables[index].city;
                                        tmp.consumption_upper_limit = tmp.price_tables[index].consumption_upper_limit;
                                        tmp.consumption_lower_limit = tmp.price_tables[index].consumption_lower_limit;
                                        tmp.export_ident = tmp.price_tables[index].export_ident;
                                        tmp.base_price = tmp.price_tables[index].base_price;
                                        tmp.labour_price = tmp.price_tables[index].labour_price;
                                        tmp.price_tables = [];
                                        matchedProducts.push(tmp);
                                    }
                                }
                            }
                        });

                    });
                } else {
                    //"ElectrcityProducts";
                    productList.forEach(product => {
                        product.price_tables.forEach((price_table, index) => {
                            if (price_table.postal_code == this.postal_code && price_table.consumption_lower_limit <= this.kWh && this.kWh <= price_table.consumption_upper_limit && product.type_id == 3) {
                                if (this.cityValue == null) {
                                    console.log("FOUND")
                                    product.kwh = this.kWh;
                                    let tmp = product;
                                    tmp.postal_code = tmp.price_tables[index].postal_code;
                                    tmp.city = tmp.price_tables[index].city;
                                    tmp.consumption_upper_limit = tmp.price_tables[index].consumption_upper_limit;
                                    tmp.consumption_lower_limit = tmp.price_tables[index].consumption_lower_limit;
                                    tmp.export_ident = tmp.price_tables[index].export_ident;
                                    tmp.base_price = tmp.price_tables[index].base_price;
                                    tmp.labour_price = tmp.price_tables[index].labour_price;
                                    tmp.price_tables = [];
                                    matchedProducts.push(tmp);
                                } else {
                                    console.log(this.cityValue)
                                    if (this.cityValue == price_table.city) {
                                        product.kwh = this.kWh;
                                        let tmp = product;
                                        tmp.postal_code = tmp.price_tables[index].postal_code;
                                        tmp.city = tmp.price_tables[index].city;
                                        tmp.consumption_upper_limit = tmp.price_tables[index].consumption_upper_limit;
                                        tmp.consumption_lower_limit = tmp.price_tables[index].consumption_lower_limit;
                                        tmp.export_ident = tmp.price_tables[index].export_ident;
                                        tmp.base_price = tmp.price_tables[index].base_price;
                                        tmp.labour_price = tmp.price_tables[index].labour_price;
                                        tmp.price_tables = [];
                                        matchedProducts.push(tmp);
                                    }
                                }
                            }
                        });

                    });
                }
                loading.dismiss();
                if (matchedProducts.length == 0) {
                    alert("Es existiert leider kein Produkt für Ihre PLZ bzw. Ihren Verbrauch.")
                } else if (matchedProducts.length > 2) {
                    console.log("multiple things detected")
                    matchedProducts.forEach(product => {
                        if (!that.cityList.includes(product.city)) {
                            that.cityList.push(product.city)
                        }
                    });
                    this.selectCity = true;
                } else {
                    this.dbService.setObject("matchedProducts", matchedProducts).then(d => {
                        if (gasIsFound) {
                            this.router.navigate(['/product-item-selection/gas']);
                        } else {
                            this.router.navigate(['/product-item-selection/power']);
                        }
                    }
                    )
                }
            })
        } else {
            alert("Bitte füllen Sie alle Felder aus.")
        }
    }

}
