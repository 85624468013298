import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DbService } from './storage/db.service';
import { ApiService } from './api-service';
import { NetworkServiceService } from './network-service.service';
import { LoadingController } from '@ionic/angular';
import { ProjectService } from './project/project-service';

@Injectable({
  providedIn: 'root'
})
export class SyncService {

  constructor(public router: Router, private storage: DbService,private api: ApiService, private networkService: NetworkServiceService, private loadingController: LoadingController, private dbService: DbService, private projectService: ProjectService) { }


  async synchonizeData() {
    if (this.networkService.getNetworkStatus() == "online") {
        const loading = await this.loadingController.create({
            cssClass: 'loadingScreen',
            message: 'Ihre Daten werden synchronisiert.',
            backdropDismiss: false,
        });
        await loading.present();
        let user = await this.dbService.get("user");
        let customerList = await this.dbService.getObject("customerList" + this.projectService.getUserId())
        this.projectService.getCustomers(user, customerList).then(data => {
            this.dbService.setObject("customerList" + this.projectService.getUserId(), data['Customerlist'])
            console.log(data['Products']);
            console.log(data);
            try {
                this.dbService.setObject("productList", data['Products'])
            } catch (error) {
                alert("Maximaler Speicherplatz des Browsers wurde erreicht. Dies kann an dem Inkognito-Modus liegen. Bitte wechseln sie den Browser und probieren Sie es erneut.")
            }
            this.dbService.setObject("electricityProviders", data['ElectricityProviders'])
            this.dbService.setObject("gasProviders", data['GasProviders'])
            this.projectService.getPDFs();
            this.projectService.getGuarantee().then(value => {
                this.dbService.setObject("guarantee", value);

            });

            loading.dismiss();
            if (this.router.url != "/customer-list") {
                this.router.navigate(['/landing-page']);
            } else {
                this.router.navigate(["/login"]).then(d => {
                    this.router.navigate(['/landing-page']);
                });
            }
        });
    }
    else {
        alert("Sie müssen eine Internetverbindung besitzen, um Ihre Daten synchronisieren zu können.");
    }
}

}
