import { Component, OnInit } from '@angular/core';
import { DbService } from 'src/services/storage/db.service';
import { Router, ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'app-impressum',
  templateUrl: './impressum.page.html',
  styleUrls: ['./impressum.page.scss'],
})
export class ImpressumPage implements OnInit {

    constructor(private _location: Location,private dbService: DbService, private router: Router ,private route: ActivatedRoute) { }
  
    ngOnInit() {
    }
    ionViewDidEnter() {
      this.dbService.setObject("currentPage",this.route.url);
    }
    backClicked() {
      this._location.back();
    }
}
