import { Component, OnInit, NgModule } from '@angular/core';
import { fabric } from 'fabric';
import { ProjectService } from 'src/services/project/project-service';
import { DbService } from 'src/services/storage/db.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-privacy-agreement',
  templateUrl: './privacy-agreement.page.html',
  styleUrls: ['./privacy-agreement.page.scss'],
})
export class PrivacyAgreementPage implements OnInit {
  
    signature: any;
    static canvas: any;
  constructor(private projectService: ProjectService,private dbService: DbService, private router: Router) 
    {

    }

  ngOnInit() {
  }

  ionViewWillEnter() {

    this.dbService.getObject("selectedProduct").then(product => {
    });
}

  ionViewDidEnter() {
    this.dbService.setObject("currentPage",this.router.url);
  }
  saveSignature() {
      let signature = JSON.stringify(PrivacyAgreementPage.canvas.toJSON());
      this.projectService.uploadSignature(signature).subscribe(res => {
          console.log("RESPONSE RECEIVED")
      });
  }

}
