import { Component } from '@angular/core';

import { Platform, LoadingController } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { Router } from '@angular/router';
import { DbService } from 'src/services/storage/db.service';
import { UserService } from 'src/services/user/user-service';
import { NetworkServiceService } from 'src/services/network-service.service';
import { ProjectService } from 'src/services/project/project-service';
import { of } from 'rxjs';
import DE from '@angular/common/locales/de';
import { registerLocaleData } from '@angular/common';
import { Location } from '@angular/common';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    index = 0;
    subscription1: any;
    currentUser: string;
    currentCustomer: any;
    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        public router: Router,
        private dbService: DbService,
        private userService: UserService,
        private networkService: NetworkServiceService,
        private loadingController: LoadingController,
        private projectService: ProjectService,
        private location: Location
    ) {
        this.networkService.initNetworkMonitor();
        registerLocaleData(DE);
        this.initializeApp();
        console.log(this.location.path())
        if(!this.location.path().includes("/view-contract") && !this.location.path().includes("/confirm-link") &&!this.location.path().includes("/contract-success" ))
        this.dbService.getObject("user").then(user => {
            if (user != false) {
                this.projectService.setUser(user);
                this.dbService.getObject("currentCustomer" + this.projectService.getUserId()).then(customer => {
                    if (customer != false && customer != undefined && customer != null) {
                        this.userService.changeMessage(customer.title + ' ' + customer.name + ' ' + customer.surname);
                        if(customer.title != null)
                        this.currentUser = customer.title + ' ' + customer.name + ' ' + customer.surname;
                        else
                        this.currentUser = customer.name + ' ' + customer.surname;
                    }
                })
                this.dbService.get("currentPage" + this.projectService.getUserId()).then(lastpage => {
                    lastpage = lastpage.replace('"', '')
                this.router.navigate(["/login"]);
                })
            } else {
                this.router.navigate(["/login"]);
            }
        })
        this.subscription1 = this.userService.currentMessage.subscribe(message => { if (message != "null") { this.currentUser = message } });
        // 
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        });
    }
    routeForward() {
        switch (this.router.url) {
            case "/home":
                this.router.navigate(['/benefits']);
                break;
            case "/price-comparison":
                this.router.navigate(['/price-comparison-gas']);
                break;
            case "/price-comparison-gas":
                console.log("PUFF")
                this.router.navigate(['/benefits']);
                break;
            case "/benefits":
                this.dbService.getObject("user").then(user => {
                    console.log(user)
                    if (user.expert_knowledge == true ||user.expert_knowledge == 1) {
                        this.router.navigate(['/expert-knowledge']);
                    } else {
                        if (user.expert_knowledg2 == true ||user.expert_knowledge2 == 1) {
                            this.router.navigate(['/influencing-factors']);
                        } else {
                            this.router.navigate(['/product-selection']);
                        }
                    }
                })
                break;
            case "/expert-knowledge":
                this.dbService.getObject("user").then(user => {
                    if (user.expert_knowledge2 == true ||user.expert_knowledge2 == 1) {
                        this.router.navigate(['/influencing-factors']);
                    } else {
                        this.router.navigate(['/product-selection']);
                    }
                });
                break;
            case "/influencing-factors":
                this.router.navigate(['/product-selection']);
                break;
            case "/product-details":
                this.router.navigate(['/checkout']);
                break;
            default:
                console.log(this.router.url)
        }
    }
    routeBackward() {
        switch (this.router.url) {
            case "/home":
                this.router.navigate(['/home']);
                break;
            case "/price-comparison":
                this.router.navigate(['/home']);
                break;
            case "/price-comparison-gas":
                this.router.navigate(['/price-comparison']);
                break;
            case "/benefits":
                this.router.navigate(['/home']);
                break;
            case "/expert-knowledge":
                this.router.navigate(['/benefits']);
                break;
            case "/influencing-factors":
                this.dbService.getObject("user").then(user => {
                    if (user.expert_knowledge == true ||user.expert_knowledge == 1) {
                        this.router.navigate(['/expert-knowledge']);
                    } else {
                        this.router.navigate(['/benefits']);
                    }
                });
                break;
            case "/product-selection":
                this.dbService.getObject("user").then(user => {
                    if (user.expert_knowledge2 == true ||user.expert_knowledge2 == 1) {
                        this.router.navigate(['/influencing-factors']);
                    } else {
                        if (user.expert_knowledge == true ||user.expert_knowledge == 1) {
                            this.router.navigate(['/expert-knowledge']);
                        } else {
                            this.router.navigate(['/benefits']);
                        }
                    }
                })
                break;
            case "/calculator/gas":
                this.router.navigate(['/product-selection']);
                break;
            case "/calculator/power":
                this.router.navigate(['/product-selection']);
                break;
            case "/product-item-selection/gas":
                this.router.navigate(['/calculator/gas']);
                break;
            case "/product-item-selection/power":
                this.router.navigate(['/calculator/power']);
                break;
            case "/product-details":
                this.dbService.getObject('selectedProduct' + this.projectService.getUserId()).then(product => {
                    if (product.type_id == 3) {
                        this.router.navigate(['/product-item-selection/power']);
                    } else {
                        this.router.navigate(['/product-item-selection/gas']);
                    }
                })
                break;
            case "/checkout":
                this.router.navigate(['/product-details']);
                break;
            case "/data-transfer":
                this.router.navigate(['/checkout']);
                break;

        }
    }
    logout() {
        this.dbService.set("user", false)
        this.dbService.set("loggedIn", false)
        this.currentUser = null;
        this.router.navigate(['/login']);
    }
    async synchonizeData() {
        if (this.networkService.getNetworkStatus() == "online") {
            const loading = await this.loadingController.create({
                cssClass: 'loadingScreen',
                message: 'Ihre Daten werden synchronisiert.',
                backdropDismiss: false,
            });
            await loading.present();
            let user = await this.dbService.get("user");
            let customerList = await this.dbService.getObject("customerList" + this.projectService.getUserId())
            this.projectService.getCustomers(user, customerList).then(data => {
                this.dbService.setObject("customerList" + this.projectService.getUserId(), data['Customerlist'])
                this.dbService.setObject("productList", data['Products'])
                this.dbService.setObject("electricityProviders", data['ElectricityProviders'])
                this.dbService.setObject("gasProviders", data['GasProviders'])
                this.projectService.getPDFs();
                this.projectService.getGuarantee().then(value => {
                    this.dbService.setObject("guarantee", value);

                });

                loading.dismiss();
                if (this.router.url != "/customer-list") {
                    this.router.navigate(['/customer-list']);
                } else {
                    this.router.navigate(["/login"]).then(d => {
                        this.router.navigate(['/customer-list']);
                    });
                }
            });
        }
        else {
            alert("Sie müssen eine Internetverbindung besitzen, um Ihre Daten synchronisieren zu können.")
        }
    }
}

