<ion-app>
    <ion-content class="has-header has-footer">
        <div class="glossar-content">
            <ion-button class="glossar-btn" (click)="backClicked()">Impressum</ion-button>
            <div class="p-1">
                <h4>Stadtwerke Hamm GmbH</h4>
                <span>Südring 1</span><br>
                <span>59065 Hamm</span><br>
                <span>Tel.: 02381 274-0</span><br>
                <span>Fax. 02381 274-1606</span><br>

                <span>Mail: post@stadtwerke-hamm.de</span><br>
            </div>
            <div class="p-1">
                <h4>Inhalt</h4>
                <span><u>Verantwortlich für den Inhalt:</u></span><br>
                <span>Center Energie- und Wasserwirtschaft</span><br><br>
                <span><u>Eingetragen beim Handelsregister Amtsgericht Hamm</u></span><br>
                <span>Register-Nr. HRB 301</span><br>
                <span>UST.-ID-NUMMER: DE 125218431</span><br><br>

                <span><u><b>Geschäftsführung:</b></u></span><br>
                <span>Jörg Hegemann (Vorsitzender)</span><br>
                <span>Reinhard Bartsch</span><br><br>

                <span><u><b>Aufsichtsratsvorsitzender:</b></u><br>
                    Marc Herter</span><br>

            </div>



            <div class="p-1">
                <h4>Streitbeilegung</h4>
                <span>Informationen zur Online-Streitbeilegung gemäß Art. 14 Abs. 1 VO (EU) 524/2013: Verbraucher haben
                    die Möglichkeit, über die Online-Streitbeilegungs-Plattform (OS-Plattform) der Europäischen Union
                    kostenlose
                    Hilfestellung für die Einreichung einer Verbraucherbeschwerde zu einem Online-Kaufvertrag oder
                    Online-
                    Dienstleistungsvertrag sowie Informationen über die Verfahren an den Verbraucherschlichtungsstellen
                    in der Europäischen Union zu erhalten.
                </span><br><br>
                <span>Die OS-Plattform kann unter folgendem Link aufgerufen werden:
                    http://ec.europa.eu/consumers/odr/.</span><br><br>

                <span>Energieversorgungsunternehmen sind in Ihrer Eigenschaft als Strom- und Gaslieferanten,
                    Netzbetreiber
                    und Messstellenbetreiber (Unternehmen) gem. § 111a EnWG verpflichtet, am Schlichtungsverfahren
                    teilzunehmen. Die Kontaktdaten der Schlichtungsstelle sind derzeit:</span><br>
                <span>Register-Nr. HRB 301</span><br><br>

                <span>Schlichtungsstelle Energie e.V., Friedrichstraße 133, 10117 Berlin,<br>
                    (0) 30/27 57 240 – 0<br>
                    Mo. - Di. 14:00 - 16:00 Uhr, Mi. – Do. 10:00 – 12:00 Uhr
                </span><br><br>

                <span>E-Mail: info@schlichtungsstelle-energie.de, Homepage: www.schlichtungsstelle-energie.de.
                </span><br><br>

                <span>Soweit es um unsere übrigen Produkte und Leistungen in den Bereichen Trinkwasser, Fernwärme, 
                    Energiedienstleistungen (ausgenommen Strom- und Gaslieferungen), Bäder und Verkehr geht, 
                    ist die Teilnahme an Schlichtungsverfahren i.S.d. § 36 VSGB nicht verpflichtend. 
                    Bitte wenden Sie sich in diesen Angelegenheiten direkt an uns, da wir hier an Schlichtungsverfahren 
                    vor Schlichtungsstellen nicht teilnehmen.
                    
                </span><br><br>
                <span>Allgemeine Informationen zu Verbraucherrechten sind erhältlich über den Verbraucherservice der 
                    Bundesnetzagentur für den Bereich Elektrizität und Gas, 
                    
                </span><br><br> <span>Postfach 8001, 53105 Bonn<br>
                    Telefon: 030 22480 - 500 oder 01805 101000 (Mo.-Fr. 9:00 Uhr - 12:00 Uhr)<br>
                    Telefax: 030 22480 - 323<br>
                    E-Mail: verbraucherservice-energie@bnetza.de. 
                </span><br><br>
            </div>
        </div>
    </ion-content>
</ion-app>