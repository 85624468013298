<ion-app >
    <ion-menu side="start" contentId="main">
        <ion-menu-toggle class="ion-text-end">
            <ion-button mode="ios"class="menuToggle"><ion-icon name="close"></ion-icon></ion-button>
        </ion-menu-toggle>
        <ion-content class="sidebar">
                <ion-list class="sidebar-list">
                <ion-menu-toggle>
                    <ion-item class="sidebar-item" lines="none" routerLink="/landing-page" mode="md">
                        <img class="sidebar-icon" src="../assets/icon/icon-landing_page.svg">
                        <ion-label class="sidebar-label">Start</ion-label>
                    </ion-item>
                </ion-menu-toggle>
                <ion-menu-toggle>
                    <ion-item class="sidebar-item" lines="none" routerLink="/customer-list" mode="md">
                        <img class="sidebar-icon" src="../assets/icon/icon-customerlist.svg">
                        <ion-label class="sidebar-label">Kundenliste</ion-label>
                    </ion-item>
                </ion-menu-toggle>
                <ion-menu-toggle>
                    <ion-item class="sidebar-icon" class="sidebar-item" lines="none" routerLink="/appointment-list" mode="md">
                        <img src="../assets/icon/icon-calender.svg">
                        <ion-label class="sidebar-label">Termine</ion-label>
                    </ion-item>
                </ion-menu-toggle>
                <ion-menu-toggle>
                    <ion-item class="sidebar-item" lines="none" routerLink="/glossar" mode="md">
                        <img class="sidebar-icon" src="../assets/icon/icon-glossar (1).svg">
                        <ion-label class="sidebar-label">Glossar</ion-label>
                    </ion-item>
                </ion-menu-toggle>
                <ion-menu-toggle>
                    <ion-item class="sidebar-item" lines="none" routerLink="/terms-of-use" mode="md">
                        <img class="sidebar-icon" src="../assets/icon/icon-glossar (1).svg">
                        <ion-label class="sidebar-label">Nutzungsbedingungen</ion-label>
                    </ion-item>
                </ion-menu-toggle>
                <ion-menu-toggle>
                    <div>
                        <ion-item class="sidebar-item" lines="none" (click)="synchonizeData()" mode="md">
                            <img class="sidebar-icon" src="../assets/icon/icon-sync.svg">
                            <ion-label class="sidebar-label">Synchronisation</ion-label>
                        </ion-item>
                    </div>
                </ion-menu-toggle>
                <ion-menu-toggle>
                    <div>
                        <ion-item class="sidebar-item" lines="none" routerLink="/impressum" mode="md">
                            <img class="sidebar-icon" src="../assets/icon/icon-impressum.svg">
                            <ion-label class="sidebar-label">Impressum</ion-label>
                        </ion-item>
                    </div>
                </ion-menu-toggle>
                <ion-menu-toggle>
                    <ion-item class="sidebar-item" lines="none" (click)="logout()" mode="md">
                        <img class="sidebar-icon" src="../assets/icon/icon-logout.svg">
                        <ion-label class="sidebar-label">Logout</ion-label>
                    </ion-item>
                </ion-menu-toggle>
            </ion-list>
        </ion-content>
    </ion-menu>
    <ion-header *ngIf="router.url != '/login' && router.url != '/forgot-password'  && router.url.indexOf('/confirm-link') === -1 && router.url != '/contract-success'">
        <ion-toolbar mode="ios">
            <ion-buttons slot="start">
                <ion-menu-button autoHide="false" menuToggle></ion-menu-button>
            </ion-buttons>
            <ion-title class="ion-text-center">
                <img class="header-banner" src="../../assets/images/hamm_logo_header_new.svg">
                <span class="currentUser" *ngIf="currentUser"><img class="margin-right"
                        src="../assets/icon/icon-user.svg">{{currentUser}}</span>
            </ion-title>
        </ion-toolbar>
    </ion-header>
    <ion-router-outlet id="main" [ngClass]="(router.url != '/login' &&  router.url != '/forgot-password' && router.url.indexOf('/confirm-link') === -1) ? 'margin' : 'no-margin'"></ion-router-outlet>
    <ion-footer *ngIf="(router.url != '/login' && router.url != '/landing-page' && router.url != '/forgot-password') && currentUser">
        <ion-toolbar>
            <ion-buttons slot="start">
                <ion-button
                    [ngClass]="router.url == '/price-comparison'|| router.url == '/terms-of-use'|| router.url == '/glossar' || router.url == '/conclusion' || router.url == '/home' ||  router.url == '/appointment-list' || router.url == '/customer-list'  || router.url == '/customer-data'? 'invisible' : 'switch-button' "
                    (click)="routeBackward();" routerDirection="forward"><img
                        src="../../assets/images/arrow-left-icon.svg"></ion-button>
            </ion-buttons>
            <div class="footer-buttons">
                    <ion-row class="maxwidth">
                        <ion-col class="footer_col">
                            <ion-button class="footer-button" [routerLink]="['/home']" routerDirection="forward">
                                <div *ngIf="router.url == '/home'; else inactiveHome">
                                    <div class="column">
                                        <img class="footer-img" src="../../assets/images/start-page-active-icon.svg">
                                        <span class="footer-text">STARTSEITE</span>
                                    </div>
                                </div>
                                <ng-template #inactiveHome>
                                    <div class="column">
                                        <img class="footer-img"  src="../../assets/images/start-page-icon.svg">
                                        <span class="footer-text">STARTSEITE</span>
                                    </div>
                                </ng-template>
                            </ion-button>
                        </ion-col>
                    <ion-col class="footer_col">
                        <ion-button class="footer-button" [routerLink]="['/benefits']" routerDirection="forward">
                            <div *ngIf="router.url == '/benefits'; else inactiveBenefits">
                                <div class="column">
                                    <img class="footer-img" src="../../assets/images/benefits-active-icon.svg">
                                    <span class="footer-text">IHRE VORTEILE</span>
                                </div>
                            </div>
                            <ng-template #inactiveBenefits>
                                <div class="column">
                                    <img class="footer-img" src="../../assets/images/benefits-icon.svg">
                                    <span class="footer-text">IHRE VORTEILE</span>
                                </div>
                            </ng-template>
                        </ion-button>
                    </ion-col>
                    <ion-col class="footer_col">
                        <ion-button class="footer-button" [routerLink]="['/product-selection']" routerDirection="forward">
                            <div *ngIf="router.url == '/product-selection'; else inactiveProduct">
                                <div class="column">
                                    <img class="footer-img" src="../../assets/images/product-selection-active-icon.svg">
                                    <span class="footer-text">PRODUKTAUSWAHL</span>
                                </div>
                            </div>
                            <ng-template #inactiveProduct>
                                <div class="column">
                                    <img class="footer-img" src="../../assets/images/product-selection-icon.svg">
                                    <span class="footer-text">PRODUKTAUSWAHL</span>
                                </div>
                            </ng-template>
                        </ion-button>
                    </ion-col>
                    <ion-col class="footer_col">
                        <ion-button class="footer-button" [routerLink]="['/conclusion']" routerDirection="forward">
                            <div *ngIf="router.url == '/conclusion'; else customerData">
                                <div class="column">
                                    <img class="footer-img" src="../../assets/images/contract-active-icon.svg">
                                    <span class="footer-text">ÜBERSICHT ABSCHLUSS</span>
                                </div>
                            </div>
                            <ng-template #customerData>
                                <div class="column">
                                    <img class="footer-img" src="../../assets/images/contract-icon.svg">
                                    <span class="footer-text">ÜBERSICHT ABSCHLUSS</span>
                                </div>
                            </ng-template>
                        </ion-button>
                    </ion-col>
                </ion-row>
            </div>
            <ion-buttons slot="end">
                <ion-button
                    [ngClass]="router.url == '/price-comparison'|| router.url == '/terms-of-use'||  router.url == '/glossar'|| router.url == '/data-transfer' || router.url == '/appointment-list' || router.url == '/customer-list'  || router.url == '/customer-data' || router.url == '/conclusion' || router.url == '/calculator/power' || router.url == '/calculator/gas' || router.url == '/product-selection' || router.url == '/product-item-selection/power' || router.url == '/checkout'? 'invisible' : 'switch-button'"
                    (click)="routeForward();" routerDirection="forward"><img
                        src="../../assets/images/arrow-right-icon.svg">
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-footer>
</ion-app>