import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { DbService } from 'src/services/storage/db.service';
import { CustomerService } from 'src/services/customer/customer.service';
import { UserService } from 'src/services/user/user-service';
import { ProjectService } from 'src/services/project/project-service';

@Component({
    selector: 'app-customer-list',
    templateUrl: './customer-list.page.html',
    styleUrls: ['./customer-list.page.scss'],
})
export class CustomerListPage implements OnInit {
    public customers = [];
    constructor(
        private _location: Location,
        public router: Router,
        private dbService: DbService,
        private customerService: CustomerService,
        private userService: UserService,
        private projectService: ProjectService,
    ) {
    }
    currentCustomer: any;

    ionViewDidEnter() {
        this.dbService.setObject("currentPage" + this.projectService.getUserId(), this.router.url);
        this.dbService.getObject("currentCustomer" + this.projectService.getUserId()).then(currentCustomer => {
            if (currentCustomer != null && currentCustomer != undefined && currentCustomer != false) {
                if (currentCustomer.title != null)
                    this.userService.changeMessage(currentCustomer.title + ' ' + currentCustomer.name + ' ' + currentCustomer.surname);
                else
                    this.userService.changeMessage(currentCustomer.name + ' ' + currentCustomer.surname);
                this.currentCustomer = currentCustomer;
            }
        })
    }
    async ngOnInit() {

    }
    async ionViewWillEnter() {
        let customerList = await this.dbService.getObject("customerList" + this.projectService.getUserId());
        Object.keys(customerList).forEach((key) => {
            let customer = this.checkForCompletion(customerList[key])
            customer = this.checkForAnalog(customer)
            console.log(customer);
            this.customers.push(customer);
        });
    }

    ionViewDidLeave() {
        this.customers = [];
    }

    /**
     * If all the fields are not null and not empty, then set dataComplete to true, else set it to false.
     * @param customer - {
     * @returns The customer object with the dataComplete property set to true or false.
     */
    checkForCompletion(customer) {
        if (
            customer.address != null && customer.date_of_birth != null && customer.email != null
            && customer.form_of_address != null
            && customer.name != null && customer.surname != null &&
            customer.address != "" && customer.date_of_birth != "" && customer.email != ""
            && customer.form_of_address != ""
            && customer.name != "" && customer.surname != ""

        ) {
            customer.dataComplete = true;
            return customer;
        } else {
            customer.dataComplete = false;
            return customer;
        }
    }
    /**
     * It checks if a customer has any products that are analog confirmed, and if so, it sets the
     * customer's analog_confirmed property to 1. If the customer has any products that are not analog
     * confirmed, it sets the customer's analog_confirmed property to 0.
     * @param customer
     * @returns The customer object with the analog_confirmed property set to either 0 or 1.
     */
    checkForAnalog(customer) {
        let hasNoneConfirmed = false;
        let hasConfirmed = false;
        customer.products.forEach(product => {
            if (product.analog_confirmed == true) {
                hasConfirmed = true;
            } else if (product.analog_confirmed == false) {
                hasNoneConfirmed = true;
            }
        });
        if ((hasConfirmed && hasNoneConfirmed) || hasNoneConfirmed) customer.analog_confirmed = 0;
        if (hasConfirmed) customer.analog_confirmed = 1;
        return customer;
    }

    async routeToCustomerdata(customer = null) {
        this.customerService.setCustomer(customer);
        await this.dbService.setObject("currentCustomer" + this.projectService.getUserId(), customer).then(d => {
            console.log("route")
            this.router.navigate(['/customer-data']);
        });
    }
    /**
     * It checks if the customer has a negative credit worthiness, if so, it shows an alert, if not, it
     * sets the customer in the customerService and navigates to the start page.
     * @param customer
     * @returns The customer object.
     */
    async startConsultation(customer) {
        console.log(customer.credit_worthiness)
        if (customer.credit_worthiness == 0) {
            alert("Dieser Kunde besitzt eine negative Bonität. Es kann keine neue Beratung gestartet werden.")
            return;
        } else {
            this.customerService.setCustomer(customer);
            await this.dbService.setObject("currentCustomer" + this.projectService.getUserId(), customer).then(d => {
                this.userService.changeMessage(customer.name + ' ' + customer.surname);
                if (undefined == customer.startPage || customer.startPage == "/home") {
                    this.router.navigate(['/home']);
                } else {
                    this.router.navigate([customer.startPage]);
                }
            });
        }
    }
    backClicked() {
        this._location.back();
    }
    async startBlankConsultation() {

        let customerList = await this.dbService.getObject("customerList" + this.projectService.getUserId());
        if (!customerList) {
            customerList = []
        }
        let customer: any = {};
        customer.name = "Neukunde";
        customer.surname = customerList.length;
        customer.form_of_address = "";
        customer.email = "";
        customer.address = {};
        customer.products = [];
        customer.address.telephone_number = "";
        customer.address.postal_code = "";
        customer.address.city = "";
        customer.address.street = "";
        customer.address.mobile_number = "";
        customer.date_of_birth = "";
        customer.birthdate = "";
        let today = new Date();
        let yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();
        //@ts-ignore
        if (dd < 10) dd = '0' + dd;
        //@ts-ignore
        if (mm < 10) mm = '0' + mm;
            
        let formattedToday = dd + '.' + mm + '.' + yyyy;
        customer.appointment_date = formattedToday;
        customer.address.house_number = "";
        customer.startPage = "";
        customer.expertKnowledge = false;
        customer.expertKnowledge2 = false;
        customer.notice = "";
        customer.existingCustomer = "";
        customer.tempId = Date.now();
        this.dbService.setObject("currentCustomer" + this.projectService.getUserId(), customer).then(async d => {
            customerList.push(customer);
            this.dbService.setObject("customerList" + this.projectService.getUserId(), customerList)
            this.userService.changeMessage(customer.name + ' ' + customer.surname);
            this.router.navigate(['/home']);
        })
    }
}
