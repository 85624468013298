import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { DbService } from 'src/services/storage/db.service';
import { Router } from '@angular/router';
import { ProjectService } from 'src/services/project/project-service';

@Component({
  selector: 'app-price-comparison',
  templateUrl: './price-comparison.page.html',
  styleUrls: ['./price-comparison.page.scss'],
})
export class PriceComparisonPage implements OnInit {

  constructor(private _location: Location,private dbService: DbService, private router: Router,private projectService: ProjectService) {
  }

  netto: any;
  custom_title: any;
  custom_workPrice: any;
  custom_basePrice: any;
  custom_fullPrice: any;
  guarantee: any;
  title: any;
  workPrice: any;
  basePrice: any;
  fullPrice: any;

  saving: any;
  type: any;
  usage: any;
  hasBonus : boolean = false;
  bonus: any;
  ngOnInit() {
  }
  ionViewDidEnter() {
    let that = this;
    this.dbService.setObject("currentPage",this.router.url);
    this.dbService.getObject("guarantee").then(value => {
      this.guarantee = value
    });

    this.dbService.getObject("selectedProduct"+this.projectService.getUserId()).then(product => {
      this.netto = (1 + product.netto);
      console.log(this.netto);
      console.log(product);
      // Filling of left Column
      if (undefined != product.workPrice) {
        this.custom_workPrice = product.workPrice * this.netto;
        this.custom_workPrice = (Math.round((this.custom_workPrice + Number.EPSILON) * 100) / 100).toFixed(2).toString().replace('.',',');
      }
      if (undefined != product.basePrice) {
        this.custom_basePrice = product.basePrice * this.netto;
        this.custom_basePrice = ( Math.round((this.custom_basePrice + Number.EPSILON) * 100) / 100  ).toFixed(2).toString().replace('.',',');
      }
      if (undefined != product.fullPrice) {
        this.custom_fullPrice = product.fullPrice;
        this.custom_fullPrice = this.custom_fullPrice * this.netto;
        this.custom_fullPrice = (Math.round((this.custom_fullPrice + Number.EPSILON) * 100) / 100).toFixed(2);
      }

      // Filling of right Column
      this.title = product.name
      this.workPrice = product.labour_price

      this.basePrice = product.base_price

      this.fullPrice = this.workPrice * product.kwh / 100 + this.basePrice;
      if(product.hasBonus) {
        this.hasBonus= true;
        this.bonus = product.bonus
        this.fullPrice -= product.bonus;
      }

      // this.fullPrice = this.fullPrice / this.netto;
      this.fullPrice = this.fullPrice.toFixed(2);

      // Filling of other content
      this.type = product.product_type.name;
      if(!isNaN(this.netto)) {
        this.saving = this.custom_fullPrice - this.fullPrice;
      } else {
        this.saving = product.fullPrice - this.fullPrice;
      }

      this.saving = this.saving.toLocaleString("de-DE", {maximumFractionDigits: 2,minimumFractionDigits: 2});
      this.custom_fullPrice = Number(this.custom_fullPrice).toLocaleString("de-DE", {maximumFractionDigits: 2,minimumFractionDigits: 2})
      this.fullPrice = Number(this.fullPrice).toLocaleString("de-DE", {maximumFractionDigits: 2,minimumFractionDigits: 2})
      this.basePrice = Number(this.basePrice).toLocaleString("de-DE", {maximumFractionDigits: 2,minimumFractionDigits: 2})
      this.workPrice = Number(this.workPrice).toLocaleString("de-DE", {maximumFractionDigits: 2,minimumFractionDigits: 2})
      // this.saving = this.saving.replace('.',',').toLocaleString("de-DE", {minimumFractionDigits: 2})
      this.usage = product.kwh;
    })
  }
  backClicked() {
    this._location.back();
  }
  navigateWithFragmet(item) {
    this.router.navigate(['/glossar'], { fragment: item });
  }
}
