import { Component, OnInit, ViewChild, ElementRef, ViewChildren } from '@angular/core';
import { DbService } from 'src/services/storage/db.service';
import { Router } from '@angular/router';
import { ProjectService } from 'src/services/project/project-service';
import { TooltipDirective } from '@cloudfactorydk/ng2-tooltip-directive';

import { ModalController } from '@ionic/angular';
import { BenefitsModal } from './benefits.modal';

@Component({
  selector: 'app-benefits',
  templateUrl: './benefits.page.html',
  styleUrls: ['./benefits.page.scss'],
})
export class BenefitsPage implements OnInit {
  @ViewChildren(TooltipDirective) tooltipDirective;

  infoItems : {title,infobox}[] = [] ;
  pageLoaded: boolean = false;
  public clicked1 : boolean = false;
  public clicked2 : boolean = false;
  public clicked3 : boolean = false;
  public clicked4 : boolean = false;
  public clicked5 : boolean = false;
  public clicked6 : boolean = false;
  public clicked7 : boolean = false;
  public clicked8 : boolean = false;
  public clicked9 : boolean = false;
    tooltip1="Als lokaler Energieversorger sind wir uns unserer besonderen Verantwortung bewusst und setzen uns sowohl regional als auch überregional für den Umwelt- und Klimaschutz ein."
    tooltip2="Durch eine Preisgarantie schützen Sie sich aktiv vor steigenden Energiekosten."
    tooltip3="Vertrauen beruht auf Gegenseitigkeit: Daher verlangen wir keine Kaution oder Vorauskasse-Zahlungen."
    tooltip4="Durch die monatliche Zahlungsweise profitieren Sie von geringeren Zahlungsbeträgen und kalkulierbaren Kosten."
    tooltip5="Zählerstände können Sie uns bequem online übermitteln. Am einfachsten geht das direkt per App. Mit der ichlesApp Ihrer Stadtwerke Hamm scannen Sie Zählerstände einfach mit der Handykamera. So behalten Sie auch Ihre Verbrauchswerte im Blick."
    tooltip6="Vertrauen beruht auf Gegenseitigkeit: Daher verlangen wir keine Kaution oder Vorauskasse-Zahlungen."
    tooltip7="Bei allen Fragen rund um das Thema Energieversorgung erreichen Sie unseren persönlichen Kundenservice. Wir beraten Sie gern!"
    tooltip8="Jedem unserer Kunden steht das Online-Kundenzentrum zur Verfügung. Hier können alle Verträge rund um die Uhr verwaltet werden."

  infobox1: any;
  infobox2: any;
  infobox3: any;
  infobox4: any;
  infobox5: any;
  infobox6: any;
  infobox7: any;
  infobox8: any;

  constructor(private dbService: DbService, private router: Router,private projectService: ProjectService,private modalController: ModalController) {

   }
   myOptions = {
    'trigger': "click",
    'hide-delay	' : 4000,
    'hideDelayTouchscreen' : 4000,
    'hideDelayAfterClick' : 4000,
    'displayTouchscreen' : true,
}

    ionViewWillEnter(){
        this.pageLoaded = true;

    }
    /*
   *  On Start fill table with appointments
   */
   ionViewDidEnter() {
     //TODO: Remove and check for problems
    this.infobox1 = this.tooltipDirective.find(elem => elem.id == "infobox1");
    this.infobox2 = this.tooltipDirective.find(elem => elem.id == "infobox2");
    this.infobox3 = this.tooltipDirective.find(elem => elem.id == "infobox3");
    this.infobox4 = this.tooltipDirective.find(elem => elem.id == "infobox4");
    this.infobox5 = this.tooltipDirective.find(elem => elem.id == "infobox5");
    this.infobox6 = this.tooltipDirective.find(elem => elem.id == "infobox6");
    this.infobox7 = this.tooltipDirective.find(elem => elem.id == "infobox7");
    this.infobox8 = this.tooltipDirective.find(elem => elem.id == "infobox8");
    this.dbService.setObject("currentPage"+this.projectService.getUserId(),this.router.url);
  }
  ionViewWillLeave(){
  }
  ionViewDidLeave(){  }

  /*
   *  Sets title of benefit Bubbles
   */
  ngOnInit() {
      this.infoItems.push({title: "Energieversorger vor Ort", infobox: false});
      this.infoItems.push({title: "Keine Kaution", infobox : false});
      this.infoItems.push({title: "Preisgarantie", infobox : false});
      this.infoItems.push({title: "Monatliche Zahlungsweise", infobox : false});
      this.infoItems.push({title: "Bequemes Zählerablesen", infobox : false});
      this.infoItems.push({title: "Keine Vorauskasse", infobox : false});
      this.infoItems.push({title: "Guter Kundenservice", infobox : false});
      this.infoItems.push({title: "Volle Online-Kontrolle", infobox : false});
  }
  ngAfterViewInit(): void {
  }

  /**
   * Opens Modal with more information
   * @param tooltip the chosen tooltip
   * 
   */
  async openModal(tooltip) {
      console.log(tooltip);
    const modal = await this.modalController.create({
        component: BenefitsModal,
        componentProps: {'tooltip' : tooltip},
        cssClass: 'benefits-modal',  
    });

    modal.present();
    let { data } = await modal.onWillDismiss();
  }
}
