import { Component, OnInit } from '@angular/core';
import { DbService } from 'src/services/storage/db.service';
import { Router } from '@angular/router';
import { ProjectService } from 'src/services/project/project-service';
import DE from '@angular/common/locales/de';
import { registerLocaleData } from '@angular/common';

@Component({
  selector: 'app-product-item-selection',
  templateUrl: './product-item-selection.page.html',
  styleUrls: ['./product-item-selection.page.scss'],
})
export class ProductItemSelectionPage implements OnInit {
  kWh: any;
  hasBonus: boolean;



  constructor(private dbService: DbService,private router: Router,private projectService: ProjectService) {
    registerLocaleData(DE);

}

  public eco_product_price: any;
  public standard_product_price: any;
  public eco_product;
  public standard_product;
  public type : any;
  public guarantee: string = "";
  ngOnInit() {
  }
  /*
   *  Shows and Fills data for ECO and Standard Product
   */
  ionViewWillEnter() {
      if(this.router.url == "/product-item-selection/power"){
        this.type = "power"
      }else{
        this.type = "gas"
      }
        this.dbService.getObject("guarantee").then(value => {
          this.guarantee = value;
        });
        this.dbService.getObject("matchedProducts").then(matchedProducts => {
          if(matchedProducts == false){
              this.router.navigate(['/calculator/'+this.type]);
          }
        
          matchedProducts.forEach(product => {
            product.labour_price = +product.labour_price;
            product.base_price = +product.base_price;

              if(product.eco == "Ja"){
                  this.eco_product = product;
                  this.kWh = product.kwh
                  this.eco_product_price = product.labour_price * product.kwh / 100 + product.base_price;
                  //this.eco_product_price = this.eco_product_price.toLocaleString("de-DE", {minimumFractionDigits: 2});
                  // this.standard_product_price = this.standard_product_price.toLocaleString("de-DE", {maximumFractionDigits: 2,minimumFractionDigits: 2});
                  this.eco_product_price = this.eco_product_price.toLocaleString("de-DE", {maximumFractionDigits: 2,minimumFractionDigits: 2});
                  this.eco_product.price = this.eco_product_price;
                  product.price = this.eco_product_price
              }else{
                  
                  this.standard_product = product;
                  this.kWh = product.kwh
                  this.standard_product_price = product.labour_price * product.kwh / 100 + product.base_price
                  this.standard_product_price = this.standard_product_price.toLocaleString("de-DE", {maximumFractionDigits: 2,minimumFractionDigits: 2});
                  product.price = this.standard_product_price;
              }
          });
        })
  }

  ionViewDidEnter() {
    this.dbService.setObject("currentPage"+this.projectService.getUserId(),this.router.url);
  }

  selectedEcoProduct() {
      this.dbService.setObject("selectedProduct"+this.projectService.getUserId(),this.eco_product).then(d => {
          this.router.navigate(["/product-details"]);
      })
      console.log(this.eco_product)
  }
  selectedStandardProduct() {
    this.dbService.setObject("selectedProduct"+this.projectService.getUserId(),this.standard_product).then(d => {
        this.router.navigate(["/product-details"]);
    })
}
}
