import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/services/user/user-service';
import { Router } from '@angular/router';
import { CustomerService } from 'src/services/customer/customer.service';
import { DbService } from 'src/services/storage/db.service';
import * as moment from 'moment';
import { ProjectService } from 'src/services/project/project-service';
import { Ionic4DatepickerModalComponent } from '@logisticinfotech/ionic4-datepicker';
import { ModalController } from '@ionic/angular';
@Component({
    selector: 'app-customer-data',
    templateUrl: './customer-data.page.html',
    styleUrls: ['./customer-data.page.scss'],
})
export class CustomerDataPage implements OnInit {
    public firstname: string = "";
    public lastname: string = "";
    public formOfAddress: string = "";
    public email: string = "";
    public telephone: string = "";
    public date_of_birth: string = "";
    public iban: string = "";
    public postal_code: string = "";
    public city: any[] = [];
    public temp: string;
    public appointment: string;
    public street: string = "";
    public ibanError: boolean = true;
    public bill_address_check: boolean = true;
    public email_confirm: string;
    public title: string = "";
    public mobile_number: string;
    public formOfAddress_bill: string;
    public street_bill: string;
    public city_bill: string;
    public fstreet_bill: string;
    public agb: boolean = false;
    public withdrawal: boolean = false;
    public data_privacy: boolean = false;
    public newsletter: boolean = false;
    public productinformation: boolean = false;
    public notice: string;
    public startPage: string = "/home";
    public newCustomer: boolean = true;
    public expertKnowledge: boolean = true;
    public existingCustomer: boolean = false;
    public customer: any;
    expertKnowledge2: boolean = true;

    // public customPickerOptions = {
    //     cssClass: "datePicker",
    // }
    house_number: any;
    boni: any;
    value: any;
    cityValue: any;

    datePickerAppointment: any = {
        inputDate: new Date(), // default new Date()
        // fromDate: new Date(), // default null
        // toDate: new Date(), // default null
        // showTodayButton: false, // default true
        closeOnSelect: true, // default false
        mondayFirst: true, // default false
        todayLabel: 'Heute', // default 'Today'
        closeLabel: 'Schließen', // default 'Close'
        clearButton: false,
        showTodayButton: false,
        titleLabel: 'Wählen Sie ein Datum aus', // default null
        monthsList: ["Jan", "Feb", "März", "April", "Mai", "Juni", "Juli", "Aug", "Sept", "Okt", "Nov", "Dez"],
        weeksList: ["S", "M", "D", "M", "D", "F", "S"],
        dateFormat: 'DD.MM.YYYY', // default DD MMM YYYY
        momentLocale: 'de', // Default 'en-US'
        btnProperties: {
            expand: 'block', // Default 'block'
            fill: '', // Default 'solid'
            size: '', // Default 'default'
            disabled: '', // Default false
            strong: '', // Default false
            color: 'primary' // Default ''
        },
        arrowNextPrev: {
            nextArrowSrc: 'assets/images/arrow_right.svg',
            prevArrowSrc: 'assets/images/arrow_left.svg'
        }, // This object supports only SVG files.
        highlightedDates: [
        ], // Default [],
        isSundayHighlighted: {
            fontColor: '#ee88bf' // Default null
        } // Default {}
    };
    datePickerBirthday: any = {
        inputDate: new Date(), // default new Date()
        // fromDate: new Date(), // default null
        // toDate: new Date(), // default null
        // showTodayButton: false, // default true
        closeOnSelect: true, // default false
        mondayFirst: true, // default false
        todayLabel: 'Heute', // default 'Today'
        closeLabel: 'Schließen', // default 'Close'
        clearButton: false,
        showTodayButton: false,
        titleLabel: 'Wählen Sie ein Datum aus', // default null
        monthsList: ["Jan", "Feb", "März", "April", "Mai", "Juni", "Juli", "Aug", "Sept", "Okt", "Nov", "Dez"],
        weeksList: ["S", "M", "D", "M", "D", "F", "S"],
        dateFormat: 'DD.MM.YYYY', // default DD MMM YYYY
        momentLocale: 'de', // Default 'en-US'
        btnProperties: {
            expand: 'block', // Default 'block'
            fill: '', // Default 'solid'
            size: '', // Default 'default'
            disabled: '', // Default false
            strong: '', // Default false
            color: 'primary' // Default ''
        },
        arrowNextPrev: {
            nextArrowSrc: 'assets/images/arrow_right.svg',
            prevArrowSrc: 'assets/images/arrow_left.svg'
        }, // This object supports only SVG files.
        highlightedDates: [
        ], // Default [],
        isSundayHighlighted: {
            fontColor: '#ee88bf' // Default null
        } // Default {}
    };


    constructor(private userService: UserService, private projectService: ProjectService, private modalController: ModalController, private router: Router, private customerService: CustomerService, private dbService: DbService) {
    }
    ngOnInit(): void {
    }
    ionViewDidEnter() {
        this.dbService.setObject("currentPage" + this.projectService.getUserId(), this.router.url);
    }
    /*
 *  Fill HTML with existing data
 */
    async ionViewWillEnter() {
        this.dbService.getObject("user").then(user => {
            (user.expert_knowledge != null) ? this.expertKnowledge = true : this.expertKnowledge = false;
            (user.expert_knowledge2 != null) ? this.expertKnowledge2 = true : this.expertKnowledge2 = false;
            console.log(this.expertKnowledge)
            console.log(this.expertKnowledge2)
            this.dbService.getObject("currentCustomer" + this.projectService.getUserId()).then(customer => {
                console.log(customer)
                if (customer != null && customer != false) {
                    this.newCustomer = false;
                    this.firstname = customer.name;
                    this.lastname = customer.surname;
                    this.formOfAddress = customer.form_of_address;
                    this.email = customer.email;
                    this.email_confirm = customer.email;
                    this.telephone = customer.address.telephone_number;
                    this.postal_code = customer.address.postal_code;
                    this.getCity();
                    this.cityValue = customer.address.city;
                    this.street = customer.address.street;
                    this.house_number = customer.address.house_number;
                    this.mobile_number = customer.address.mobile_number;
                    this.firstname = customer.name;
                    this.notice = customer.notice;
                    this.date_of_birth = customer.date_of_birth;
                    console.log(this.date_of_birth);
                    this.existingCustomer = customer.existingCustomer;
                    this.boni = customer.credit_worthiness;
                    this.title = customer.title
                    if (customer.startPage) {
                        this.startPage = customer.startPage;
                    }
                    if (customer.appointment_date != undefined || customer.appointment_date != null) {
                        this.appointment = customer.appointment_date;
                    }
                } else {
                    this.newCustomer = true;
                }
            });
        })
    }

    ionViewDidLeave() {
        this.firstname = null;
        this.lastname = null;
        this.formOfAddress = null;
        this.email = null;
        this.telephone = null;
        this.date_of_birth = null;
        this.iban = null;
        this.postal_code = null;
        this.street = null;
        this.city = [];
        this.house_number = null;
        this.mobile_number = null;
        this.email_confirm = null;
        this.appointment = null;
        this.cityValue = null;
    }
    /*
   *  Saves Customer by creating new Object or updating existing one
   */
    async save() {
        if(this.checkForPrimaryFields()){
            alert("Bitte geben sie alle Pflichtfelder an.")
            return;
        }
        if (this.newCustomer == true) {
            //SAVE new customer
            let customer: any = {};
            customer.name = this.firstname;
            customer.surname = this.lastname;
            customer.form_of_address = this.formOfAddress;
            customer.email = this.email;
            customer.address = {};
            customer.products = [];
            customer.address.telephone_number = this.telephone;
            customer.address.postal_code = this.postal_code;
            if (this.cityValue == null) {
                customer.address.city = null
            } else {
                customer.address.city = this.cityValue[0];
            }
            customer.address.street = this.street;
            customer.address.mobile_number = this.mobile_number;
            customer.date_of_birth = this.date_of_birth;
            customer.birthdate = moment(this.date_of_birth).format('DD/MM/YYYY');
            customer.appointment_date = this.appointment;
            customer.address.house_number = this.house_number;
            customer.startPage = this.startPage;
            customer.expertKnowledge = this.expertKnowledge;
            customer.expertKnowledge2 = this.expertKnowledge2;
            customer.notice = this.notice;
            customer.existingCustomer = this.existingCustomer;
            customer.title = this.title;
            customer.tempId = Date.now();

            // this.dbService.setObject("currentCustomer", customer).then(async d => {
            let customerList = await this.dbService.getObject("customerList" + this.projectService.getUserId());
            if (!customerList) {
                customerList = []
            }
            customerList.push(customer);
            this.dbService.setObject("customerList" + this.projectService.getUserId(), customerList)
            this.dbService.setObject("currentCustomer" + this.projectService.getUserId(), customer)

            if (this.title != null)
                this.userService.changeMessage(this.title + ' ' + this.firstname + ' ' + this.lastname);
            else
            this.userService.changeMessage(this.firstname + ' ' + this.lastname);
            this.router.navigate(['/customer-list']);
            // })
        } else {
            //Update customer
            this.dbService.getObject("currentCustomer" + this.projectService.getUserId()).then(async customer => {
                customer.name = this.firstname;
                customer.surname = this.lastname;
                customer.form_of_address = this.formOfAddress;
                customer.email = this.email;
                if (customer.address == undefined || customer.address == null) {
                    customer.address = {};
                }
                if (customer.products == undefined || customer.products == null) {
                    customer.products = [];
                }
                customer.address.telephone_number = this.telephone;
                customer.address.postal_code = this.postal_code;
                if (this.cityValue == null) {
                    customer.address.city = null
                } else {
                    customer.address.city = this.cityValue[0];
                }
                customer.address.street = this.street;
                customer.address.mobile_number = this.mobile_number;
                customer.date_of_birth = this.date_of_birth
                customer.birthdate = moment(this.date_of_birth).format('DD/MM/YYYY');
                customer.appointment_date = this.appointment
                customer.address.house_number = this.house_number;
                customer.startPage = this.startPage;
                customer.expertKnowledge = this.expertKnowledge;
                customer.expertKnowledge2 = this.expertKnowledge2;
                customer.existingCustomer = this.existingCustomer;
                customer.notice = this.notice;
                customer.title = this.title;

                // this.dbService.setObject("currentCustomer", customer).then(async d => {
                let customerList = await this.dbService.getObject("customerList" + this.projectService.getUserId());
                if (!customerList) {
                    customerList = []
                }
                Object.keys(customerList).forEach((key) => {
                    if (customer.tempId == undefined) {
                        if (customerList[key].id == customer.id) {
                            customerList[key] = customer;
                            this.dbService.setObject("customerList" + this.projectService.getUserId(), customerList)
                        }
                    } else {
                        if (customerList[key].tempId == customer.tempId) {
                            customerList[key] = customer;
                            this.dbService.setObject("customerList" + this.projectService.getUserId(), customerList)
                        }
                    }
                });
                this.dbService.setObject("currentCustomer" + this.projectService.getUserId(), customer)
                if (this.title != null)
                    this.userService.changeMessage(this.title + ' ' + this.firstname + ' ' + this.lastname);
                else
                    this.userService.changeMessage(this.firstname + ' ' + this.lastname);
                this.router.navigate(['/customer-list']);
                // })
            });
        }
    }
    async testForm() {
        if(this.checkForPrimaryFields()){
            alert("Bitte geben sie alle Pflichtfelder an.")
            return;
        }
        if (this.newCustomer == true) {
            //SAVE new customer
            let customer: any = {};
            customer.name = this.firstname;
            customer.surname = this.lastname;
            customer.form_of_address = this.formOfAddress;
            customer.email = this.email;
            customer.address = {};
            customer.products = [];
            customer.address.telephone_number = this.telephone;
            customer.address.postal_code = this.postal_code;
            if (this.cityValue == null) {
                customer.address.city = null
            } else {
                customer.address.city = this.cityValue[0];
            }
            customer.address.street = this.street;
            customer.address.house_number = this.house_number;
            customer.address.mobile_number = this.mobile_number;
            customer.date_of_birth = this.date_of_birth;
            customer.birthdate = moment(this.date_of_birth).format('DD/MM/YYYY');
            customer.appointment_date = this.appointment;
            customer.startPage = this.startPage;
            customer.existingCustomer = this.existingCustomer;
            customer.expertKnowledge = this.expertKnowledge;
            customer.expertKnowledge2 = this.expertKnowledge2;
            customer.notice = this.notice;
            customer.title = this.title;
            customer.tempId = Date.now();

            this.dbService.setObject("currentCustomer" + this.projectService.getUserId(), customer).then(async d => {
                let customerList = await this.dbService.getObject("customerList" + this.projectService.getUserId());
                if (!customerList) {
                    customerList = []
                }
                customerList.push(customer);
                this.dbService.setObject("customerList" + this.projectService.getUserId(), customerList)
                if (this.title != null)
                    this.userService.changeMessage(this.title + ' ' + this.firstname + ' ' + this.lastname);
                else
                    this.userService.changeMessage(this.firstname + ' ' + this.lastname);

                this.router.navigate([customer.startPage]);
            })
        } else {
            //Update customer
            this.dbService.getObject("currentCustomer" + this.projectService.getUserId()).then(customer => {
                customer.name = this.firstname;
                customer.surname = this.lastname;
                customer.form_of_address = this.formOfAddress;
                customer.email = this.email;
                if (customer.address == undefined || customer.address == null) {
                    customer.address = {};
                }
                customer.address.telephone_number = this.telephone;
                customer.address.postal_code = this.postal_code;
                if (this.cityValue == null) {
                    customer.address.city = null
                } else {
                    customer.address.city = this.cityValue[0];
                }
                customer.address.street = this.street;
                customer.address.house_number = this.house_number;
                if (customer.products == undefined || customer.products == null) {
                    customer.products = [];
                }
                customer.address.mobile_number = this.mobile_number;
                customer.date_of_birth = this.date_of_birth;
                customer.birthdate = moment(this.date_of_birth).format('DD/MM/YYYY');
                customer.appointment_date = this.appointment;
                customer.startPage = this.startPage;
                customer.expertKnowledge = this.expertKnowledge;
                customer.existingCustomer = this.existingCustomer;
                customer.expertKnowledge2 = this.expertKnowledge2;

                customer.notice = this.notice;
                customer.title = this.title;

                this.dbService.setObject("currentCustomer" + this.projectService.getUserId(), customer).then(async d => {
                    let customerList = await this.dbService.getObject("customerList" + this.projectService.getUserId());
                    if (!customerList) {
                        customerList = []
                    }
                    Object.keys(customerList).forEach((key) => {
                        if (customer.tempId == undefined) {
                            if (customerList[key].id == customer.id) {
                                customerList[key] = customer;
                                this.dbService.setObject("customerList" + this.projectService.getUserId(), customerList)
                            }
                        } else {
                            if (customerList[key].tempId == customer.tempId) {
                                customerList[key] = customer;
                                this.dbService.setObject("customerList" + this.projectService.getUserId(), customerList)
                            }
                        }
                    });
                    if (this.title != null)
                        this.userService.changeMessage(this.title + ' ' + this.firstname + ' ' + this.lastname);
                    else
                        this.userService.changeMessage(this.firstname + ' ' + this.lastname);
                    this.router.navigate([customer.startPage]);
                })
            });
        }
    }
    getCityValue() {
        console.log(this.cityValue)
    }


    /**
     * It gets the city from the postal code.
     */
    getCity() {
        let that = this;
        let city = [];
        this.dbService.getObject("productList").then(productList => {
            for (let i = 0; i < productList.length; i++) {
                for (let x = 0; x < productList[i].price_tables.length; x++) {
                    if (productList[i].price_tables[x].postal_code == that.postal_code) {
                        if (!city.includes(productList[i].price_tables[x].city)) {
                            city.push(productList[i].price_tables[x].city);
                        }
                    }
                }
            }
            if (city.length == 0) {
                that.city = [];
                that.cityValue = null;

            } else if (city.length == 1) {
                that.cityValue = city;
            } else {
                that.city = city
            }
        })
    }

    checkForPrimaryFields(){
        let bool = true;
        (this.lastname == "" || this.lastname == "")? bool = true: bool = false;
        return bool;
    }

    async openDatePickerAppointment() {
        const datePickerModal = await this.modalController.create({
            component: Ionic4DatepickerModalComponent,
            cssClass: 'li-ionic4-datePicker',
            componentProps: {
                'objConfig': this.datePickerAppointment,
                'selectedDate': this.appointment
            }
        });
        await datePickerModal.present();

        datePickerModal.onDidDismiss()
            .then((data) => {
                if (data.data.date != "Invalid date") {
                    console.log(data.data.date)
                    this.appointment = data.data.date;
                }
            });
    }

    async openDatePickerBirthday() {
        const datePickerModal = await this.modalController.create({
            component: Ionic4DatepickerModalComponent,
            cssClass: 'li-ionic4-datePicker',
            componentProps: {
                'objConfig': this.datePickerBirthday,
                'selectedDate': this.date_of_birth
            }
        });
        await datePickerModal.present();

        datePickerModal.onDidDismiss()
            .then((data) => {
                if (data.data.date != "Invalid date") {
                    console.log(data.data.date)
                    this.date_of_birth = data.data.date;
                }
            });
    }
}
