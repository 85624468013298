<ion-app>
    <ion-content class="has-header">
        <div class="page-content">
        <img class="green_bubble" src="../../assets/images/bubble-grey.svg">
        <img class="top-logo-2020" src="../../assets/images/top-logo.jpg">
        <div class="p-1">
            <h2>Welche Faktoren beeinflussen Ihren Strompreis</h2>
        </div>
        <img class="banner-small p-1" src="../../assets/images/content-influencing-factors.png">
        <div class="row p-1">
            <span class="verticalAlign margin-right">GLOSSAR / INFO</span> <img src="../../assets/images/glossar-icon.svg" [routerLink]="['/glossar']" routerDirection="forward"> 
        </div>
    </div>
    </ion-content>
</ion-app>