<ion-content id="login-form" >
    <div class="green-layer">
        <img class="logo" src="../../assets/images/hamm_logo.svg">
        <div class ="login-wrapper">
            <div class="right-wrapper">
              <form (ngSubmit)="loginForm()" [formGroup]="userForm" no-padding>
                <div class="image-container">
                  <img src="../../assets/images/login_symbol.svg">
                </div>
                <p>Digitale Beratung</p>
                <input type="text" formControlName="email" placeholder="Email" required/>
                <input type="password" formControlName="password" [ngClass]="{'': true}" placeholder="Passwort" required/>
                <!-- <input type="hidden" name="{{csrf.keys.name}}" value="{{csrf.name}}">
                <input type="hidden" name="{{csrf.keys.value}}" value="{{csrf.value}}"> -->
                <ion-row>
                    <ion-col size="6">
                        <button type="submit" expand="block">Anmelden</button><br/>
                    </ion-col>
                    <ion-col size="6">
                        <button class="password" expand="block"  [routerLink]="['/forgot-password']" routerDirection="forward"><span class="password-text">Passwort vergessen ?</span></button><br/>
                    </ion-col>
                    <!-- <ion-col size="6">
                      <button class="password" expand="block" (click)="getDeviceInformations()" ><span class="password-text">Geräteinformationen</span></button><br/>
                  </ion-col> -->
                </ion-row>
              </form>
            </div>
        </div>
    </div>
  </ion-content>
