import { Component, OnInit } from '@angular/core';
import { DbService } from 'src/services/storage/db.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { UserService } from 'src/services/user/user-service';
import { CustomerService } from 'src/services/customer/customer.service';
import { ProjectService } from 'src/services/project/project-service';

@Component({
  selector: 'app-appointment-list',
  templateUrl: './appointment-list.page.html',
  styleUrls: ['./appointment-list.page.scss'],
})
export class AppointmentListPage implements OnInit {
  currentCustomer: any;

  constructor(private dbService: DbService,private projectService: ProjectService ,private router : Router,private userService: UserService,private customerService: CustomerService) { 
    moment.locale('de');
  }
  public customers : any = [];

  ionViewDidEnter() {
  }
  ionViewDidLeave(){
      this.customers = []
  }
  /*
   *  On Start fill table with appointments
   */
  async ionViewWillEnter() {
    this.dbService.setObject("currentPage"+this.projectService.getUserId(),this.router.url);
    this.dbService.getObject("currentCustomer"+this.projectService.getUserId()).then(currentCustomer => {
        this.currentCustomer = currentCustomer;
    })
    let customerList = await this.dbService.getObject("customerList"+this.projectService.getUserId());
    customerList =  customerList.sort((a, b) => {
        return moment(a.appointment_date).diff(b.appointment_date);
      });
    Object.keys(customerList).forEach((key) => {
        let currentTime = moment()

        if(customerList[key].appointment_date != null && customerList[key].appointment_date != undefined){
            customerList[key].formatted_appointment_date = moment(customerList[key].appointment_date,'DD.MM.YYYY').format('dd. DD.MM.YY');
            if(customerList[key].formatted_appointment_date == "Invalid date"){
              customerList[key].formatted_appointment_date = '';
            }else{
              this.customers.push(customerList[key]);
            }
        }
    });

  }

  ngOnInit() {
  }
  async routeToCustomerdata(customer = null){
    this.customerService.setCustomer(customer);
    await this.dbService.setObject("currentCustomer"+this.projectService.getUserId(),customer).then(d => {
        this.router.navigate(['/customer-data']);
    });
  }
  async startConsultation(customer){
      console.log(customer.credit_worthiness)
      if(customer.credit_worthiness == 0){
          alert("Dieser Kunde besitzt eine negative Bonität. Es kann keine neue Beratung gestartet werden.")
          return;
      }else{
        this.customerService.setCustomer(customer);
        await this.dbService.setObject("currentCustomer"+this.projectService.getUserId(),customer).then(d => {
            this.userService.changeMessage(customer.name + ' ' + customer.surname);
            if(undefined  == customer.startPage ||customer.startPage == "/home"){
                this.router.navigate(['/home']);
            } else {
                this.router.navigate([customer.startPage]);
            }
        });
      }
  }
}
