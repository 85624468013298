import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {


  constructor() { }

  public currentCustomer;


  public setCustomer(customer) {
      this.currentCustomer = customer
  }

  public getCustomer(){
      return this.currentCustomer;
  }
}
