<ion-app>
    <ion-content class="has-header has-footer ">
        <div class="page-content grey_bubble_background">
            <div class="p-1">
                <h2>Hammer Preise für Ihren Verbrauch</h2>
            </div>
            <div class="product-selection">
                <span class="ecoPrice ion-text-center">
                    <!-- <sup>€</sup> -->
                    <span class="underline-green">{{eco_product_price}}</span><span class="year"> €/Jahr*</span>
                    <span class="smaller kwhPriceEco">*Bei einem Jahresverbrauch von {{kWh}} kWh</span>

                </span>
                <span class="standardPrice ion-text-center">
                    <sup></sup>
                    <span class="underline">{{standard_product_price}}</span><span class="year"> €/Jahr*</span><br>
                    <span class="smaller kwhPriceStandard">*Bei einem Jahresverbrauch von {{kWh}} kWh</span>
                </span>
                <ion-button mode="md" class="product-button1" (click)="selectedEcoProduct()">ZUM PRODUKT</ion-button>
                <ion-button mode="md" class="product-button2" (click)="selectedStandardProduct()">ZUM PRODUKT
                </ion-button>
                <ng-container *ngIf="type == 'gas'; else elseTemplate">
                    <div class="banner product-item-selection-image p-1">
                        <svg class="svg-overflow">
                            <defs>
                                <filter id="Rechteck_812" x="0" y="0" width="483" height="736"
                                    filterUnits="userSpaceOnUse">
                                    <feOffset input="SourceAlpha" />
                                    <feGaussianBlur stdDeviation="35" result="blur" />
                                    <feFlood flood-opacity="0.231" />
                                    <feComposite operator="in" in2="blur" />
                                    <feComposite in="SourceGraphic" />
                                </filter>
                                <filter id="Rechteck_874" x="296" y="21" width="483" height="690"
                                    filterUnits="userSpaceOnUse">
                                    <feOffset input="SourceAlpha" />
                                    <feGaussianBlur stdDeviation="35" result="blur-2" />
                                    <feFlood flood-opacity="0.078" />
                                    <feComposite operator="in" in2="blur-2" />
                                    <feComposite in="SourceGraphic" />
                                </filter>
                            </defs>
                            <g id="Gruppe_644" data-name="Gruppe 644" transform="translate(25 -51)">
                                <g transform="matrix(1, 0, 0, 1, -25, 51)" filter="url(#Rechteck_812)">
                                    <rect id="Rechteck_812-2" data-name="Rechteck 812" width="273" height="526" rx="5"
                                        transform="translate(105 105)" fill="#fff" />
                                </g>
                                <path id="csm_erdgas_1010x490_3bba0fb2b1"
                                    d="M5,0H268a5,5,0,0,1,5,5V150a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V5A5,5,0,0,1,5,0Z"
                                    transform="translate(80 156)" fill="#a2c617" />
                                <text id="CO2-neutral_Zahlungsweise_monatlich" data-name="CO2-neutral
                      
                      
                      Zahlungsweise monatlich" transform="translate(105 443)" font-size="15" font-family="Ubuntu">
                                    <tspan x="0" y="0">CO</tspan>
                                    <tspan y="0" font-size="8.75" baseline-shift="-4.999500156940817">2</tspan>
                                    <tspan y="0">-neutral</tspan>
                                    <tspan x="0" y="34"></tspan>
                                    <tspan x="0" y="68"></tspan>
                                    <tspan x="0" y="102">Zahlungsweise monatlich</tspan>
                                </text>
                                <text id="Zu_100_CO2-neutral" data-name="Zu 100% CO2-neutral"
                                    transform="translate(216 256)" fill="#fff" font-size="15" font-family="Ubuntu">
                                    <tspan x="-70.77" y="0">Zu 100% CO</tspan>
                                    <tspan y="0" font-size="8.75" baseline-shift="-4.999500156940817">2</tspan>
                                    <tspan y="0">-neutral</tspan>
                                </text>
                                <rect id="csm_erdgas_1010x490_3bba0fb2b1-2" data-name="csm_erdgas_1010x490_3bba0fb2b1"
                                    width="273" height="95" transform="translate(80 304)" fill="#f6f6f6" />
                                <text id="hammergasklima" transform="translate(216 205)" fill="#fff" font-size="26"
                                    font-family="Ubuntu" letter-spacing="0.08em">
                                    <tspan x="-118.482" y="0">hammerGASklima</tspan>
                                </text>
                                <line id="Linie_60" data-name="Linie 60" x2="223" transform="translate(105 455.5)"
                                    fill="none" stroke="#ccc" stroke-miterlimit="10" stroke-width="1" />
                                <line id="Linie_60-2" data-name="Linie 60" x2="223" transform="translate(105 523.5)"
                                    fill="none" stroke="#ccc" stroke-miterlimit="10" stroke-width="1" />
                                <line id="Linie_60-3" data-name="Linie 60" x2="223" transform="translate(105 557.5)"
                                    fill="none" stroke="#ccc" stroke-miterlimit="10" stroke-width="1" />
                                <path id="Icon_awesome-check" data-name="Icon awesome-check"
                                    d="M4.681,14.654.2,10.174A.689.689,0,0,1,.2,9.2l.975-.975a.689.689,0,0,1,.975,0l3.018,3.018,6.463-6.463a.689.689,0,0,1,.975,0l.975.975a.689.689,0,0,1,0,.975L5.656,14.654A.689.689,0,0,1,4.681,14.654Z"
                                    transform="translate(312.478 427.87)" fill="#a2c617" />
                                <path id="Icon_awesome-check-2" data-name="Icon awesome-check"
                                    d="M4.681,14.654.2,10.174A.689.689,0,0,1,.2,9.2l.975-.975a.689.689,0,0,1,.975,0l3.018,3.018,6.463-6.463a.689.689,0,0,1,.975,0l.975.975a.689.689,0,0,1,0,.975L5.656,14.654A.689.689,0,0,1,4.681,14.654Z"
                                    transform="translate(312.478 470.423)" fill="#a2c617" />
                                <path id="Icon_awesome-check-3" data-name="Icon awesome-check"
                                    d="M4.681,14.654.2,10.174A.689.689,0,0,1,.2,9.2l.975-.975a.689.689,0,0,1,.975,0l3.018,3.018,6.463-6.463a.689.689,0,0,1,.975,0l.975.975a.689.689,0,0,1,0,.975L5.656,14.654A.689.689,0,0,1,4.681,14.654Z"
                                    transform="translate(312.478 529.87)" fill="#a2c617" />
                                <g transform="matrix(1, 0, 0, 1, -25, 51)" filter="url(#Rechteck_874)">
                                    <rect id="Rechteck_874-2" data-name="Rechteck 874" width="273" height="480" rx="5"
                                        transform="translate(401 126)" fill="#fff" />
                                </g>
                                <path id="csm_strom_1010x490_05_4657a8ddb1"
                                    d="M5,0H268a5,5,0,0,1,5,5V129a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V5A5,5,0,0,1,5,0Z"
                                    transform="translate(376 177)" fill="#a2c617" />
                                <text id="Das_preiswerte_Gasprodukt_aus_Hamm_" data-name="Das preiswerte Gasprodukt 
                      aus Hamm
                      " transform="translate(513 253)" fill="#fff" font-size="15" font-family="Ubuntu">
                                    <tspan x="-94.185" y="0">Das preiswerte Gasprodukt </tspan>
                                    <tspan x="-35.415" y="18">aus Hamm</tspan>
                                    <tspan x="0" y="36"></tspan>
                                </text>
                                <text id="hammergas" transform="translate(514 226)" fill="#fff" font-size="20"
                                    font-family="Ubuntu" letter-spacing="0.08em">
                                    <tspan x="-62.83" y="0">hammerGAS</tspan>
                                </text>
                                <text id="Preisgarantie_bis_zum_31.12.2021" data-name="Preisgarantie bis zum
                      31.12.2021" transform="translate(105 485)" font-size="15" font-family="Ubuntu">
                                    <tspan x="0" y="0">Preisgarantie bis zum</tspan>
                                    <tspan x="0" y="20">{{guarantee}}</tspan>
                                </text>
                                <text id="CO2-neutral_Zahlungsweise_monatlich-2" data-name="CO2-neutral
                      
                      
                      Zahlungsweise monatlich" transform="translate(401 432)" font-size="15" font-family="Ubuntu">
                                    <tspan x="0" y="0">CO</tspan>
                                    <tspan y="0" font-size="8.75" baseline-shift="-4.999500156940817">2</tspan>
                                    <tspan y="0">-neutral</tspan>
                                    <tspan x="0" y="34"></tspan>
                                    <tspan x="0" y="68"></tspan>
                                    <tspan x="0" y="102">Zahlungsweise monatlich</tspan>
                                </text>
                                <line id="Linie_78" data-name="Linie 78" x2="223" transform="translate(401 444.5)"
                                    fill="none" stroke="#ccc" stroke-miterlimit="10" stroke-width="1" />
                                <line id="Linie_79" data-name="Linie 79" x2="223" transform="translate(401 512.5)"
                                    fill="none" stroke="#ccc" stroke-miterlimit="10" stroke-width="1" />
                                <line id="Linie_80" data-name="Linie 80" x2="223" transform="translate(401 546.5)"
                                    fill="none" stroke="#ccc" stroke-miterlimit="10" stroke-width="1" />
                                <path id="Icon_awesome-check-4" data-name="Icon awesome-check"
                                    d="M4.681,14.654.2,10.174A.689.689,0,0,1,.2,9.2l.975-.975a.689.689,0,0,1,.975,0l3.018,3.018,6.463-6.463a.689.689,0,0,1,.975,0l.975.975a.689.689,0,0,1,0,.975L5.656,14.654A.689.689,0,0,1,4.681,14.654Z"
                                    transform="translate(608.478 459.423)" fill="#a2c617" />
                                <path id="Icon_awesome-check-5" data-name="Icon awesome-check"
                                    d="M4.681,14.654.2,10.174A.689.689,0,0,1,.2,9.2l.975-.975a.689.689,0,0,1,.975,0l3.018,3.018,6.463-6.463a.689.689,0,0,1,.975,0l.975.975a.689.689,0,0,1,0,.975L5.656,14.654A.689.689,0,0,1,4.681,14.654Z"
                                    transform="translate(608.478 518.87)" fill="#a2c617" />
                                <text id="Preisgarantie_bis_zum_31.12.2021-2" data-name="Preisgarantie bis zum
                      31.12.2021" transform="translate(401 474)" font-size="15" font-family="Ubuntu">
                                    <tspan x="0" y="0">Preisgarantie bis zum</tspan>
                                    <tspan x="0" y="20">{{guarantee}}</tspan>
                                </text>
                                <text id="_-" data-name="-" transform="translate(617 430)" font-size="14"
                                    font-family="Arial-BoldMT, Arial" font-weight="700">
                                    <tspan x="-4.662" y="0">-</tspan>
                                </text>
                            </g>
                        </svg>
                    </div>

                    <!-- <img class="" src="../../assets/images/content-product-selection-gas.svg"> -->
                </ng-container>
                <ng-template #elseTemplate>
                    <div class="banner product-item-selection-image p-1">
                        <svg class="svg-overflow">
                            <defs>
                              <filter id="Rechteck_812" x="0" y="0" width="483" height="736" filterUnits="userSpaceOnUse">
                                <feOffset input="SourceAlpha"/>
                                <feGaussianBlur stdDeviation="35" result="blur"/>
                                <feFlood flood-opacity="0.231"/>
                                <feComposite operator="in" in2="blur"/>
                                <feComposite in="SourceGraphic"/>
                              </filter>
                              <filter id="Rechteck_874" x="296" y="21" width="483" height="690" filterUnits="userSpaceOnUse">
                                <feOffset input="SourceAlpha"/>
                                <feGaussianBlur stdDeviation="35" result="blur-2"/>
                                <feFlood flood-opacity="0.078"/>
                                <feComposite operator="in" in2="blur-2"/>
                                <feComposite in="SourceGraphic"/>
                              </filter>
                            </defs>
                            <g id="Gruppe_644" data-name="Gruppe 644" transform="translate(25 -51)">
                              <g transform="matrix(1, 0, 0, 1, -25, 51)" filter="url(#Rechteck_812)">
                                <rect id="Rechteck_812-2" data-name="Rechteck 812" width="273" height="526" rx="5" transform="translate(105 105)" fill="#fff"/>
                              </g>
                              <path id="csm_erdgas_1010x490_3bba0fb2b1" d="M5,0H268a5,5,0,0,1,5,5V150a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V5A5,5,0,0,1,5,0Z" transform="translate(80 156)" fill="#a2c617"/>
                              <text id="Ökostrom_Zahlungsweise_monatlich" data-name="Ökostrom
                          
                          
                          Zahlungsweise monatlich" transform="translate(105 443)" font-size="15" font-family="SegoeUI, Segoe UI"><tspan x="0" y="0">Ökostrom</tspan><tspan x="0" y="34"></tspan><tspan x="0" y="68"></tspan><tspan x="0" y="102">Zahlungsweise monatlich</tspan></text>
                              <text id="Zu_100_aus_regenerativen_Energiequellen" data-name="Zu 100% aus 
                          regenerativen Energiequellen" transform="translate(216 250
                          )" fill="#fff" font-size="15" font-family="SegoeUI, Segoe UI"><tspan x="-44.194" y="0">Zu 100% aus </tspan><tspan x="-96.735" y="18">regenerativen Energiequellen</tspan></text>
                              <rect id="csm_erdgas_1010x490_3bba0fb2b1-2" data-name="csm_erdgas_1010x490_3bba0fb2b1" width="273" height="95" transform="translate(80 304)" fill="#f6f6f6"/>
                              <text id="hammerSTROMklima" transform="translate(216 206)" fill="#fff" font-size="24" font-family="SegoeUI, Segoe UI" letter-spacing="0.08em"><tspan x="-126.179" y="0">hammerSTROMklima</tspan></text>
                              <line id="Linie_60" data-name="Linie 60" x2="223" transform="translate(105 455.5)" fill="none" stroke="#ccc" stroke-miterlimit="10" stroke-width="1"/>
                              <line id="Linie_60-2" data-name="Linie 60" x2="223" transform="translate(105 523.5)" fill="none" stroke="#ccc" stroke-miterlimit="10" stroke-width="1"/>
                              <line id="Linie_60-3" data-name="Linie 60" x2="223" transform="translate(105 557.5)" fill="none" stroke="#ccc" stroke-miterlimit="10" stroke-width="1"/>
                              <path id="Icon_awesome-check" data-name="Icon awesome-check" d="M4.681,14.654.2,10.174A.689.689,0,0,1,.2,9.2l.975-.975a.689.689,0,0,1,.975,0l3.018,3.018,6.463-6.463a.689.689,0,0,1,.975,0l.975.975a.689.689,0,0,1,0,.975L5.656,14.654A.689.689,0,0,1,4.681,14.654Z" transform="translate(312.478 427.87)" fill="#a2c617"/>
                              <path id="Icon_awesome-check-2" data-name="Icon awesome-check" d="M4.681,14.654.2,10.174A.689.689,0,0,1,.2,9.2l.975-.975a.689.689,0,0,1,.975,0l3.018,3.018,6.463-6.463a.689.689,0,0,1,.975,0l.975.975a.689.689,0,0,1,0,.975L5.656,14.654A.689.689,0,0,1,4.681,14.654Z" transform="translate(312.478 470.423)" fill="#a2c617"/>
                              <path id="Icon_awesome-check-3" data-name="Icon awesome-check" d="M4.681,14.654.2,10.174A.689.689,0,0,1,.2,9.2l.975-.975a.689.689,0,0,1,.975,0l3.018,3.018,6.463-6.463a.689.689,0,0,1,.975,0l.975.975a.689.689,0,0,1,0,.975L5.656,14.654A.689.689,0,0,1,4.681,14.654Z" transform="translate(312.478 529.87)" fill="#a2c617"/>
                              <g transform="matrix(1, 0, 0, 1, -25, 51)" filter="url(#Rechteck_874)">
                                <rect id="Rechteck_874-2" data-name="Rechteck 874" width="273" height="480" rx="5" transform="translate(401 126)" fill="#fff"/>
                              </g>
                              <path id="csm_strom_1010x490_05_4657a8ddb1" d="M5,0H268a5,5,0,0,1,5,5V129a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V5A5,5,0,0,1,5,0Z" transform="translate(376 177)" fill="#a2c617"/>
                              <text id="Das_preiswerte_Stromprodukt_aus_Hamm_" data-name="Das preiswerte Stromprodukt 
                          aus Hamm
                          " transform="translate(513 253)" fill="#fff" font-size="15" font-family="SegoeUI, Segoe UI"><tspan x="-98.859" y="0">Das preiswerte Stromprodukt </tspan><tspan x="-35.358" y="18">aus Hamm</tspan><tspan font-family="Ubuntu"><tspan x="0" y="36"></tspan></tspan></text>
                              <text id="hammerSTROM" transform="translate(514 226)" fill="#fff" font-size="20" font-family="SegoeUI, Segoe UI" letter-spacing="0.08em"><tspan x="-77.634" y="0">hammerSTROM</tspan></text>
                              <text id="Preisgarantie_bis_zum_31.12.2021" data-name="Preisgarantie bis zum
                          31.12.2021" transform="translate(105 485)" font-size="15" font-family="SegoeUI, Segoe UI"><tspan x="0" y="0">Preisgarantie bis zum</tspan><tspan x="0" y="20">{{ guarantee}}</tspan></text>
                              <text id="Ökostrom_Zahlungsweise_monatlich-2" data-name="Ökostrom
                          
                          
                          Zahlungsweise monatlich" transform="translate(401 432)" font-size="15" font-family="SegoeUI, Segoe UI"><tspan x="0" y="0">Ökostrom</tspan><tspan x="0" y="34"></tspan><tspan x="0" y="68"></tspan><tspan x="0" y="102">Zahlungsweise monatlich</tspan></text>
                              <line id="Linie_78" data-name="Linie 78" x2="223" transform="translate(401 444.5)" fill="none" stroke="#ccc" stroke-miterlimit="10" stroke-width="1"/>
                              <line id="Linie_79" data-name="Linie 79" x2="223" transform="translate(401 512.5)" fill="none" stroke="#ccc" stroke-miterlimit="10" stroke-width="1"/>
                              <line id="Linie_80" data-name="Linie 80" x2="223" transform="translate(401 546.5)" fill="none" stroke="#ccc" stroke-miterlimit="10" stroke-width="1"/>
                              <path id="Icon_awesome-check-4" data-name="Icon awesome-check" d="M4.681,14.654.2,10.174A.689.689,0,0,1,.2,9.2l.975-.975a.689.689,0,0,1,.975,0l3.018,3.018,6.463-6.463a.689.689,0,0,1,.975,0l.975.975a.689.689,0,0,1,0,.975L5.656,14.654A.689.689,0,0,1,4.681,14.654Z" transform="translate(608.478 459.423)" fill="#a2c617"/>
                              <path id="Icon_awesome-check-5" data-name="Icon awesome-check" d="M4.681,14.654.2,10.174A.689.689,0,0,1,.2,9.2l.975-.975a.689.689,0,0,1,.975,0l3.018,3.018,6.463-6.463a.689.689,0,0,1,.975,0l.975.975a.689.689,0,0,1,0,.975L5.656,14.654A.689.689,0,0,1,4.681,14.654Z" transform="translate(608.478 518.87)" fill="#a2c617"/>
                              <text id="Preisgarantie_bis_zum_31.12.2021-2" data-name="Preisgarantie bis zum
                          31.12.2021" transform="translate(401 474)" font-size="15" font-family="SegoeUI, Segoe UI"><tspan x="0" y="0">Preisgarantie bis zum</tspan><tspan x="0" y="20">{{guarantee}}</tspan></text>
                              <text id="_-" data-name="-" transform="translate(617 430)" font-size="14" font-family="Arial-BoldMT, Arial" font-weight="700"><tspan x="-4.662" y="0">-</tspan></text>
                            </g>
                          </svg>
                          
                    </div>
                </ng-template>
            </div>
        </div>
    </ion-content>
</ion-app>