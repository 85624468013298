import { Component, OnInit } from '@angular/core';
import { DbService } from 'src/services/storage/db.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-expert-knowledge',
  templateUrl: './expert-knowledge.page.html',
  styleUrls: ['./expert-knowledge.page.scss'],
})
export class ExpertKnowledgePage implements OnInit {

  constructor(private dbService: DbService, private router: Router) {
  }
  ionViewDidEnter() {
    this.dbService.setObject("currentPage",this.router.url);
  }
  ngOnInit() {
  }

}
