<ion-app class="test">
    <ion-content class="has-header has-footer ">
        <div class="page-content grey_bubble_background">
            <!-- <img class="grey_bubble" src="../../assets/images/bubble-grey.svg"> -->
            <div class="p-1">
                <h2>Die Stadtwerke Hamm - Ihr zuverlässiger Energieversorger</h2>
                <p>Die Stadtwerke Hamm stehen für Versorgungssicherheit, Leistungsfähigkeit und Wirtschaftlichkeit.</p>
            </div>
            <div>
                <!-- <img class="banner product-selection-image p-1" src="../../assets/images/content-product-selection.svg"> -->
                <ion-grid>
                    <ion-row>
                        <ion-col>
                            <ion-card mode="md">
                                <ion-item class="ion-no-padding">
                                    <img class="maxwidth" src="../../assets/images/product-power.jpg">
                                </ion-item>

                                <ion-card-content mode="md" class="ion-text-center">
                                    <img slot="center" src="../../assets/icon/icon-power-socket.svg">
                                    <div>
                                        <h1>STROM</h1>
                                        Schont die Umwelt und den Geldbeutel.
                                    </div>
                                    <ion-button mode="md" class="product-button1 ion-margin-top"
                                        [routerLink]="['/calculator/power']" routerDirection="forward">ZUM PRODUKT
                                    </ion-button>
                                </ion-card-content>
                            </ion-card>
                        </ion-col>
                        <ion-col>
                            <ion-card mode="md">
                                <ion-item class="ion-no-padding">
                                    <img class="maxwidth" src="../../assets/images/product-gas.jpg">
                                </ion-item>

                                <ion-card-content mode="md" class="ion-text-center">
                                    <img slot="center" src="../../assets/icon/icon-gas.svg">
                                    <div>
                                        <h1>ERDGAS</h1>
                                        Preiswert, komfortabel und sicher.
                                    </div>
                                    <ion-button mode="md" class="product-button2 ion-margin-top"
                                        [routerLink]="['/calculator/gas']" routerDirection="forward">ZUM PRODUKT
                                    </ion-button>
                                </ion-card-content>
                            </ion-card>
                        </ion-col>
                        <ion-col>
                        </ion-col>
                    </ion-row>
                </ion-grid>
                <!-- <div *ngIf="cross_selling"> 
                    Ich interessiere mich für folgende / zusätzliche Produkte:
                    <ion-grid>
                        <ion-row>
                            <ion-col>
                                <input #value [(ngModel)]="cross_selling_power" type="checkbox" class="cross_selling_power"  (change)="submitCheckboxes()"
                                    id="cross_selling_power" name="privacy"> STROM
                            </ion-col>
                            <ion-col>
                                <input #value [(ngModel)]="cross_selling_gas" type="checkbox" class="cross_selling_gas"  (change)="submitCheckboxes()"
                                    id="cross_selling_gas" name="privacy"> ERDGAS
                            </ion-col>
                            <ion-col>
                                <input #value [(ngModel)]="cross_selling_none" type="checkbox" class="cross_selling_none"  (change)="unset()"
                                    id="cross_selling_none" name="privacy"> KEIN INTERESSE
                            </ion-col>
                            <ion-col>
                            </ion-col>
                            <ion-col>
                            </ion-col>
                            <ion-col>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </div> -->
            </div>
        </div>
    </ion-content>
</ion-app>