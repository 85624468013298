import { Injectable } from '@angular/core';
import { Network } from '@awesome-cordova-plugins/network/ngx';

@Injectable({
  providedIn: 'root'
})


export class NetworkServiceService {

  private connected: boolean = true; // Change to false in production

  constructor(private network: Network) { }

  /**
   * Monitor the current network status
   * @return void
   */
  initNetworkMonitor() {
    console.log("init network monitoring...")
    this.network.onDisconnect().subscribe(() => {
      console.log("network disconnected :(");
      this.connected = false;
    });
    // watch network for a connection
    this.network.onConnect().subscribe(() => {
      console.log("network connected! :)");
      this.connected = true;
      /* If we need to know the connection type
      if (this.network.type === this.network.Connection.WIFI) {
        console.log("we got a wifi connection, woohoo!");
      }
      if (this.network.type === this.network.Connection.ETHERNET) {
        console.log("we got a wifi connection, woohoo!");
      }
      */
    });
  };

  /**
   * Returns the current networkstatus on device
   * @return string
   */
  getNetworkStatus() {
    return this.connected ? 'online' : 'offline';
  }

}
