<ion-content *ngIf="modalType == 'bonus'" class="modal-wrap">

    <ng-container>
        <img class="green_gray_bubble" src="../../assets/images/bubble-green-gray.svg">
        <button class="bonus-dismiss"><img (click)="dismiss()" src="../../assets/icon/icon-material-close.svg"/></button>

        <div class="ion-text-center bonus-button">
            <div class="bonus-amount">
                <h2 class="bonus-head">+{{bonus}}€</h2>
                <p class="bonus-desc">Neukundenbonus</p>
            </div>
            <div class="bonus-bottom">
                <button class="bonus-save" (click)="addBonus()">Bonus hinzufügen</button>
                <p class="bonus-bottom-text">* Die Boni werden mit der ersten Jahresrechnung gewährt (für Strom ab einem jährlichen Verbrauch <br>
                    von 2.001 kWh – gilt nicht für hammerSTROMnachtspeicher. Für Erdgas ab einem jährlichen Verbrauch von 2.667 kWh).
                </p>
            </div>
        </div>
    </ng-container>
    
</ion-content>

<ion-content *ngIf="modalType == 'calc'" class="comparison-wrap">

    <ng-container>

        <div class="compare-header ion-text-center">
            <h3>Preisvergleich</h3>
            <span>Bestens versorgt mit {{productName}}</span>
        </div>
        <div class="input-wrapper">
            <ion-grid class="input-table">
                <ion-row>
                    <ion-col class="title-label">
                        <ion-label>
                            Preise Ihres aktuellen Anbieters:
                        </ion-label>
                    </ion-col>
                </ion-row>
                <ion-row class="compare-input input-first">
                    <ion-col>
                        <ion-label>
                            Arbeitspreis in Ct/kWh
                        </ion-label>
                        <ion-input mode="md"  class="input-col" [(ngModel)]="workPrice" type="number" value="Arbeitspreis"></ion-input >
                    </ion-col>
                    <ion-col>
                        <ion-label>
                            Grundpreis in €/Jahr
                        </ion-label>
                        <ion-input mode="md"  class="input-col" [(ngModel)]="basePrice" type="number" value="Grundpreis"></ion-input >
                    </ion-col>
                </ion-row>
            </ion-grid>
            <ion-grid class="input-table">
                <ion-row>
                    <ion-col class="title-label">
                        <ion-label>
                            Alternativ:
                        </ion-label>
                    </ion-col>
                </ion-row>
                <ion-row class="compare-input">
                    <ion-col>
                        <ion-label>
                            Gesamtpreis aus Ihrer aktuellen Jahresrechnung (€/Jahr)
                        </ion-label>
                    </ion-col>
                    <ion-col>
                        <ion-input mode="md"  class="input-col input_height" [(ngModel)]="fullPrice" type="number" value="Gesamtpreis"></ion-input >
                    </ion-col>
                </ion-row>
            </ion-grid>
            <ion-grid class="input-table">
                <ion-row class="checkbox-netto">
                    <ion-label>Die angegebenen Preise sind Nettopreise.</ion-label>
                    <input mode="md" (change)="changeNetto($event)" [(ngModel)]="nettoBool" type="checkbox"/>
                </ion-row>
            </ion-grid>
        </div>

        <div class="compare-button-wrapper">
            <ion-button mode="md" class="compare-button" (click)="calculateUsage()">Preise vergleichen</ion-button>
        </div>
        
    </ng-container>

</ion-content>