import { Component, OnInit } from '@angular/core';
import { DbService } from 'src/services/storage/db.service';
import { Router, ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';
import { ProjectService } from 'src/services/project/project-service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.page.html',
  styleUrls: ['./terms-of-use.page.scss'],
})
export class TermsOfUsePage implements OnInit {
  pdfSrc: any;

  constructor(private modalController: ModalController, private dbService: DbService,private projectService: ProjectService,private _location: Location) {
  }

  ngOnInit() {
  }
  ionViewWillEnter(){
    this.dbService.getObject('pdfSrcTermsOfUse').then(src => {
      src = src.split(',')
      console.log(src[1])
      this.pdfSrc = this.base64ToArrayBuffer(src[1]);  
    })
  }
  backClicked() {
    this._location.back();
  }
/**
 * Convert a base64 string to an array buffer.
 * @param base64 - The base64 string that you want to convert to an array buffer.
 * @returns A Uint8Array
 */
  base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
   }
}  
