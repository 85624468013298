<ion-app>
    <ion-content class="has-header">
        <div class="page-content">
            <div class="bubble">
                <img class="green_bubble" src="../../assets/images/bubble.svg">
            </div>
            <div class="p-1">
                <h2>Preisvergleich Erdgas</h2>
                <p>Hamms gute Geister senken Ihre Energiekosten</p>
            </div>
            <img class="banner p-1" src="../../assets/images/comparison-gas-conten.svg">
            <div class="row">
                <span class="verticalAlign margin-right">PREISGARANTIE BIS {{guarantee}}</span> <img
                    src="../../assets/images/glossar-icon.svg" [routerLink]="['/glossar']" routerDirection="forward">
            </div>
        </div>
    </ion-content>

</ion-app>