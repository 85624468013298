<ion-content>
    <div class="pdf-container" *ngIf="productInformation != true; else elseTemplate">
        <button class="bonus-dismiss"><img (click)="dismiss()"
                src="../../assets/icon/icon-material-close.svg" /></button>
        <pdf-viewer [src]="pdfSrc" [original-size]="false" [zoom]="1" [zoom-scale]="'page-width'"></pdf-viewer>
    </div>
    <ng-template #elseTemplate>
        <div class="productinformation">
            <button class="bonus-dismiss"><img (click)="dismiss()"
                    src="../../assets/icon/icon-material-close.svg" /></button>
            <div class="information-body">
                <h2>Ihr Produkt: {{currentProduct.name}} für die PLZ {{currentProduct.postal_code}}</h2>
                <h4> Preiskonditionen</h4>
                <p>Diese Preise garantieren wir Ihnen bis zum {{guarantee}}.</p>
                <p><b>Der Preis für die im Rechnungsjahr gelieferte Energiemenge gilt wie folgt: </b></p>
                <p>Jahresverbrauch: {{currentProduct.kwh | number : '' :'DE'}} kWh </p>
                <p>Arbeitspreis: {{currentProduct.labour_price | number : '1.2-2' :'DE'}} Ct/kWh* </p>
                <p>Jahresgrundpreis: {{currentProduct.base_price | number : '1.2-2' :'DE'}} Euro*</p>
                <p>Vertragslaufzeit: 12 Monate </p>
                <div *ngIf="currentProduct.type_id == 3; else gasBonus">
                    <p> Neukundenbonus: 50,00 €</p>
                </div>
                <ng-template #gasBonus>
                    <p> Neukundenbonus: 95,00 €</p>
                </ng-template>

                <table>
                    <tr>
                        <th>Jahresverbrauch</th>
                        <th>Arbeitspreis (brutto)</th>
                        <th>Jahresgrundpreis (brutto)</th>
                    </tr>

                    <tr>
                        <td>bis {{productList[0].consumption_upper_limit| number : '' :'DE'}} kWh</td>
                        <td>{{productList[0].labour_price| number : '1.2-2' :'DE'}} Ct/kWh</td>
                        <td>{{productList[0].base_price| number : '1.2-2' :'DE'}} €/Jahr</td>
                    </tr>

                    <tr>
                        <td>ab {{productList[1].consumption_lower_limit| number : '' :'DE'}} kWh</td>
                        <td>{{productList[1].labour_price| number : '1.2-2' :'DE'}} Ct/kWh</td>
                        <td>{{productList[1].base_price| number : '1.2-2' :'DE'}} €/Jahr</td>
                    </tr>

                    <tr>
                        <td>ab {{productList[2].consumption_lower_limit| number : '' :'DE'}} kWh</td>
                        <td>{{productList[2].labour_price| number : '1.2-2' :'DE'}} Ct/kWh</td>
                        <td>{{productList[2].base_price| number : '1.2-2' :'DE'}} €/Jahr</td>
                    </tr>

                    <tr>
                        <td>ab {{productList[3].consumption_lower_limit| number : '' :'DE'}} kWh</td>
                        <td>{{productList[3].labour_price| number : '1.2-2' :'DE'}} Ct/kWh</td>
                        <td>{{productList[3].base_price| number : '1.2-2' :'DE'}} €/Jahr</td>
                    </tr>

                    <tr>
                        <td>ab {{productList[4].consumption_lower_limit| number : '' :'DE'}} kWh</td>
                        <td>{{productList[4].labour_price| number : '1.2-2' :'DE'}} Ct/kWh</td>
                        <td>{{productList[4].base_price| number : '1.2-2' :'DE'}} €/Jahr</td>
                    </tr>
                </table>


                <p>
                    * Wird der Belieferungszeitraum von 12 Monaten unterschritten, ist für die Preisfindung stets der
                    tatsächliche Verbrauch maßgeblich. Die Preisfindung orientiert sich in diesem Fall weiterhin an der
                    oben
                    aufgeführten Verbrauchsstaffelung.
                </p>
                <p>
                    Das Entgelt setzt sich aus einem Grundpreis und einem verbrauchsabhängigen Arbeitspreis -
                    entsprechend
                    der
                    aufgeführten Ziffer 6 in den Allgemeinen Geschäftsbedingungen für die Belieferung von Stromkunden
                    bzw.
                    Erdgaskunden - zusammen.
                </p>
                <h4>
                    Neukundenbonus
                </h4>
                <p>
                    Die Stadtwerke Hamm GmbH gewähren Neukunden, deren Erdgasverbrauch in den ersten 12 Liefermonaten
                    2.666
                    kWh
                    übersteigt, einmalig einen Neukundenbonus von 95,00 € (brutto) bzw. deren Stromverbrauch in den
                    ersten
                    12
                    Liefermonaten 2.000 kWh übersteigt, einmalig einen Neukundenbonus von 50,00 € (brutto). Der
                    Neukundenbonus
                    steht dem Kunden nur zu, wenn die Verbrauchsstelle in den letzten 6 Monaten vor Vertragsschluss
                    nicht
                    auf
                    den Namen des Kunden von den Stadtwerken Hamm GmbH mit Erdgas bzw. Strom beliefert wurde. Der Bonus
                    wird
                    mit
                    der ersten Jahresabrechnung bzw. Schlussrechnung verrechnet.
                </p>
                <h4>
                    Mindestliefer-/Vertragslaufzeit
                </h4>

                <p>
                    Die Stadtwerke Hamm GmbH beliefern Sie mindestens 12 Monate (Mindestlieferzeit). Die
                    Mindestlieferzeit
                    bestimmt die Erstlaufzeit Ihres Vertrages und damit Ihre Kündigungsmöglichkeiten. Die Erstlaufzeit
                    beginnt
                    mit der Annahme Ihres Antrages und endet nach 12 Monaten Energiebelieferung. Die Erstlaufzeit des
                    Vertrages
                    ist also in der Regel länger als die Mindestlieferzeit. Er verlängert sich nach Ablauf der
                    Erstlaufzeit auf unbestimmte Zeit und kann von jeder Partei mit einer Frist von einem Monat
                    gekündigt werden, erstmals zum
                    Ablauf der Erstlaufzeit. Die Kündigung bedarf der Textform. Besondere Kündigungsrechte (nach Gesetz
                    oder den beigefügten AGB) bleiben unberührt.
                </p>
                <h4>
                    Online-Rechnungsstellung
                </h4>
                <p>
                    Sie werden per E-Mail benachrichtigt, sobald Ihre Rechnung im Online-Kundenzentrum als PDF-Datei zum
                    Abruf
                    hinterlegt ist. Wir bitten Sie daher, Ihre Kommunikationsdaten (E-Mail-Adresse, Telefonnummer etc.)
                    im
                    Online-Portal stets auf dem aktuellen Stand zu halten.
                </p>
            </div>
        </div>
    </ng-template>
</ion-content>