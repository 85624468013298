<ion-app>
    <ion-content class="has-header has-footer">
        <div class=" page-content grey_bubble_background">
            <img *ngIf="noBackground == true" class="tüv-logo" src="../../assets/images/logo-tüv-rheinland.png">
            <div class="header">
                <div class="p-1">
                    <h2>{{productName}}</h2>
                    <div mode="md" class="subtitle">
                        <p mode="md" class="subtitle-text" [innerHTML]="test.subtitle"></p>
                    </div>
                </div>
                <ion-row mode="md" class="myProduct">
                    <ion-col size="3">
                        <span>Mein Tarif</span>
                    </ion-col>
                    <ion-col size="3">
                        <span class="productPrice">{{productName}}</span>
                    </ion-col>
                    <ion-col class="flex" size="6">
                        <ion-row>
                            <img (click)="openModal('bonus')" src="../../assets/icon/icon-price-tag.svg">
                            <ng-container *ngIf="hasBonus == false; else elseTemplate">
                                <span class="productPrice"> {{productPrice}} €/Jahr</span><br>
                            </ng-container>
                            <ng-template #elseTemplate>
                                <span class="productPrice"> {{productPrice}} €/Jahr (inkl. {{bonus}} € Neukundenbonus im ersten Lieferjahr)</span><br>
                            </ng-template>
                        </ion-row>
                        <ion-row><span>Arbeitspreis: {{labour_price}} Ct/kWh</span><br></ion-row>
                        <ion-row><span>Grundpreis: {{base_price}} €/Jahr</span></ion-row>
                    </ion-col>
                </ion-row>
            </div>
            <div class="content">
                <div class="benefits">
                    <h2>Ihre Vorteile</h2>
                    <ion-list mode="md" lines="none">
                        <ion-item *ngFor="let benefit of test.benefits">
                            <img class="tick" src="../../assets/icon/icon-tick.svg">
                            <ion-label>
                                <p [innerHTML]="benefit.title"></p>
                                <ion-note [innerHTML]="benefit.subtitle"></ion-note>
                            </ion-label>
                        </ion-item>
                    </ion-list>
                </div>
                <div class="background_informations">
                    <div *ngIf="noBackground == true">
                        <h2>Hintergrundinformationen</h2>
                        <ion-card mode="md" no-margin (click)="expandItem(item)"
                            *ngFor="let item of test.background_informations">
                            <ion-card-header>
                                <span class="title" [innerHTML]="item.title"></span>
                                <span [ngClass]="item.expanded ? 'expanded' : 'not-expanded'"></span>

                            </ion-card-header>

                            <ion-card-content>
                                <app-expandable expandHeight="100px" [expanded]="item.expanded">
                                    <p [innerHTML]="item.content"></p>
                                </app-expandable>
                            </ion-card-content>
                        </ion-card>
                    </div>
                    <div class="footer-buttons">
                        <!-- <ion-button mode="md" class="margin-right" *ngIf="bonusEligible" expand="block">BONUS HINZUFÜGEN</ion-button> -->
                        <ion-button mode="md" (click)="routeToCheckout()">WEITER ZUM BESTELLPROZESS</ion-button>
                    </div>
                </div>
            </div>
            <!-- fab placed to the (vertical) center and end -->
            <ion-fab class="comparison-popup" vertical="bottom" horizontal="end" slot="fixed">
                <ion-fab-button (click)="openModal('calc')">
                    <ion-icon src="../assets/icon/icon-calc.svg"></ion-icon>
                </ion-fab-button>
            </ion-fab>              
        </div>
    </ion-content>
</ion-app>