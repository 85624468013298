<ion-content id="login-form">
    <div class="green-layer">
        <img class="logo" src="../../assets/images/hamm_logo.svg">
        <div class ="login-wrapper">
            <div class="right-wrapper column">
                <div class="image-container">
                    <img src="../../assets/images/login_symbol.svg">
                  </div>
                <div>
                    <p>Falls Sie ihr Passwort vergessen haben, <br>
                    melden Sie sich bei Ihrem Vertriebsorganisator, <br>
                    dieser kann Ihr Passwort zurück setzen</p><br>
                </div>
                <div>
                    <ion-button [routerLink]="['/login']" routerDirection="forward">Zurück zum Login</ion-button>
                </div>
              </div>
              
        </div>
    </div>
  </ion-content>
