import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class UserService {

	constructor() { }

    currentUser: string;

	private messageSource = new BehaviorSubject('null');
	currentMessage = this.messageSource.asObservable();

	
	private smartyContent = '';
	private subpath: string;

    changeMessage(message: string) {
        this.currentUser = message;
		this.messageSource.next(message);
	  }
}
