import { Component, Input, NgModule } from '@angular/core';
import { fabric } from 'fabric';
import { ModalController } from '@ionic/angular';
import { OnInit } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'signature-modal',
  templateUrl: './signature.modal.html',
  styleUrls: ['./signature.modal.scss'],

})
/*
 *  Handles the Signature Popup
 * Implements FabricJS for the Signature fiels
 */
export class SignatureModal {

  constructor(private modalController: ModalController) { }
  signature: any;
  pdfSrc: any;
  static canvas: any;
  ngOnInit() {
    this.pdfSrc = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";

  }
  ngAfterViewInit(): void {
    SignatureModal.canvas = new fabric.Canvas('canvas');
    SignatureModal.canvas.isDrawingMode = 1;
    SignatureModal.canvas.freeDrawingBrush.color = "black";
    SignatureModal.canvas.freeDrawingBrush.width = 1;
    SignatureModal.canvas.renderAll();

  }
  dismiss() {

    if (SignatureModal.canvas.toDataURL({
      format: 'png',
    }) == "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAZAAAABkCAYAAACoy2Z3AAAAAXNSR0IArs4c6QAAA5hJREFUeF7t1bENAAAIwzD6/9P8kNnsXSyk7BwBAgQIEAgCCxsTAgQIECBwAuIJCBAgQCAJCEhiMyJAgAABAfEDBAgQIJAEBCSxGREgQICAgPgBAgQIEEgCApLYjAgQIEBAQPwAAQIECCQBAUlsRgQIECAgIH6AAAECBJKAgCQ2IwIECBAQED9AgAABAklAQBKbEQECBAgIiB8gQIAAgSQgIInNiAABAgQExA8QIECAQBIQkMRmRIAAAQIC4gcIECBAIAkISGIzIkCAAAEB8QMECBAgkAQEJLEZESBAgICA+AECBAgQSAICktiMCBAgQEBA/AABAgQIJAEBSWxGBAgQICAgfoAAAQIEkoCAJDYjAgQIEBAQP0CAAAECSUBAEpsRAQIECAiIHyBAgACBJCAgic2IAAECBATEDxAgQIBAEhCQxGZEgAABAgLiBwgQIEAgCQhIYjMiQIAAAQHxAwQIECCQBAQksRkRIECAgID4AQIECBBIAgKS2IwIECBAQED8AAECBAgkAQFJbEYECBAgICB+gAABAgSSgIAkNiMCBAgQEBA/QIAAAQJJQEASmxEBAgQICIgfIECAAIEkICCJzYgAAQIEBMQPECBAgEASEJDEZkSAAAECAuIHCBAgQCAJCEhiMyJAgAABAfEDBAgQIJAEBCSxGREgQICAgPgBAgQIEEgCApLYjAgQIEBAQPwAAQIECCQBAUlsRgQIECAgIH6AAAECBJKAgCQ2IwIECBAQED9AgAABAklAQBKbEQECBAgIiB8gQIAAgSQgIInNiAABAgQExA8QIECAQBIQkMRmRIAAAQIC4gcIECBAIAkISGIzIkCAAAEB8QMECBAgkAQEJLEZESBAgICA+AECBAgQSAICktiMCBAgQEBA/AABAgQIJAEBSWxGBAgQICAgfoAAAQIEkoCAJDYjAgQIEBAQP0CAAAECSUBAEpsRAQIECAiIHyBAgACBJCAgic2IAAECBATEDxAgQIBAEhCQxGZEgAABAgLiBwgQIEAgCQhIYjMiQIAAAQHxAwQIECCQBAQksRkRIECAgID4AQIECBBIAgKS2IwIECBAQED8AAECBAgkAQFJbEYECBAgICB+gAABAgSSgIAkNiMCBAgQEBA/QIAAAQJJQEASmxEBAgQICIgfIECAAIEkICCJzYgAAQIEBMQPECBAgEASEJDEZkSAAAECAuIHCBAgQCAJCEhiMyJAgACBB9bxAGWtoMHOAAAAAElFTkSuQmCC") {
      alert("Bitte geben Sie Ihre Unterschrift ein bevor Sie fortfahren.");
      return;
    }
    this.modalController.dismiss({
      'dismissed': true,
      'signatureData': JSON.stringify(SignatureModal.canvas.toDataURL({
        format: 'png',
      }))
    });
  }
  /*
 *  Clear canvas
 */
  refreshCanvas() {
    SignatureModal.canvas.clear();
    SignatureModal.canvas.renderAll();
  }
}