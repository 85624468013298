import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-contract',
  templateUrl: './view-contract.page.html',
  styleUrls: ['./view-contract.page.scss'],
})
export class ViewContractPage implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
