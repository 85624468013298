import { Component } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { Router } from '@angular/router';
import { DbService } from 'src/services/storage/db.service';
import { ProjectService } from 'src/services/project/project-service';
import { UserService } from 'src/services/user/user-service';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage {
    customers: any;
    currentCustomer: any;

  constructor(private menu: MenuController, private userService: UserService,private router: Router,private dbService: DbService,private projectService: ProjectService) {
  }
  ionViewDidEnter() {
    this.dbService.setObject("currentPage"+this.projectService.getUserId(),this.router.url);
    this.dbService.getObject("currentCustomer"+this.projectService.getUserId()).then(currentCustomer => {
        if(currentCustomer != null && currentCustomer != undefined && currentCustomer != false){
            if(currentCustomer.title != null)
            this.userService.changeMessage(currentCustomer.title + ' ' + currentCustomer.name + ' ' + currentCustomer.surname);
            else
            this.userService.changeMessage(currentCustomer.name + ' ' + currentCustomer.surname);
            this.currentCustomer = currentCustomer;
        }
    })
  }
  routeToPrices(){
    this.router.navigateByUrl('/price-comparison');
}
/**
 * It creates a new customer object, saves it to the database, and then navigates to the benefits page.
 */
async startBlankConsultation(){

        let customerList = await this.dbService.getObject("customerList"+this.projectService.getUserId());
        if(!customerList) {
            customerList = []
        }
        let customer :any = {};
        customer.name = "Neukunde";
        customer.surname = customerList.length;
        customer.form_of_address = "";
        customer.email = "";
        customer.address = {};
        customer.products = [];
        customer.address.telephone_number = "";
        customer.address.postal_code = "";
        customer.address.city = "";
        customer.address.street = "";
        customer.address.mobile_number = "";
        customer.date_of_birth = "";
        customer.birthdate = "";
        customer.appointment_date = "";
        customer.address.house_number = "";
        customer.startPage = "";
        customer.expertKnowledge = false;
        customer.expertKnowledge2 = false;
        customer.notice = "";
        customer.existingCustomer = "";
        this.dbService.setObject("currentCustomer"+this.projectService.getUserId(), customer).then(async d => {
        customerList.push(customer);
        this.dbService.setObject("customerList"+this.projectService.getUserId(), customerList)
        this.userService.changeMessage(customer.name + ' ' + customer.surname);
        this.router.navigate(['/benefits']);
    })
    }
}
