import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DbService } from 'src/services/storage/db.service';
import { Gas, Gas_eco } from 'src/models/gas'
import { Electricity, Electricity_Eco } from 'src/models/electricity'
import { ExpandableComponent } from "../components/expandable/expandable.component";
import { ProjectService } from 'src/services/project/project-service';
import { BonusModal } from './bonus.modal';
import { ModalController } from '@ionic/angular';
import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera/ngx';
import { HostListener } from "@angular/core";
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.page.html',
  styleUrls: ['./product-details.page.scss'],
})

export class ProductDetailsPage implements OnInit {
  sub: any;
  productName: any = "";
  productPrice: any = "";
  test: any = {};
  items: any = [];
  bonus: string;
  hasBonus: boolean = false;
  noBackground: boolean = false;
  labour_price: any;
  base_price: any;
  eco: boolean;
  guarantee: string;
  screenHeight: number;
  screenWidth: number;
  public showWebcam = false;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public errors: WebcamInitError[] = [];

  // latest snapshot
  public webcamImage: WebcamImage = null;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }


  constructor(private router: Router, private dbService: DbService,private diagnostic: Diagnostic, private route: ActivatedRoute, private modalController: ModalController, private projectService: ProjectService, private camera: Camera) {
    this.getScreenSize();

  }

  ngOnInit() {
  }
  ionViewDidLeave() {
    this.hasBonus = false;
    this.bonus = null;
  }
  /*
 *  Checks what Product has been chosen and fills page with coorect Data
 */
  ionViewWillEnter() {
    this.dbService.getObject("selectedProduct" + this.projectService.getUserId()).then(product => {
      if (undefined != product.bonus) {
        this.hasBonus = true;
        this.bonus = product.bonus;
        let tmp;
        tmp = product.price.replace(/[^\d\,\-]/g, "");
        tmp = tmp.replace(',', '.');
        tmp = parseFloat(tmp) - product.bonus;
        this.productPrice = tmp.toLocaleString("de-DE", { maximumFractionDigits: 2,minimumFractionDigits: 2 });
      } else {
        this.productPrice = product.price.toLocaleString("de-DE", { maximumFractionDigits: 2,minimumFractionDigits: 2 });
      }
      this.productName = product.name;
      this.labour_price = product.labour_price.toFixed(2).toString().replace('.', ',');;
      this.base_price = product.base_price.toFixed(2).toString().replace('.', ',');
      this.dbService.getObject("guarantee").then(guarantee => {
        if (product.type_id == 3) {
          console.log(product)

          //ECO
          if (product.eco == "Ja") {
            this.test = new Electricity_Eco(guarantee)

            this.eco = true;
            this.noBackground = true;
            //Standard
          } else {
            this.test = new Electricity(guarantee)
            this.noBackground = false;
          }

          //Gas
        } else {

          //ECO
          if (product.eco == "Ja") {
            this.test = new Gas_eco(guarantee)
            this.eco = true;
            this.noBackground = true;
            //Standard
          } else {
            this.test = new Gas(guarantee)
            this.noBackground = false;
          }
        }
      })
      //Electricity

    })
    WebcamUtil.getAvailableVideoInputs()
    .then((mediaDevices: MediaDeviceInfo[]) => {
      this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
    });
  }
  ionViewDidEnter() {
    this.dbService.setObject("currentPage", this.router.url);
  }
  /*
 *  Expand custom-cropdown
 *  Functionality in expand.component
 */
  expandItem(item): void {
    item.expanded = !item.expanded;
  }
  async openCamera() {
      this.showWebcam = true;
  }
  async openModal(type) {
    const modal = await this.modalController.create({
      component: BonusModal,
      cssClass: type + '-modal',
      componentProps: { 'modalType': type },
    });

    modal.present();
    let { data } = await modal.onWillDismiss();
    if (data != undefined && Object.keys(data).length !== 0) {
      console.log(data);
      this.dbService.getObject('selectedProduct' + this.projectService.getUserId()).then(product => {
        console.log(product.price)
        // product.price = product.price.replace(',','.')
        // tmp = parseFloat(tmp);
        let tmp;
        tmp = product.price.replace(/[^\d\,\-]/g, "");
        tmp = tmp.replace(',', '.');
        tmp = parseFloat(tmp) - product.bonus;
        this.productPrice = tmp.toLocaleString("de-DE", { maximumFractionDigits: 2,minimumFractionDigits: 2 });
        this.hasBonus = product.hasBonus;
        this.bonus = product.bonus;
      })
    }
  }

  addPicture(base64Image) {
    this.dbService.getObject("currentCustomer" + this.projectService.getUserId()).then(customer => {
      this.dbService.getObject("customerList" + this.projectService.getUserId()).then(customerList => {
        Object.keys(customerList).forEach((key) => {
          if ((customerList[key].email == customer.email) && (customerList[key].street == customer.street) &&
            (customerList[key].postal_code == customer.postal_code) && (customerList[key].name == customer.name) &&
            (customerList[key].surname == customer.surname)) {
            if (customer.pictures == undefined) customer.pictures = [];
            customer.pictures.push(base64Image)
            customerList[key] = customer;
            this.dbService.setObject("currentCustomer" + this.projectService.getUserId(), customer).then(d => {
              this.router.navigate(['/photo-contract'])
            })
          }
        });
      });
    });
  }
  routeToCheckout() {
    this.router.navigate(['/checkout'])
  }
  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean|string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    console.info(webcamImage.imageAsDataUrl);
    this.addPicture(webcamImage.imageAsDataUrl);

  }

  public cameraWasSwitched(deviceId: string): void {
    console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean|string> {
    return this.nextWebcam.asObservable();
  }
}
