<div class="signature-modal">
    <div class="p-1">
        <h2>Vertragsabschluss </h2>
        <p>Bitte bestätigen Sie den Vertragsabschluss mit Ihrer Unterschrift.<br>
            Vielen Dank!</p>
    </div>
    <div class="row signature">
        <button class="refresh" (click)="refreshCanvas();"><img class="icon" src="../../assets/icon/refresh-outline.svg"></button>
        <div #container class="container">
            <div id="testid" class="d-flex justify-content-center canvas-container-wrapper">
                <canvas width="400" height="100" id="canvas"></canvas>
            </div>
        </div>
        <p>Unterschrift</p>
    </div>
    <div class="ion-text-center signature-button">
        <button class="signature-save" (click)="dismiss()">SPEICHERN</button>
    </div>
</div>