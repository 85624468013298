import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})

  /*
   *  Handles Local-Storage for Offline Functionality
   */
export class DbService {

  constructor(public router: Router,private storage: Storage) { }

public async get(string) {
    return await this.storage.get(string);
 }

 // set a key/value
 set(key, value) {
   // console.log("Write: " + value)
   this.storage.set(key, value);
 }

 async unset(key) {
   this.storage.remove(key);
 }

 // set a user
 async setUser(user) {
    this.setObject('user', user);
    return "done"
 }

 // set a key/value object in stoage
 async setObject(key: string, object: Object) {
   // console.info("SQL Driver: ", this.storage.driver);

   try {
     const result = await this.storage.set(key, JSON.stringify(object));
     console.log('set Object in storage: '+'"key":'+key +'   ' + result);
   } catch (reason) {
     console.log(reason);
   }
 }
 // get a key/value object from storage
 async getObject(key) {
   console.info("SQL Driver: ", this.storage.driver);

   return await this.storage.get(key).then((result) => {
     if(result) {
       return JSON.parse(result);
     } else {
       return false;
     }
   });
 }
 clearStorage() {
   this.storage.clear();
 }
}
