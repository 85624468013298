import { Component, Input, NgModule } from '@angular/core';
import { fabric } from 'fabric';
import { ModalController } from '@ionic/angular';
import { OnInit } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { DbService } from 'src/services/storage/db.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from '../components/components.module';
import { BrowserModule } from '@angular/platform-browser';
import { GlossarPageRoutingModule } from '../glossar/glossar-routing.module';
import { ProjectService } from 'src/services/project/project-service';
@Component({
  selector: 'benefits-modal',
  templateUrl: './benefits.modal.html',
  styleUrls: ['./benefits.modal.scss'],

})
  /*
   *  Handles Popup-Functions
   */
export class BenefitsModal {
  // Data passed in by componentProps
  @Input() tooltip: string;
    currentTooltip: string;

  constructor(private modalController: ModalController, private dbService: DbService,private projectService: ProjectService) {
  }
  public bonus: any;

  workPrice: any;
  basePrice: any;
  fullPrice: any;

  wpPerYear: any;
  result: any;
  pdfSrc: any
  ngOnInit() {
    // this.pdfSrc = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";
  }
  ionViewWillEnter() {
      this.currentTooltip = this.tooltip
  }
  dismiss() {
    this.modalController.dismiss({
      });
  }
}