<ion-app>
    <ion-content class="has-header has-footer">
        <div class="glossar-content">
            <ion-button class="glossar-btn" (click)="backClicked()">Glossar</ion-button>
            <div class="content-wrapper">
                <!-- button class="tippy">Text</button -->
                <ul class="glossar-tablist">
                  <li class="glossar-tablink" [ngClass]="{active:tab == 'a_d' || tab == undefined}">
                    <a class="glossar-link active" (click)="tabSelect('a_d')">A-D</a>
                  </li>
                  <li class="glossar-tablink" [ngClass]="{active: tab == 'e_h'}">
                    <a class="glossar-link" (click)="tabSelect('e_h')">E-H</a>
                  </li>
                  <li class="glossar-tablink" [ngClass]="{active: tab == 'i_l'}">
                    <a class="glossar-link" (click)="tabSelect('i_l')">I-L</a>
                  </li>
                  <li class="glossar-tablink" [ngClass]="{active:tab == 'm_p'}">
                    <a class="glossar-link" (click)="tabSelect('m_p')">M-P</a>
                  </li>
                  <li class="glossar-tablink" [ngClass]="{active:tab == 'q_z'}">
                    <a class="glossar-link" (click)="tabSelect('q_z')">Q-Z</a>
                  </li>
                </ul>
                <div id="glossar-tab-content" class="glossar-tab-content" *ngIf="!showDetail">
                    <div id="glossar-A-D" class="glossar-accordion" *ngIf="tab == 'a_d' || tab == undefined">
                        <ion-card no-margin (click)="singleDetail(item)" *ngFor="let item of a_d_items">
                            <ion-card-header>
                                <span class="title" [innerHTML]="item.title"></span>
                                <span class="glossar-arrow not-expanded"></span>
                            </ion-card-header>
                        </ion-card>
                    </div>
                    <div id="glossar-E-H" class="glossar-accordion" *ngIf="tab == 'e_h'">
                        <ion-card no-margin (click)="singleDetail(item)" *ngFor="let item of e_h_items">
                            <ion-card-header>
                                <span class="title" [innerHTML]="item.title"></span>
                                <span class="glossar-arrow not-expanded"></span>
                            </ion-card-header>
                        </ion-card>
                    </div>
                    <div id="glossar-I-L" class="glossar-accordion" *ngIf="tab == 'i_l'">
                        <ion-card no-margin (click)="singleDetail(item)" *ngFor="let item of i_l_items">
                            <ion-card-header>
                                <span class="title" [innerHTML]="item.title"></span>
                                <span class="glossar-arrow not-expanded"></span>
                            </ion-card-header>
                        </ion-card>
                    </div>
                    <div id="glossar-M-P" class="glossar-accordion" *ngIf="tab == 'm_p'">
                        <ion-card no-margin (click)="singleDetail(item)" *ngFor="let item of m_p_items">
                            <ion-card-header>
                                <span class="title" [innerHTML]="item.title"></span>
                                <span class="glossar-arrow not-expanded"></span>
                            </ion-card-header>
                        </ion-card>
                    </div>
                    <div id="glossar-Q-Z" class="glossar-accordion" *ngIf="tab == 'q_z'">
                        <ion-card no-margin (click)="singleDetail(item)" *ngFor="let item of q_z_items">
                            <ion-card-header>
                                <span class="title" [innerHTML]="item.title"></span>
                                <span class="glossar-arrow not-expanded"></span>
                            </ion-card-header>
                        </ion-card>
                    </div>
                </div>
                <div class="glossar-detail" *ngIf="showDetail">
                    <ion-card>
                        <ion-card-header>
                            <h3 (click)="hideDetail()">{{detailView.title}}</h3>
                        </ion-card-header>
                        <ion-card-content>
                            <p class="glossar-detail-text" [innerHTML]="detailView.content"></p>
                        </ion-card-content>
                    </ion-card>
                </div>
                <!-- <div class="glossar-tab-content">
                    <div id="glossar-A-D" class="glossar-accordion" *ngIf="tab == 'a_d' || tab == undefined && detail == undefined">
                        <ion-card no-margin [ngClass]="(detail == undefined || detail != item) ? 'not-active' : ''" (click)="detailSelect(item)" *ngFor="let item of a_d_items">
                            <ion-card-header>
                                <span class="title" [innerHTML]="item.title"></span>
                                <span [ngClass]="item.expanded ? 'expanded' : 'not-expanded'"></span>
        
                            </ion-card-header>
        
                            <ion-card-content class="glossar-accordion-content">
                                <p [innerHTML]="item.content"></p>
                            </ion-card-content>
                        </ion-card>
                    </div>
                    <div id="glossar-E-H" class="glossar-accordion" *ngIf="tab == 'e_h'">
                        <ion-card no-margin (click)="detailSelect(item)" *ngFor="let item of e_h_items">
                            <ion-card-header>
                                <span class="title" [innerHTML]="item.title"></span>
                                <span [ngClass]="item.expanded ? 'expanded' : 'not-expanded'"></span>
        
                            </ion-card-header>
        
                            <ion-card-content class="glossar-accordion-content">
                                <app-expandable expandHeight="100px" [expanded]="item.expanded">
                                    <p [innerHTML]="item.content"></p>
                                </app-expandable>
                            </ion-card-content>
                        </ion-card>
                    </div>
                    <div id="glossar-I-L" class="glossar-accordion" *ngIf="tab == 'i_l'">
                        <ion-card no-margin (click)="detailSelect(item)" *ngFor="let item of i_l_items">
                            <ion-card-header>
                                <span class="title" [innerHTML]="item.title"></span>
                                <span [ngClass]="item.expanded ? 'expanded' : 'not-expanded'"></span>
        
                            </ion-card-header>
        
                            <ion-card-content class="glossar-accordion-content">
                                <app-expandable expandHeight="100px" [expanded]="item.expanded">
                                    <p [innerHTML]="item.content"></p>
                                </app-expandable>
                            </ion-card-content>
                        </ion-card>
                    </div>
                    <div id="glossar-M-P" class="glossar-accordion" *ngIf="tab == 'm_p'">
                        <ion-card no-margin (click)="detailSelect(item)" *ngFor="let item of m_p_items">
                            <ion-card-header>
                                <span class="title" [innerHTML]="item.title"></span>
                                <span [ngClass]="item.expanded ? 'expanded' : 'not-expanded'"></span>
        
                            </ion-card-header>
        
                            <ion-card-content class="glossar-accordion-content">
                                <app-expandable expandHeight="100px" [expanded]="item.expanded">
                                    <p [innerHTML]="item.content"></p>
                                </app-expandable>
                            </ion-card-content>
                        </ion-card>
                    </div>
                </div> -->
            </div>
        </div>
    </ion-content>
</ion-app>