<ion-app>
    <ion-content>
        <div class="p-1">
            <h2>Datenschutz</h2>
        </div>
                <div #container>
                    <div id="testid" class="d-flex justify-content-center canvas-container-wrapper">
                        <canvas width="400" height="100" id="canvas2"></canvas>
                    </div>
                </div>
                <p>Unterschrift</p>
                <ion-button class="product-button1" (click)="saveSignature()">SPEICHERN</ion-button>
    </ion-content>
</ion-app>