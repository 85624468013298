import { Component, OnInit } from '@angular/core';
import { ProjectService } from 'src/services/project/project-service';
import { Router } from '@angular/router';
import { DbService } from 'src/services/storage/db.service';

@Component({
  selector: 'app-influencing-factors',
  templateUrl: './influencing-factors.page.html',
  styleUrls: ['./influencing-factors.page.scss'],
})
export class InfluencingFactorsPage implements OnInit {

    constructor(private dbService: DbService, private router: Router, private projectService: ProjectService) {}
  ngOnInit() {
  }
  ionViewDidEnter() {
    this.dbService.setObject("currentPage",this.router.url);
  }

}
