import { Component, OnInit } from '@angular/core';
import { DbService } from 'src/services/storage/db.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NetworkServiceService } from 'src/services/network-service.service';
import { LoadingController } from '@ionic/angular';
import { ProjectService } from 'src/services/project/project-service';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-data-transfer',
    templateUrl: './data-transfer.page.html',
    styleUrls: ['./data-transfer.page.scss'],
})
export class DataTransferPage implements OnInit {

    public sendContract: boolean = false;
    public generateLink: boolean = false;
    public customerEmail = "";
    public customer = {email: ""};
    constructor(private dbService: DbService, private router: Router, private networkService: NetworkServiceService,
        private loadingController: LoadingController,
        private projectService: ProjectService,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {

    }
    ionViewWillEnter() {
        this.dbService.getObject("currentCustomer" + this.projectService.getUserId()).then(customer => {
            this.customer = customer;
        });
        (this.route.snapshot.paramMap.get('generateLink') == "true" )?this.generateLink = true: this.generateLink = false;
    }
    ionViewDidEnter() {
        this.dbService.setObject("currentPage" + this.projectService.getUserId(), this.router.url);
    }

    /*
   *  Send finalized Contract to DB if internet is enabled otherwise save in local storage 
   */
    async syncAndRoute(sendContract) {
        if(this.projectService.getUser() == null){
            alert("Es gibt ein Problem mit Ihrem User. Bitte einmal neu einloggen, damit dieses Problem behoben werden kann.")
            return;
          }
        this.dbService.getObject("currentCustomer" + this.projectService.getUserId()).then(customer => {
            this.dbService.getObject("selectedProduct" + this.projectService.getUserId()).then(product => {
                product.newProduct = true;
                customer.products.push(product)
                this.dbService.setObject("currentCustomer" + this.projectService.getUserId(), customer).then(async d => {
                    if (this.networkService.getNetworkStatus() == "online") {
                        const loading = await this.loadingController.create({
                            cssClass: 'loadingScreen',
                            message: this.generateLink == true?'Ihr Auftrag wird übermittelt.': 'Ihr Bestätigungslink wird übermittelt.',
                            backdropDismiss: false,
                        });
                        await loading.present();
                        if(sendContract == true){
                            this.projectService.uploadCustomerContract(customer).then(data => {
                                if (data == "notExisting") {
                                    alert("Der Benutzer wurde in der Datenbank gelöscht und wird nun aus Ihrer Liste entfernt.")
                                    this.dbService.getObject("customerList" + this.projectService.getUserId()).then(customerList => {
                                        Object.keys(customerList).forEach((key) => {
                                            if ((customerList[key].id && customer.id)) {
                                                customerList[key] = null;
                                                this.dbService.setObject("customerList" + this.projectService.getUserId(), customerList);
                                                loading.dismiss();
                                                this.router.navigate(['/customer-list']);
                                            }
                                        });
                                    })
                                } else {
                                    customer = data;
                                    this.dbService.setObject("currentCustomer" + this.projectService.getUserId(), customer);
                                    //TODO UPDATE CUSTOMER IN CUSTOMERLIST
                                    this.dbService.getObject("customerList" + this.projectService.getUserId()).then(customerList => {
                                        Object.keys(customerList).forEach((key) => {
                                            if ((customerList[key].email == customer.email) && (customerList[key].street == customer.street) &&
                                                (customerList[key].postal_code == customer.postal_code) && (customerList[key].name == customer.name) &&
                                                (customerList[key].surname == customer.surname)) {
                                                customerList[key] = customer;
                                                this.dbService.setObject("customerList" + this.projectService.getUserId(), customerList);
                                                loading.dismiss();
                                                this.router.navigate(['/conclusion']);
                                            }
                                        });
                                    })
                                }
                            });
                        }else{
                            this.projectService.sendCustomerContract(customer,product).then(value => {
                                customer = value;
                                this.dbService.setObject("currentCustomer" + this.projectService.getUserId(), customer);
                                //TODO UPDATE CUSTOMER IN CUSTOMERLIST
                                this.dbService.getObject("customerList" + this.projectService.getUserId()).then(customerList => {
                                    Object.keys(customerList).forEach((key) => {
                                        if ((customerList[key].email == customer.email) && (customerList[key].street == customer.street) &&
                                            (customerList[key].postal_code == customer.postal_code) && (customerList[key].name == customer.name) &&
                                            (customerList[key].surname == customer.surname)) {
                                            customerList[key] = customer;
                                            this.dbService.setObject("customerList" + this.projectService.getUserId(), customerList);
                                            loading.dismiss();
                                            this.router.navigate(['/conclusion']);
                                        }
                                    });
                                })
                            }
                            ) 
                        }
                    } else {
                        this.dbService.setObject("currentCustomer" + this.projectService.getUserId(), customer).then(d => {
                            this.dbService.getObject("customerList" + this.projectService.getUserId()).then(customerList => {
                                Object.keys(customerList).forEach((key) => {
                                    if ((customerList[key].email == customer.email) && (customerList[key].street == customer.street) &&
                                        (customerList[key].postal_code == customer.postal_code) && (customerList[key].name == customer.name) &&
                                        (customerList[key].surname == customer.surname)) {
                                        customerList[key] = customer;
                                        this.dbService.setObject("customerList" + this.projectService.getUserId(), customerList);
                                        this.router.navigate(['/conclusion']);
                                    }
                                });
                            })
                        });
                    }
                })
            })
        })
    }
}
