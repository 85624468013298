<ion-app>
    <ion-content class="has-header price-comparison">
        <div class="page-content">
        <img class="green_bubble_compare" src="../../assets/images/bubble.svg">
        <div class="p-1">
            <h2 (click)="backClicked()"><img class="glossar-icon" src="../../assets/icon/icon_glossar_arrow.svg">Preisvergleich {{type}}</h2>
            <p>Hamms gute Geister senken Ihre Energiekosten</p>
        </div>
        <!-- <img class="banner" src="../../assets/images/price-comparison-content.svg"> -->
        <div class="compare-content">
            <div class="compare-header">
                <h3>Beispiel</h3>
                <span>für einen Verbrauch von {{usage}} kWh/Jahr</span>
            </div>
            <ion-grid class="compare-table">
                <ion-row class="compare-table-header">
                    <ion-col class="tiny-header">
                    </ion-col>
                    <ion-col>
                        Bisherige Preise
                    </ion-col>
                    <ion-col class="green-header">
                        <ion-label>
                            {{title}}
                        </ion-label>
                    </ion-col>
                </ion-row>
                <ion-row class="compare-table-row">
                    <ion-col>
                        Arbeitspreis
                    </ion-col>
                    <ion-col>
                        {{custom_workPrice}} Ct/kWh
                    </ion-col>
                    <ion-col>
                        {{workPrice}} Ct/kWh
                    </ion-col>
                </ion-row>
                <ion-row class="compare-table-row row-border">
                    <ion-col>
                        Grundpreis
                    </ion-col>
                    <ion-col>
                        {{custom_basePrice}} €/Jahr
                    </ion-col>
                    <ion-col>
                        {{basePrice}} €/Jahr
                    </ion-col>
                </ion-row>
                <ion-row class="compare-table-footer">
                    <ion-col>
                        Gesamtpreis
                    </ion-col>
                    <ion-col>
                        {{custom_fullPrice}} €/Jahr
                    </ion-col>
                    <ion-col>
                        <ng-container *ngIf="hasBonus == true; else elseTemplate">
                         {{fullPrice}} €/Jahr (inkl. {{bonus}} € Neukundenbonus im ersten Lieferjahr)
                        </ng-container>
                        <ng-template #elseTemplate>
                            {{fullPrice}} €/Jahr

                        </ng-template>
                        
                    </ion-col>
                </ion-row>
            </ion-grid>
        </div>
        <div class="compare-footer">
            <div class="compare-green-left">
                <p class="compare-text">Bei dieser Beispielrechnung sparen<br>
                    Sie mit einem Wechsel zu den Stadtwerken Hamm</p>
                <p class="division-price">{{saving}}€</p>
            </div>
            <div class="compare-green-right">
                <img src="../../assets/images/glossar-icon.svg" (click)="navigateWithFragmet('warranty')" routerDirection="forward"> <span class="garantee">PREISGARANTIE<br>BIS {{guarantee}}</span> 
            </div>
        </div>
    </div>
    </ion-content>
</ion-app>