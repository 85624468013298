/*
 *  Both Gas-Models with given Data
 */


export class Gas {
    guarantee: string = "23.654.215";

    constructor(guarantee) {
        this.guarantee = guarantee;
        this.init();

    }
    subtitle: string = "hammerGAS ist unser klassisches Erdgasprodukt für alle, die preiswert und zuverlässig versorgt werden möchten. Ob beim Waschen, Kochen oder Heizen: hammerGAS bietet vielseitige Einsatzmöglichkeiten."

    benefits: {}[] = [
    ]

    background_informations: {}[] = []
    init() {
        this.benefits = [

            {
                title: "Preisgarantie",
                subtitle: "Bis zum " + this.guarantee
            },
            {
                title: "keine Kaution",
                subtitle: "weil uns gegenseitiges Vertrauen in einer Geschäftsbeziehung wichtig ist"
            },
            {
                title: "keine Vorauszahlung",
                subtitle: "sondern Sie zahlen erst, wenn Sie von uns beliefert werden"
            },
            {
                title: "monatliche Zahlungsweise",
                subtitle: "über einen übersichtlichen Abschlagsplan"

            },
            {
                title: "einfacher Wechsel",
                subtitle: "weil wir alle Formalitäten für Sie übernehmen"
            },
            {
                title: "volle Online-Kontrolle",
                subtitle: "über unser modernes Online Kundenzentrum"
            },
            {
                title: "guter Kundenservice",
                subtitle: "denn wir sind für Sie online und telefonisch erreichbar",

            }
        ]
    }
}
export class Gas_eco {
    guarantee: string = "23.654.215";

    constructor(guarantee) {
        this.guarantee = guarantee;
        this.init();

    }
    subtitle: string = "hammerGASklima ist unser Ökogasprodukt und wird zu 100 % CO<sub>2</sub>-neutral in Ihrer Heizungsanlage verbrannt. Mit hammerGASklima entscheiden Sie sich für ein besonders umwelt- und klimafreundliches Erdgas, das jährlich durch den TÜV Rheinland zertifiziert wird."

    benefits: {}[] = [
    ]

    background_informations: {}[] = [
        {
            title: "Warum hammerGASklima",
            content: "Treibhausgase sind kein lokales, sondern ein globales Problem. CO<sub>2</sub>, das hier in Deutschland emittiert wird, verursacht mit das Ozonloch über Australien. Damit ist es egal, wo Treibhausgase erzeugt und wo Treibhausgase eingespart werden. Auf diesem Prinzip beruht unser Klima-Produkt Erdgas. Treibhausgase, die hier vor Ort durch die Verbrennung von Erdgas in die Luft gelangen, werden in weltweiten Klimaschutzprojekten wieder gebunden und neutralisieren damit den CO<sub>2</sub>-Ausstoß.",
            expanded: false,

        },
        {
            title: "Wie wird eine echte Emissionsreduktion sicher gestellt?",
            content: "Anerkannte Standards / Methoden legen Kriterien für eine nachhaltige Emissionsreduktion fest, die von den Vereinten Nationen genehmigt werden müssen. Unabhängige Auditoren wie der TÜV überprüfen die Einhaltung des Kriterienkatalogs. Auch die jährliche Zertifizierung der eingesparten Mengen an CO<sub>2</sub> wird durch unabhängige Auditoren überwacht. Ein transparentes Verfahren der Stilllegung von Zertifikaten über ein Register vermeidet eine Doppelzählung. Die Stadtwerke Hamm lassen zusätzlich durch den TÜV Rheinland überwachen, dass der verkauften Menge Klima Erdgas eine ausreichende Anzahl von Zertifikatsstilllegungen gegenübersteht. Damit können Sie sicher sein, dass Ihr Erdgasverbrauch klimaneutral ist.",
            expanded: false,
        },
        {
            title: "In welche Projekte investieren die Stadtwerke Hamm?",
            content: "Die Stadtwerke Hamm haben sich für zwei Projekte entschieden: Wasserkraft in Indien und vermiedene Abholzung in Brasilien. Indien: Bis 2025 wird sich der Energiebedarf Indiens schätzungsweise verdoppeln. Gegenwärtig basiert die Elektrizitätserzeugung Indiens zu 80 % auf fossilen Brennstoffen. Durch die zusätzlichen Einnahmen aus Klimaschutzzertifikaten konnte die Finanzierung und damit die Realisierung des Projektes im Himalaya gesichert werden. Damit wurde der Bau eines weiteren Kohlekraftwerks verhindert. Brasilien: In den vergangenen Jahrzehnten ist der Regenwald durch Rodungen und illegale Abholzung stark entwaldet worden. Die Farm Florestal Santa Maria besitzt die Nutzungsrechte für eine Fläche von über 71.000 Hektar. Es ist einer der wenigen Standorte, die den einheimischen Wald zur nachhaltigen Bewirtschaftung nutzt. Der Schutz dieser riesigen Fläche erfordert einen hohen Aufwand, der ohne die Einnahmen aus dem Verkauf von Klimaschutzzertifikaten nicht finanzierbar wäre.",
            expanded: false,
        }
    ]
    init() {
        this.benefits = [
            {
                title: "CO<sub>2</sub>-neutral",
                subtitle: "durch den TÜV Rheinland zertifiziert"
            },
            {
                title: "Preisgarantie",
                subtitle: "Bis zum " + this.guarantee,
            },
            {
                title: "keine Kaution",
                subtitle: "weil uns gegenseitiges Vertrauen in einer Geschäftsbeziehung wichtig ist"
            },
            {
                title: "keine Vorauszahlung",
                subtitle: "sondern Sie zahlen erst, wenn Sie von uns beliefert werden"
            },
            {
                title: "monatliche Zahlungsweise",
                subtitle: "über einen übersichtlichen Abschlagsplan"

            },
            {
                title: "einfacher Wechsel",
                subtitle: "weil wir alle Formalitäten für Sie übernehmen"
            },
            {
                title: "volle Online-Kontrolle",
                subtitle: "über unser modernes Online-Kundenzentrum"
            },
            {
                title: "guter Kundenservice",
                subtitle: "denn wir sind für Sie online und telefonisch erreichbar",

            }
        ]
    }
}