import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { LoginService } from 'src/services/login/login.service';
import { Router } from '@angular/router';
import { DbService } from 'src/services/storage/db.service';
import { ProjectService } from 'src/services/project/project-service';
import { NetworkInterface } from '@awesome-cordova-plugins/network-interface/ngx';
import { HttpClient } from '@angular/common/http';
import { SyncService } from 'src/services/sync-service';
import { NetworkServiceService } from 'src/services/network-service.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {

  public userForm: FormGroup
  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private router: Router,
    private dbService: DbService,
    private projectService: ProjectService,
    private networkInterface: NetworkInterface,
    private http: HttpClient,
    private syncService: SyncService,
    private networkService: NetworkServiceService
  ) {
    this.userForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],

    });
  }
  ngOnInit(): void {
  }
  async ionViewDidEnter() {
    this.dbService.setObject("currentPage", this.router.url);
    if (await this.dbService.get("loggedIn") == true) {
      this.syncService.synchonizeData();
    }
  }


  /**
   * It logs in the user, sets the user in the database, sets the user in the project service, sets the
   * loggedIn variable in the  database to true, and then either synchronizes the data or navigates to the
   * landing page.
   */
  async loginForm() {
    console.log(this.userForm.value);
    this.loginService.login(this.userForm.value).subscribe(user => {
      if (user != false) {
        this.dbService.setUser(user).then(d => {
          this.projectService.setUser(user).then(d => {
            this.dbService.set("loggedIn", true);
            (this.networkService.getNetworkStatus() == "online") ?
              this.syncService.synchonizeData() : this.router.navigate(['/landing-page']);
          });
        });
      } else {
        alert("Fehler beim Login.");
        return;
      }
    });
  }
  /**
   * It gets the IP address of the device, and then it gets the IP address of the carrier. 
   * 
   * Then it uses the XMLHttpRequest object to send a GET request to the freegeoip.net API. 
   * 
   * The API returns a JSON object containing the IP address of the device, the country, the region, the
   * city, the zip code, the latitude, the longitude, the metro code, and the area code. 
   * 
   * The function then logs the response to the console.
   */
  getDeviceInformations() {
    this.networkInterface.getWiFiIPAddress()
      .then(address => alert(`IP: ${address.ip}, Subnet: ${address.subnet}`))
      .catch(error => alert(`Unable to get IP: ${error}`));

    this.networkInterface.getCarrierIPAddress()
      .then(address => alert(`IP: ${address.ip}, Subnet: ${address.subnet}`))
      .catch(error => alert(`Unable to get IP: ${error}`));
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 200) {
        console.log(xhttp.responseText)
      }
    };
    xhttp.open("GET", "//freegeoip.net/json/?callback=", true);
    xhttp.send();
  }
}
