import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { DbService } from 'src/services/storage/db.service';

@Component({
  selector: 'app-glossar',
  templateUrl: './glossar.page.html',
  styleUrls: ['./glossar.page.scss'],
})
export class GlossarPage implements OnInit {

  private fragment: string;

  constructor(private _location: Location,private dbService: DbService, private router: Router ,private route: ActivatedRoute) { }
  
  public tab : any = "";
  public detail : any = "";
  public showDetail : any;
  public guarantee : any;
  public detailView: any = {
    title: "",
    content: ""
  }
  public a_d_items : {}[] = [
    {
        title: "Arbeitspreis",
        content: "Kosten für den Verbrauch einer Kilowattstunde Strom für die individuell verbrauchte Menge Strom, angegeben in Cent pro Kilowattstunde. <br><br>Alternative Bezeichnung: Verbrauchspreis",
        expanded: false,

    },
    {
        title : "Bilanzierungsumlage (Gas)",
        content: 'Früher "Regel- und Ausgleichsenergieumlage". <br><br>Regelenergie wird benötigt, um je Stunde tatsächliche physische Differenzen zwischen Ein- und Ausspeisung ausgleichen zu können. D.h. es wird Energie gekauft oder verkauft. Ergibt sich am Ende des Gastages eine Differenz aus dem Saldo der Ein- und Ausspeisungen so wird diese mit der Ausgleichsenergie berechnet. Die Regel- und Ausgleichsenergieumlage setzt sich aus den Erlösen und Kosten der Gas Zu- bzw. Verkäufe zusammen.',
        expanded: false,
    },
    {
        title: "Brennwert",
        content: "Der Brennwert eines Brennstoffs, Kraftstoffs oder Treibstoffs gibt an, wie viel Energie in Form von Wärme bei der Verbrennung pro Kilogramm des Stoffs gewonnen werden kann.",
        expanded: false,
    }
  ]
  public e_h_items : {}[] = [
    {
        title: "EEG-Umlage",
        content: "Mit der EEG-Umlage, festgelegt im Erneuerbare-Energien-Gesetz (EEG), wird der Ausbau der Erneuerbaren Energien finanziert (z.B. Stromerzeugung über EE-Anlagen wie Windkraft & Photovoltaik).  Anlagenbetreiber erhalten für die in das öffentliche Versorgungsnetz eingespeiste Strommenge eine festgelegte Vergütung.<br><br>Die EEG-Umlage ist Teil des Strompreises. Entsprechend müssen alle Stromverbraucher die EEG-Umlage bezahlen. ",
        expanded: false,
    },
    {
        title : "Emissionshandel",
        content: "Der Emissionshandel regelt, d.h. hat zum Ziel die Emissionen von Kohlenstoffdioxid (CO<sub>2</sub>) und anderen Treibhausgasen zu senken, so dass das Klima geschützt wird. Dabei legt die Politik fest, wie viele Tonnen CO<sub>2</sub> von einer Gruppe insgesamt ausgestoßen werden dürfen.",
        expanded: false,
    },
    {
        title: "Energiesteuer",
        content: "Verbauchssteuer auf Erdgas, das als Energieerzeugnis  Teil der Energiesteuer ist, die auf alle Energiearten erhoben wird. <br><br>Alternative Bezeichnung: Erdgassteuer",
        expanded: false,
    },
    {
        title: "Energiemix",
        content: "Der Energiemix bezeichnet die Kombination verschiedener Primärenergieformen (z.B. Stein- u. Braunkohle, Erdöl, Erdgas, Wasser, Wind, Solarstrahlung) zur gesamten Energieversorgung.",
        expanded: false,
    },
    {
        title: "Energieversorgungsvertrag (wettbewerblich)",
        content: 'Alle Haushaltskunden, die nicht in der Grund- oder Ersatzversorgung sind haben einen aktiv und frei abgeschlossenen Energieliefervertrag mit einem Energielieferant Ihrer Wahl, einen sogenannten "Liefervertrag außerhalb der Grundversorgung". <br><br><i>Alternative Bezeichnung: Sondervertrag<br><br>Besondere Form: z.B. Mieterstromvertrag</i>',
        expanded: false,
    },
    {
        title: "Erneuerbare Energien",
        content: "Erneuerbare Energien sind Energieträger, die unendlich zur Verfügung stehen bzw. in kürzester Zeit wieder nachwachsen können - anders als fossile Energieträger - gewonnen durch Wasserkraft, Solar- und Windenergie, Biomasse, Geothermie. <br><br><i>Alternative Bezeichnung: Regenerative Energien</i>",
        expanded: false,
    },
    {
        title: "Ersatzversorgung",
        content: "Von Ersatzversorgung wird gesprochen, wenn der Grundversorger für die Energielieferung einspringt, weil der aktuelle Energiebezug/-verbrauch eines Haushalts nicht einem bestimmten Lieferanten oder konkreten Liefervertrag zugeordnet werden kann. Hierfür ist kein Vertragsabschluss nötig. <br><br><i>Alternative Bezeichnung: Notversorgung</i>",
        expanded: false,
    },
    {
        title: "Grundpreis",
        content: "Der Grundpreis entspricht einer Grundgebühr und ist damit der Teil der Stromrechnung, der grundsätzlich und unabhängig vom individuellen Stromverbrauch anfällt. <br><br>Alternative Bezeichnung: Zählergebühr",
        expanded: false,
    },
    {
        title: "Grundversorgung",
        content: 'Die Grundversorgung ist die Energielieferung an Haushaltskunden durch den Grundversorger. Grundversorger ist das Energieversorgungsunternehmen, das in dem Netzgebiet des Kunden vor Ort die meisten Haushaltskunden mit Strom bzw. Gas beliefert. Jeder Haushaltskunde hat Anspruch auf Grundversorgung und kann entweder aktiv  oder durch "konkludentes Verhalten" (z.B. Lichteinschalten in neuer Wohnung) einen Vertrag abschließen.',
        expanded: false,
    },
    {
        title: "Gas",
        content: "H-Gas (high calorific gas) und L-Gas (low calorific gas) sind Gasarten, die sich in Beschaffenheit und Qualität unterscheiden und entsprechend unterschiedliche Brennwerte und Energiegehalt aufweisen. H-Gas setzt beim Verbrennen mehr Energie frei als L-Gas, hat entsprechend einen höheren Brennwert. Dafür ist H-Gas bei gleichbleibender Energie ressourcenschonender und bedeutet entsprechend einen niedrigeren Verbrauch. <br><br>Der Großteil Deutschlands wird bereits mit H-Gas versorgt. Ziel der Bundesnetzagentur ist es bis 2030 schrittweise alle deutschen Haushalte und Unternehmen auf H-Gas umzustellen.",
        expanded: false,
    },
  ]
  public i_l_items : {}[] = [
    {
        title: "Konzessionsabgabe",
        content: "Konzessionsabgaben werden von Energieversorgungsunternehmen und Wasserversorgungsunternehmen an Gemeinden gezahlt, damit sie im Gegenzug öffentliche Straßen und Wege für Verlegung von Strom- und Gasleitung zu nutzen. <br><br>Die Konzessionsabgabenverordnung (KAV) regelt Höhe der Konzessionsabgaben.",
        expanded: false,
    },
    {
        title: "kWh",
        content: "kWh steht für Kilowattstunde und gibt an wieviel Energie/Strom eine Maschine oder ein Gerät (z.B. Fernseher), mit einer bestimmten Leistung (ausgedrückt in Kilowatt; 1kW = 1000 Watt) innerhalb einer Stunde benötigt/verbraucht, um zu laufen. Mit 1 kWh Strom kann man beispielsweise eine 10 Watt LED Birne 100 Stunden leuchten lassen. <br><br>Die Formel zur Berechnung von Energieverbrauch (Verbauch in kWh) ist: Leistung (in Watt) * Verbrauchszeitraum (in Stunden) / 1000 <br><br>Der Energieverbrauch eines Haushaltskunden wird auf der Abrechnung in kWh angegeben. <br><br>Alternative Bezeichnung: Elektrische Arbeit",
        expanded: false,
    },
    {
        title: "KWKG",
        content: "KWKG steht für Kraft-Wärme-Kopplungsgesetz und regelt die KWKG Umlage von KWK-Anlagen, die in einem Verbrennungsprozess gleichzeitig Strom und Wärme erzeugen. Die KWKG-Umlage zur Förderung von Stromerzeugung aus KWK Anlagen ist Teil des Strompreises und wird auf die Netzentgelte aufgeschlagen.",
        expanded: false,
    }
  ]
  public m_p_items : {}[] = [{}]
  public q_z_items : {}[] = [
    {
        title: "Stromsteuer",
        content: "Der gelieferte Strom wird mit der Stromsteuer nach § 3 des Stromsteuergesetzes (StromStG) besteuert. Hiermit werden Anreize zu energieeffizientem Verhalten gesetzt.",
        expanded: false,
    },
    {
        title: "Übertragungsnetzbetreiber (ÜNB)",
        content: "Übertragungsnetzbetreiber übernehmen die Aufgabe der Übertragung von Strom und verantworten Betrieb, Wartung sowie den Ausbau der Übertragungsnetze und Einrichtung von Verbindungsleitungen zu anderen Netzen. Vier Übertragungsnetzbetreiber (ÜNB) - 50Hertz, Amprion, TenneT und TransnetBW - teilen sich in Deutschland die Verantwortung für das Übertragungsnetz zur überregionalen Versorgung und Übertragung im Höchstspannungsbereich.",
        expanded: false,
    },
    {
        title: "Umlage für abschaltbare Lasten",
        content: 'Mit der Umlage nach § 18 der Verordnung zu abschaltbaren Lasten (AbLaV) werden Vergütungszahlungen der Übertragungsnetzbetreiber an Anbieter von sogenannter "Abschaltleistung" ausgeglichen. <br><br>Anbieter von Abschaltleistung sind z.B. Industriebetriebe, die für einen vereinbarten Zeitraum oder auch kurzfristig auf die Lieferung von Strom verzichten können, wenn im Stromnetz gerade nicht genügend Strom vorhanden ist. Die Übertragungsnetzbetreiber (ÜNB) gleichen Ihre Zahlungen untereinander aus und legen den Betrag auf alle Letztverbraucher um. Ziel ist eine bessere Netzstabilität und damit eine höhere Versorgungssicherheit.',
        expanded: false,
    },
    {
        title: "Umlage nach §19 Abs. 2 StromNEV",
        content: "Nach § 19 Stromnetzentgeltverordnung (StromNEV) haben bestimmte Letztverbraucher die Möglichkeit, vom örtlichen Netzbetreiber niedrigere individuelle Netzentgelte zu erhalten. <br><br>Die Übertragungsnetzbetreiber (ÜNB) müssen den örtlichen Netzbetreibern die durch diese niedrigeren Entgelte entgangenen Erlöse erstatten. Die ÜNB gleichen die Zahlungen für diese entgangenen Erlöse untereinander aus und errechnen einen Aufschlag auf die Netzentgelte, der als Umlage auf alle Letztverbraucher umgelegt wird.",
        expanded: false,
    },
    {
        title: "Umsatzsteuer",
        content: "Die Umsatzsteuer (USt) ist eine Steuer, die auf den Verkauf bzw. den Austausch von Produkten und Dienstleistungen von Unternehmen erhoben wird. Die Lieferung von Energie wird ebenfalls mit dem aktuell gültigen Umsatzstuersatz besteuert.",
        expanded: false,
    },
    {
        title: "Verteilnetzbetreiber (VNB)",
        content: "Verteilnetzbetreiber haben ähnliche Aufgaben wie Übertragungsnetzbetreiber, sind allerdings für die regionalen und lokalen Verteilnetze zuständig und bringen somit den Strom direkt zum Verbaucher. <br><br>EE-Anlagen sind für gewöhnlich an das Verteilnetz angeschlossen, ausgenommen große Wasserkraftwerke und Offshore Windkraftpools. Entsprechend liegen Koordination und Versorgung durch erneuerbare Energie vorzugsweise regional bei den Verteilnetzbetreibern.",
        expanded: false,
    },
  ]

  ngOnInit() {
    this.route.fragment.subscribe(fragment => { this.fragment = fragment;
     });
     this.dbService.getObject("guarantee").then(value => {
      this.guarantee = value;
      this.init();
      if(this.fragment == 'warranty'){
        this.singleDetail(this.m_p_items[11]);
        this.tab = 'm_p';
    }
    });
  }
  ionViewWillEnter() {

  }
  ionViewDidEnter() {
    // this.dbService.setObject("currentPage",this.route.url);
  }
  backClicked() {
    this._location.back();
  }
  tabSelect(TabName) {
    this.tab = TabName;
    this.showDetail = false
  }
  detailSelect(TabName) {
    this.detail = TabName;
    console.log(this.detail);
  }

  ngAfterViewInit(): void {
    try {
      this.tab = this.fragment;
    } catch (e) { }
  }

  expandItem(item): void {
    item.expanded = !item.expanded;
  }

  singleDetail(item): void {
    this.detailView.title = item.title
    this.detailView.content = item.content
    this.showDetail = true
  }

  hideDetail() {
    this.showDetail = false
  }
  init() {
    this.m_p_items = [
      {
          title: "Messstellenbetrieb",
          content: "Der Messstellenbetrieb ist Einbau, Betrieb und Wartung von Messeinrichtungen.",
          expanded: false,
      },
      {
          title : "Mieterstrom",
          content: "Als Mieterstrom wird der Strom bezeichnet, der aus erneuerbaren Energien erzeugt (z.B. Blockheizkraftwerk (BHKW) oder Photovoltaik-Anlage (PV-Anlage) auf dem Dach eines Wohngebäudes) und an Endverbraucher in diesem Gebäude (i.d.R. Mieter) geliefert wird. Hierzu wird ein Vertrag geschlossen zwischen Nutzer (i.d.R. Mieter) und dem Anlagenbetreiber/Energielieferant (i.d.R. Vermieter) oder Mieterstromdienstleister geschlossen.",
          expanded: false,
      },
      {
          title: "Müllverbrennungsanlage (MVA)",
          content: "Eine Müllverbrennungsanlage ist eine tragende Säule der Abfallentsorgung in Deutschland. Sie wird auch als Müllkraftwerk bezeichnet, da in diesem Prozess auch Strom und Wärme erzeugt werden kann.",
          expanded: false,
      },
      {
          title: "Netzentgelte",
          content: "Das Netzentgelt ist eine Gebühr, die für die Nutzung des Versorgungsnetzes zur Strom- & Gasübertragung an den Netzbetreiber gezahlt werden muss. Der Energieversorger ist direkter Netznutzer, gibt das Netzentgelt an die Haushaltskunden weiter indem er dies in Rechnung stellt und anschließend an den Netzbetreiber weiterleitet.",
          expanded: false,
      },
      {
          title: "Offshore-Netzumlage",
          content: "Die Offshore-Netzumlage ist wie andere Umlagen Teil des Strompreises. Sie entschädigt Betreiber von Offshore Windparks für Errichtung und Betrieb von Anbindungsleitungen (nicht in Netzengelt enthalten) und verspäteten Anschluss an das Übertragungsnetz oder lang andauernder Netzunterbrechungen.",
          expanded: false,
      },
      {
          title: "Ökogas",
          content: "Ökogas wird als CO<sub>2</sub>-neutral und damit besonders klimaschonend bezeichnet. Es handelt sich dabei um Gas, dessen CO<sub>2</sub>-Neutralität durch den Erwerb von Emissionszertifikaten oder die Teilnahme an Klimaschutzprojekten - die den CO<sub>2</sub>-Ausstoß ausgleichen und somit klimafreundlich agieren - zustande kommt. Der Verbraucher leistet mit der Wahl eines Ökogas Produktes entsprechend einen Beitrag. <br><br>Alternative Bezeichnung: Klimagas",
          expanded: false,
      },
      {
          title: "Ökostrom",
          content: "Ökostrom ist Strom, der regenerativ und damit aus erneuerbaren Energiequellen, d.h. Wind-, Wasser-, Solar und mittels Erneuerbare-Energien-Anlagen (EE-Anlagen) erzeugt wurde. Zertifikate, Gütesiegel und Herkunftsnachweise geben dem Verbaucher Orientierung bei der Wahl eines entsprechenden Anbieters. Auf die Begriffsverwendung alleine kann man sich dabei nicht verlassen, da dieser nicht geschützt ist.",
          expanded: false,
      },
      {
          title: "Ökostromzertifikat",
          content: "Ein Ökostromzertifikat gibt zuverlässig Aufschluss darüber, ob Strom aus erneuerbaren Energiequellen entspringt und wie dieser konkret zusammengesetzt ist (z.B. Anteile von Wind, Wasser, Solarenergie). Beispiele sind der Herkunftsnachweis vom Umweltbundesamt und das Renewable Energy Certificate System (RECS) -Zertifikat. Hierfür werden jeweils unterschiedliche Kriterien herangezogen.",
          expanded: false,
      },
      {
          title: "Gütesiegel (Ökostrom)",
          content: "Ein Gütesiegel gibt Auskunft über die Qualität des Ökostromtarifs. Beispiele sind das Gütesiegel von OK Power, Gütesiegel Grüner Strom und das des TÜV. Hierfür werden jeweils unterschiedliche Kriterien herangezogen. Beim TÜV bekommt man beispielsweise ein Gütesiegel, wenn 100% aus erneuerbaren Energien stammt und die Produkte einen Beitrag zur Förderung erneuerbarer Energien leisten, d.h. Preisaufschläge dem Ausbau regenerativer Energie dienen.",
          expanded: false,
      },
      {
          title: "Preisbestandteile Gas",
          content: "Der Gaspreis setzt sich aus drei Kostenblöcken zusammen:<ul><li>Beschaffung und Vertrieb</li><li>Transport und Vertreilung (z.B. Netzentgelte)</li><li>Steuern und andere Abgaben (z.B. Energiesteuer)</li></ul>Der Versorger kann dabei nur auf den Teil Beschaffung und Vertrieb Einfluss nehmen.",
          expanded: false,
      },
      {
          title: "Preisbestandteile Strom",
          content: "Der Strompreis setzt sich aus drei Kostenblöcken zusammen:<ul><li>Beschaffung und Vertrieb</li><li>Transport und Vertreilung (z.B. Netzentgelte)</li><li>Steuern und andere Abgaben (z.B. EEG-Umlage)</li></ul>Der Versorger kann dabei nur auf den Teil Beschaffung und Vertrieb Einfluss nehmen.",
          expanded: false,
      },
      {
          title: "Preisgarantie",
          content: "Die Stadtwerke Hamm GmbH garantieren die genannten Preise bis zum "+this.guarantee+". Danach ist die Stadtwerke Hamm GmbH berechtigt, die Nettopreise nach Ziffer 6 der allgemeinen Geschäftsbedingungen für die Belieferung von Erdgas- bzw. Stromkunden anzupassen.",
          expanded: false,
      },
    ]
  }
}
