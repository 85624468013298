import { Component, OnInit } from '@angular/core';
import { DbService } from 'src/services/storage/db.service';
import { Router } from '@angular/router';
import { ProjectService } from 'src/services/project/project-service';

@Component({
  selector: 'app-product-selection',
  templateUrl: './product-selection.page.html',
  styleUrls: ['./product-selection.page.scss'],
})
export class ProductSelectionPage implements OnInit {

  constructor(private dbService: DbService, private router: Router,private projectService :ProjectService) {
  }
  public cross_selling_array = [
      {id: "power",value: false},
      {id: "gas",value: false},
      {id: "none",value: false},
]

   public cross_selling_power: boolean = false;
   public cross_selling_gas: boolean = false;
   public cross_selling_none: boolean = false;
   public cross_selling : boolean = false;
  ngOnInit() {
  }
  ionViewWillEnter() {
    let input = this.router.url;
    this.cross_selling = input.indexOf("cross-selling") != -1 ? true : false;
  }

  ionViewDidEnter() {
    this.dbService.setObject("currentPage"+this.projectService.getUserId(),this.router.url);
  }
/**
 * It takes the values of the checkboxes and puts them into an array.
 */
  submitCheckboxes(){
      let array = [{}];
      if(this.cross_selling_gas == true || this.cross_selling_power){
        this.cross_selling_none = false;
      }
      this.cross_selling_array[0] = {id: "3",value: this.cross_selling_power}
      this.cross_selling_array[1] = {id: "1",value: this.cross_selling_gas}
      this.cross_selling_array[2] = {id: "none",value: this.cross_selling_none}
      console.log(this.cross_selling_array);
      this.dbService.getObject("currentCustomer"+this.projectService.getUserId()).then(customer => {
          customer.cross_selling = this.cross_selling_array;
          this.dbService.setObject("currentCustomer"+this.projectService.getUserId(), customer);
      })
    }
    unset(){
        if(this.cross_selling_none){
            this.cross_selling_power = false;
            this.cross_selling_gas = false;
        }
        this.submitCheckboxes();
    }
}
