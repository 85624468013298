<ion-app>
    <ion-content class="has-header">
        <div class="page-content">
            <img class="green_bubble" src="../../assets/images/bubble.svg">
            <div class="p-1">
                <h2>{{productName}}</h2>
            </div>
            <div>
                <div class="tab">
                    <span [ngClass]="currentTab == 1 ? 'tab-button active' : 'tab-button'">Kontaktdaten</span>
                    <span [ngClass]="currentTab == 2 ? 'tab-button active' : 'tab-button'">Lieferstelle</span>
                    <span [ngClass]="currentTab == 3 ? 'tab-button active' : 'tab-button'">Angaben zum Wechsel</span>
                    <span [ngClass]="currentTab == 4 ? 'tab-button active' : 'tab-button'">Zahlungsweise</span>
                    <span [ngClass]="currentTab == 5 ? 'tab-button active' : 'tab-button'">Rechnungsadresse</span>
                    <span [ngClass]="currentTab == 6 ? 'tab-button active' : 'tab-button'">Abschluss</span>
                </div>
                <!-- Tab content -->
                <div *ngIf="currentTab==1">
                    <ion-grid class="maingrid">
                        <ion-row>
                            <ion-col size="4">
                                <ion-card mode="md">
                                    <ion-card-header>
                                        <ion-card-title class="ion-text-center">{{productName}}</ion-card-title>
                                        <ng-container *ngIf="productType == 'Ja';">
                                            <ion-card-subtitle class="ion-text-center">Zu 100% aus regenerativen
                                                Energiequellen
                                            </ion-card-subtitle>
                                        </ng-container>
                                    </ion-card-header>

                                    <ion-card-content class="ion-text-center">
                                        <span class="ecoPrice ion-text-center">
                                            
                                            <span class="underline">{{productPrice}}</span><span
                                                class="year"> €/Jahr*</span><br>
                                            <span class="smaller">*Bei einem Jahresverbrauch von {{kWh}} kWh</span><br>
                                            <span class="smaller small-br" *ngIf="hasBonus == true">Preis beinhaltet {{currentBonus}} € Neukundenbonus im ersten Jahr</span>
                                        </span>
                                    </ion-card-content>


                                    <ion-row class="cardfooter ion-text-center">
                                        <ion-col>
                                            <ng-container *ngIf="productType == 'Ja'; else noEco">
                                                <span class="footerText">Öko Tarif</span>
                                            </ng-container>
                                            <ng-template #noEco>
                                                <span class="footerText">Standard Tarif</span>
                                            </ng-template>                                        
                                        </ion-col>
                                    </ion-row>
                                </ion-card>
                            </ion-col>
                            <ion-col size="8">
                                <ion-grid class="form-grid">
                                    <ion-row>
                                        <ion-col>
                                            <ion-select mode="md" class="customer-form select-full-width" cancelText="Abbrechen"
                                                okText="Bestätigen" [(ngModel)]="formOfAddress" id="formOfAddress"
                                                name="formOfAddress" placeholder="Anrede">
                                                <ion-select-option value="Herr">Herr</ion-select-option>
                                                <ion-select-option value="Frau">Frau</ion-select-option>
                                                <ion-select-option value="Firma">Firma</ion-select-option>
                                                <ion-select-option value="Eheleute">Eheleute</ion-select-option>
                                                <ion-select-option value="Eigentümergemeinschaft">Eigentümergemeinschaft</ion-select-option>
                                                <ion-select-option value="Wohngemeinschaft">Wohngemeinschaft</ion-select-option>
                                                <ion-select-option value="Hausverwaltung">Hausverwaltung</ion-select-option>
                                                <ion-select-option value="Bürogemeinschaft">Bürogemeinschaft</ion-select-option>
                                                <ion-select-option value="Erbengemeinschaft">Erbengemeinschaft</ion-select-option>
                                                <ion-select-option value="Immobilienverwaltung">Immobilienverwaltung</ion-select-option>
                                                <ion-select-option value="Wohnungsgesellschaft">Wohnungsgesellschaft</ion-select-option>
                                                <ion-select-option value="Bauunternehmung">Bauunternehmung</ion-select-option>
                                                <ion-select-option value="Bauherrengemeinschaft">Bauherrengemeinschaft</ion-select-option>
                                                <ion-select-option value="Praxisgemeinschaft">Praxisgemeinschaft</ion-select-option>
                                                <ion-select-option value="Grundstücksgemeinschaft">Grundstücksgemeinschaft</ion-select-option>
                                                <ion-select-option value="Hausgemeinschaft">Hausgemeinschaft</ion-select-option>
                                                <ion-select-option value="Kostengemeinschaft">Kostengemeinschaft</ion-select-option>
                                                <ion-select-option value="Sozietät">Sozietät</ion-select-option>
                                                <ion-select-option value="">-</ion-select-option>
                                            </ion-select>
                                        </ion-col>
                                        <ng-container *ngIf="formOfAddress != 'Herr' && formOfAddress != 'Frau' && formOfAddress != 'Eheleute'; else noOrgaTemplate">

                                            <ion-col><input mode="md" autocomplete="off" maxlength="55" class="customer-form" [(ngModel)]="title" type="text" id="title"
                                                name="title" value="" placeholder="Firma/Organisation"></ion-col>
                                        </ng-container>
                                        <ng-template #noOrgaTemplate>

                                            <ion-col><input mode="md" autocomplete="off" maxlength="30" class="customer-form" [(ngModel)]="title" type="text" id="title"
                                                name="title" value="" placeholder="Titel"></ion-col>

                                        </ng-template>     


                                    </ion-row>

                                    <ion-row>
                                        <ion-col> <input mode="md" autocomplete="off" maxlength="45" class="customer-form" [(ngModel)]="firstname" type="text"
                                                id="firstname" name="firstname" value="" placeholder="Vorname*"></ion-col>
                                        <ion-col> <input mode="md" autocomplete="off" maxlength="45" class="customer-form" [(ngModel)]="lastname" type="text"
                                                id="lastname" name="lastname" value="" placeholder="Nachname*"></ion-col>
                                    </ion-row>

                                    <ion-row *ngIf="formOfAddress == 'Herr' || formOfAddress == 'Frau' || formOfAddress == 'Eheleute'">
                                        <ion-col>
                                            <!-- <ion-datetime mode="md" [(ngModel)]="date_of_birth"
                                                placeholder="Geburtsdatum*" displayFormat="DD/MM/YYYY"
                                                doneText="Bestätigen" cancelText="Abbrechen"></ion-datetime> -->
                                                <input mode="md" autocomplete="off" readonly maxlength="30" class="customer-form" (click)="openDatePickerBirth()" [(ngModel)]="date_of_birth" type="text"
                                                 id="date_of_birth" name="date_of_birth" value=""
                                                placeholder="Geburtsdatum*">
                                        </ion-col>
                                        <ion-col></ion-col>
                                    </ion-row>

                                    <ion-row>
                                        <ion-col> <input mode="md" autocomplete="off" maxlength="100" class="customer-form" [(ngModel)]="email" type="text"
                                                id="email" name="email" value="" placeholder="E-Mail*"></ion-col>
                                        <ion-col> <input mode="md" autocomplete="off" maxlength="30" class="customer-form" [(ngModel)]="telephone" type="text"
                                                id="telephone" name="telephone" value="" placeholder="Telefon*">
                                        </ion-col>
                                    </ion-row>
                                    

                                </ion-grid>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                    <ion-fab class="comparison-popup" vertical="bottom" horizontal="start" slot="fixed">
                        <img (click)="openCamera()" src="../../assets/images/add-picture-icon.png">
                    </ion-fab>   
                    <div class="custom-webcam-wrapper" *ngIf="showWebcam">
                        <div (click)="toggleWebcam()" class="cross"><img  src="../../assets/icon/icon-material-close.svg"></div>
                        <webcam [height]="800" [width]="800" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
                            [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
                            [videoOptions]="videoOptions"
                            [imageQuality]="1"
                            (cameraSwitched)="cameraWasSwitched($event)"
                            (initError)="handleInitError($event)"
                        ></webcam>     
                        <button class="actionBtn capture-button" (click)="triggerSnapshot();"></button>
                    </div>  
                    <ion-row class="ion-text-center footer-row">
                        <ion-button mode="md" (click)="openTab(2)">WEITER</ion-button>

                    </ion-row>
                </div>

                <div *ngIf="currentTab==2">
                    <ion-grid class="maingrid">
                        <ion-row>
                            <ion-col size="4">
                                <ion-card mode="md">
                                    <ion-card-header>
                                        <ion-card-title class="ion-text-center">{{productName}}</ion-card-title>
                                        <ng-container *ngIf="productType == 'Ja';">
                                            <ion-card-subtitle class="ion-text-center">Zu 100% aus regenerativen
                                                Energiequellen
                                            </ion-card-subtitle>
                                        </ng-container>
                                    </ion-card-header>

                                    <ion-card-content class="ion-text-center">
                                        <span class="ecoPrice ion-text-center">
                                            
                                            <span class="underline">{{productPrice}}</span><span
                                                class="year"> €/Jahr*</span><br>
                                            <span class="smaller">*Bei einem Jahresverbrauch von {{kWh}} kWh</span><br>
                                            <span class="smaller small-br" *ngIf="hasBonus == true">Preis beinhaltet {{currentBonus}} € Neukundenbonus im ersten Jahr</span>
                                        </span>
                                    </ion-card-content>


                                    <ion-row class="cardfooter ion-text-center">
                                        <ion-col>
                                        <ng-container *ngIf="productType == 'Ja'; else noEco">
                                            <span class="footerText">Öko Tarif</span>
                                        </ng-container>
                                        <ng-template #noEco>
                                            <span class="footerText">Standard Tarif</span>
                                        </ng-template>
                                    
                                        </ion-col>
                                    </ion-row>
                                </ion-card>
                            </ion-col>
                            <ion-col size="8">
                                <ion-grid class="form-grid">
                                    <ion-row>
                                        <ion-col> <input mode="md" autocomplete="off" maxlength="30" class="customer-form" [(ngModel)]="postal_code" [disabled]="true" type="text"
                                                id="postal_code" name="postal_code" value="" placeholder="PLZ*" (ngModelChange)="test()">
                                                
                                        </ion-col>
                                        <ion-col></ion-col>
                                    </ion-row>

                                    <ion-row>
                                        <ion-col> <input mode="md" autocomplete="off" maxlength="30" class="customer-form" [(ngModel)]="street" type="text"
                                                id="street" name="street" value="" placeholder="Straße*"></ion-col>
                                        <ion-col> <input mode="md" autocomplete="off" maxlength="30" class="customer-form" [(ngModel)]="house_number" type="text"
                                                id="house_number" name="house_number" value=""
                                                placeholder="Hausnummer*"></ion-col>
                                    </ion-row>

                                    <ion-row>
                                        <ion-col>
                                            <ng-container *ngIf="city.length == null ||  city.length < 2 ; else elseTemplate">
                                                <input mode="md" autocomplete="off" maxlength="30" class="customer-form" [(ngModel)]="cityValue" type="text" id="city" name="city" value=""
                                                placeholder="Ort" [disabled]="true">
                                            </ng-container>
                                            <ng-template #elseTemplate>
                                                <ion-select mode="md" class="customer-form" cancelText="Abbrechen" okText="Bestätigen" [(ngModel)]="cityValue" id="formOfAddress" name="formOfAddress"  placeholder="Wählen Sie eine Stadt aus">
                                                    <div *ngFor="let city of city">
                                                        <ion-select-option value="{{city}}">{{city}}</ion-select-option>
                                                    </div>
                                                  </ion-select>
                                            </ng-template>
                                        </ion-col>
                                        <ion-col></ion-col>
                                    </ion-row>

                                    <ion-row>
                                        <ion-col>
                                        </ion-col>
                                        <ion-col>
                                        </ion-col>
                                    </ion-row>
                                </ion-grid>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                    
                    <ion-row class="ion-text-center footer-row">
                        <ion-button mode="md" (click)="openTab(1)">ZURÜCK</ion-button>
                        <ion-button mode="md" (click)="openTab(3)">WEITER</ion-button>
                    </ion-row>
                </div>

                <div *ngIf="currentTab==3">
                    <ion-grid class="maingrid">
                        <ion-row>
                            <ion-col size="4">
                                <ion-card mode="md">
                                    <ion-card-header>
                                        <ion-card-title class="ion-text-center">{{productName}}</ion-card-title>
                                        <ng-container *ngIf="productType == 'Ja';">
                                            <ion-card-subtitle class="ion-text-center">Zu 100% aus regenerativen
                                                Energiequellen
                                            </ion-card-subtitle>
                                        </ng-container>
                                    </ion-card-header>

                                    <ion-card-content class="ion-text-center">
                                        <span class="ecoPrice ion-text-center">
                                            
                                            <span class="underline">{{productPrice}}</span><span
                                                class="year"> €/Jahr*</span><br>
                                            <span class="smaller">*Bei einem Jahresverbrauch von {{kWh}} kWh</span><br>
                                            <span class="smaller small-br" *ngIf="hasBonus == true">Preis beinhaltet {{currentBonus}} € Neukundenbonus im ersten Jahr</span>
                                        </span>
                                    </ion-card-content>


                                    <ion-row class="cardfooter ion-text-center">
                                        <ion-col>
                                            <ng-container *ngIf="productType == 'Ja'; else noEco">
                                                <span class="footerText">Öko Tarif</span>
                                            </ng-container>
                                            <ng-template #noEco>
                                                <span class="footerText">Standard Tarif</span>
                                            </ng-template>                                        </ion-col>
                                    </ion-row>
                                </ion-card>
                            </ion-col>
                            <ion-col size="8">
                                <ion-grid class="form-grid">
                                    <ion-row>
                                        <ion-col>
                                            <ion-select mode="md" class="customer-form" cancelText="Abbrechen"
                                                okText="Bestätigen" [(ngModel)]="reason" id="reason" name="reason"
                                                placeholder="Grund" (ionChange)="clearDateOfChange()">
                                                <ion-select-option value="change">Versorgerwechsel</ion-select-option>
                                                <ion-select-option value="moving">Neueinzug</ion-select-option>
                                            </ion-select>
                                        </ion-col>
                                        <ion-col></ion-col>
                                    </ion-row>
                                    <div *ngIf="reason == 'change'">
                                        <ion-row>
                                            <ion-col>
                                                <input mode="md" autocomplete="off" readonly maxlength="30" class="customer-form" (click)="openDatePickerChange()" [(ngModel)]="date_of_change" type="text"
                                                id="date_of_change" name="date_of_change" value=""
                                                placeholder="Gew. Wechseldatum*">
                                            </ion-col>
                                            <ion-col></ion-col>
                                        </ion-row>

                                        <ion-row>
                                            <ion-col>
                                                <input mode="md" autocomplete="off" maxlength="30" class="customer-form" [(ngModel)]="meter_number" type="text"
                                                    id="meter_number" name="meter_number" value=""
                                                    placeholder="Zählernummer">
                                            </ion-col>
                                            <!-- <ion-col>                                            
                                                <input mode="md" autocomplete="off" maxlength="30" class="customer-form" [(ngModel)]="current_provider" type="text"
                                                id="current_provider" name="current_provider" value=""
                                                placeholder="Vorlieferant*">
                                            </ion-col> -->
                                            <ion-col>
                                                <ngx-select-dropdown [config]="config" [options]="dropdownOptions" [(ngModel)]="current_provider"></ngx-select-dropdown>
                                            </ion-col>
                                        </ion-row>

                                        <ion-row>
                                            <ion-col>
                                                <!-- <input mode="md" autocomplete="off" maxlength="30" [(ngModel)]="canceled" type="checkbox" class="checkbox" id="canceled"
                                                name="canceled">
                                            <span class="ion-margin-start">Bisherigen Versorger gekündigt</span> -->
                                                <input mode="md" autocomplete="off" maxlength="30" class="customer-form" [(ngModel)]="old_customer_number"
                                                    type="text" id="old_customer_number" name="old_customer_number"
                                                    value="" placeholder="Kundennummer beim Vorlieferant">
                                            </ion-col>
                                            <ion-col>
                                                <input mode="md" autocomplete="off" maxlength="100" class="customer-form" [(ngModel)]="malo_number" type="text"
                                                    id="malo_number" name="malo_number" value="" placeholder="Marktlokations-ID (optional)">
                                            </ion-col>
                                        </ion-row>
                                        <ion-row>
                                            <ion-col>
                                                <input mode="md" autocomplete="off" maxlength="30" [(ngModel)]="canceled " type="checkbox" class="checkbox"
                                                    id="canceled " name="canceled">
                                                <span class="ion-margin-start">Beim Vorlieferant gekündigt</span>
                                            </ion-col>
                                            <ion-col>
                                                <!-- <ion-datetime mode="md" [(ngModel)]="date_of_cancel"
                                                *ngIf="canceled == true" placeholder="Kündigungsdatum*"
                                                displayFormat="DD/MM/YYYY" doneText="Bestätigen"
                                                cancelText="Abbrechen"></ion-datetime> -->
                                                <input mode="md" autocomplete="off" readonly maxlength="30" class="customer-form" (click)="openDatePickerCancel()" [(ngModel)]="date_of_cancel" type="text"
                                                *ngIf="canceled == true"  id="date_of_cancel" name="date_of_cancel" value=""
                                                placeholder="Kündigungsdatum*">
                                            </ion-col>
                                        </ion-row>

                                    </div>
                                    <div *ngIf="reason == 'moving'">
                                        <ion-row>
                                            <ion-col>

                                                    <input mode="md" autocomplete="off" readonly maxlength="30" class="customer-form" (click)="openDatePickerMoving()" [(ngModel)]="date_of_change" type="text"
                                                    id="date_of_change" name="date_of_change" value=""
                                                    placeholder="Datum des Neueinzug*">
                                            </ion-col>
                                            <ion-col></ion-col>
                                        </ion-row>

                                        <ion-row>
                                            <ion-col>
                                                <input mode="md" autocomplete="off" maxlength="30" class="customer-form" [(ngModel)]="meter_number" type="text"
                                                    id="meter_number" name="meter_number" value=""
                                                    placeholder="Zählernummer">
                                            </ion-col>
                                            <ion-col></ion-col>
                                        </ion-row>

                                        <ion-row>
                                            <ion-col>
                                                <input mode="md" autocomplete="off" maxlength="30" class="customer-form" [(ngModel)]="meter_reading" type="text"
                                                    id="meter_reading" name="meter_reading" value=""
                                                    placeholder="Zählerstand">
                                            </ion-col>
                                            <ion-col></ion-col>
                                        </ion-row>
                                        <ion-row>
                                            <ion-col> <input mode="md" autocomplete="off" maxlength="100" class="customer-form" [(ngModel)]="malo_number" type="text"
                                                    id="malo_number" name="malo_number" value="" placeholder="Marktlokations-ID  (optional)"></ion-col>
                                                    <ion-col> </ion-col>
                                        </ion-row>
                                    </div>
                                </ion-grid>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                    <ion-row class="ion-text-center footer-row">
                        <ion-button mode="md" (click)="openTab(2)">ZURÜCK</ion-button>
                        <ion-button mode="md" (click)="openTab(4)">WEITER</ion-button>
                    </ion-row>
                </div>

                <div *ngIf="currentTab==4">
                    <ion-grid class="maingrid">
                        <ion-row>
                            <ion-col size="4">
                                <ion-card mode="md">
                                    <ion-card-header>
                                        <ion-card-title class="ion-text-center">{{productName}}</ion-card-title>
                                        <ng-container *ngIf="productType == 'Ja';">
                                            <ion-card-subtitle class="ion-text-center">Zu 100% aus regenerativen
                                                Energiequellen
                                            </ion-card-subtitle>
                                        </ng-container>
                                    </ion-card-header>

                                    <ion-card-content class="ion-text-center">
                                        <span class="ecoPrice ion-text-center">
                                            
                                            <span class="underline">{{productPrice}}</span><span
                                                class="year"> €/Jahr*</span><br>
                                            <span class="smaller">*Bei einem Jahresverbrauch von {{kWh}} kWh</span><br>
                                            <span class="smaller small-br" *ngIf="hasBonus == true">Preis beinhaltet {{currentBonus}} € Neukundenbonus im ersten Jahr</span>
                                        </span>
                                    </ion-card-content>


                                    <ion-row class="cardfooter ion-text-center">
                                        <ion-col>
                                            <ng-container *ngIf="productType == 'Ja'; else noEco">
                                                <span class="footerText">Öko Tarif</span>
                                            </ng-container>
                                            <ng-template #noEco>
                                                <span class="footerText">Standard Tarif</span>
                                            </ng-template>                                        </ion-col>
                                    </ion-row>
                                </ion-card>
                            </ion-col>
                            <ion-col size="8">
                                <ion-grid class="form-grid">
                                    <ion-row>
                                        <ion-col>
                                            <ion-select mode="md" class="customer-form" cancelText="Abbrechen"
                                                okText="Bestätigen" [(ngModel)]="payment_method" id="payment_method"
                                                name="payment_method" placeholder="Zahlungsmethode ">
                                                <ion-select-option value="transfer">Überweisung</ion-select-option>
                                                <ion-select-option value="IBAN">Lastschrift</ion-select-option>
                                            </ion-select>
                                        </ion-col>
                                        <ion-col></ion-col>
                                    </ion-row>
                                    <div *ngIf="payment_method =='IBAN'">
                                        <ion-row>
                                            <ion-col>
                                                <input mode="md" autocomplete="off" maxlength="30" class="customer-form" [(ngModel)]="iban" type="text" id="iban"
                                                    name="iban" value="" placeholder="IBAN-Nummer*">
                                            </ion-col>
                                            <ion-col>
                                                <input mode="md" autocomplete="off" maxlength="30" class="customer-form" [(ngModel)]="bic" type="text" id="bic"
                                                name="bic" value="" placeholder="BIC"></ion-col>
                                        </ion-row>
                                        <ion-row>
                                            <ion-col>
                                                <input mode="md" autocomplete="off" maxlength="30" class="customer-form" [(ngModel)]="account_owner" type="text"
                                                    id="account_owner" name="account_owner" value=""
                                                    placeholder="Kontoinhaber*">
                                            </ion-col>
                                            <ion-col></ion-col>
                                        </ion-row>
                                        <ion-row>
                                            <ion-col>
                                            </ion-col>
                                            <ion-col></ion-col>
                                        </ion-row>
                                        <ion-row>
                                            <ion-col>
                                                <input mode="md" autocomplete="off" maxlength="30" [(ngModel)]="sepa " type="checkbox" class="checkbox" id="sepa "
                                                    name="sepa">
                                                <span class="ion-margin-start small">Hiermit ermächtige(n) ich/wir die Stadtwerke Hamm GmbH, Gläubigeridentifikations-Nr. DE8810100000084424, wiederkehrende Zahlungen von meinem/unserem Konto mittels Lastschrift einzuziehen. Die Mandatsreferenz wird gesondert mitgeteilt. Zugleich weise(n) ich mein/wir unser Kreditinstitut an, die von der Stadtwerke Hamm GmbH auf mein/unser Konto gezogenen Lastschriften einzulösen. Hinweis: Ich kann/Wir können innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem/unserem Kreditinstitut vereinbarten Bedingungen.*</span>
                                            </ion-col>
                                        </ion-row>
                                    </div>
                                </ion-grid>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                    <ion-row class="ion-text-center footer-row">
                        <ion-button mode="md" (click)="openTab(3)">ZURÜCK</ion-button>
                        <ion-button mode="md" (click)="openTab(5)">WEITER</ion-button>
                    </ion-row>
                </div>
                <div *ngIf="currentTab==5">
                    <ion-grid class="maingrid">
                        <ion-row>
                            <ion-col size="4">
                                <ion-card mode="md">
                                    <ion-card-header>
                                        <ion-card-title class="ion-text-center">{{productName}}</ion-card-title>
                                        <ng-container *ngIf="productType == 'Ja';">
                                            <ion-card-subtitle class="ion-text-center">Zu 100% aus regenerativen
                                                Energiequellen
                                            </ion-card-subtitle>
                                        </ng-container>
                                    </ion-card-header>

                                    <ion-card-content class="ion-text-center">
                                        <span class="ecoPrice ion-text-center">
                                            
                                            <span class="underline">{{productPrice}}</span><span
                                                class="year"> €/Jahr*</span><br>
                                            <span class="smaller">*Bei einem Jahresverbrauch von {{kWh}} kWh</span><br>
                                            <span class="smaller small-br" *ngIf="hasBonus == true">Preis beinhaltet {{currentBonus}} € Neukundenbonus im ersten Jahr</span>
                                        </span>
                                    </ion-card-content>


                                    <ion-row class="cardfooter ion-text-center">
                                        <ion-col>
                                            <ng-container *ngIf="productType == 'Ja'; else noEco">
                                                <span class="footerText">Öko Tarif</span>
                                            </ng-container>
                                            <ng-template #noEco>
                                                <span class="footerText">Standard Tarif</span>
                                            </ng-template>                                        </ion-col>
                                    </ion-row>
                                </ion-card>
                            </ion-col>
                            <ion-col size="8">
                                <ion-grid class="form-grid">
                                    <!-- TODO Content für Abschluss-->
                                    <ion-row>
                                        <ion-col>
                                            <input mode="md" autocomplete="off" maxlength="30" [(ngModel)]="bill_address_check" (change)="clickedBill($event)" type="checkbox" class="checkbox" id="bill_address_check"
                                            name="bill_address_check">
                                        <span class="ion-margin-start">Rechnungsadresse gleich Lieferadresse</span>
                                        </ion-col>
                                        <ion-col></ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col>
                                            <ion-select mode="md" [disabled]="bill_address_check" class="customer-form" cancelText="Abbrechen"
                                                okText="Bestätigen" [(ngModel)]="formOfAddress_bill" id="formOfAddress_bill"
                                                name="formOfAddress_bill" placeholder="Anrede*">
                                                <ion-select-option value="Herr">Herr</ion-select-option>
                                                <ion-select-option value="Frau">Frau</ion-select-option>
                                                <ion-select-option value="Firma">Firma</ion-select-option>
                                                <ion-select-option value="Eheleute">Eheleute</ion-select-option>
                                                <ion-select-option value="Eigentümergemeinschaft">Eigentümergemeinschaft</ion-select-option>
                                                <ion-select-option value="Wohngemeinschaft">Wohngemeinschaft</ion-select-option>
                                                <ion-select-option value="Hausverwaltung">Hausverwaltung</ion-select-option>
                                                <ion-select-option value="Bürogemeinschaft">Bürogemeinschaft</ion-select-option>
                                                <ion-select-option value="Erbengemeinschaft">Erbengemeinschaft</ion-select-option>
                                                <ion-select-option value="Immobilienverwaltung">Immobilienverwaltung</ion-select-option>
                                                <ion-select-option value="Wohnungsgesellschaft">Wohnungsgesellschaft</ion-select-option>
                                                <ion-select-option value="Bauunternehmung">Bauunternehmung</ion-select-option>
                                                <ion-select-option value="Bauherrengemeinschaft">Bauherrengemeinschaft</ion-select-option>
                                                <ion-select-option value="Praxisgemeinschaft">Praxisgemeinschaft</ion-select-option>
                                                <ion-select-option value="Grundstücksgemeinschaft">Grundstücksgemeinschaft</ion-select-option>
                                                <ion-select-option value="Hausgemeinschaft">Hausgemeinschaft</ion-select-option>
                                                <ion-select-option value="Kostengemeinschaft">Kostengemeinschaft</ion-select-option>
                                                <ion-select-option value="">-</ion-select-option>
                                            </ion-select>
                                        </ion-col>
                                        <ion-col>
                                            <ng-container *ngIf="formOfAddress_bill != 'Herr' && formOfAddress_bill != 'Frau' && formOfAddress_bill != 'Eheleute'; else noOrgaBillTemplate">

                                               <input mode="md" autocomplete="off" maxlength="55" [disabled]="bill_address_check" class="customer-form" [(ngModel)]="title_bill" type="text" id="title"
                                                    name="title" value="" placeholder="Firma/Organisation">
                                            </ng-container>
                                            <ng-template #noOrgaBillTemplate>
    
                                                <input mode="md" autocomplete="off" maxlength="30" [disabled]="bill_address_check" class="customer-form" [(ngModel)]="title_bill" type="text" id="title"
                                                    name="title" value="" placeholder="Titel">
    
                                            </ng-template>  
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col>
                                            <input mode="md" autocomplete="off" maxlength="30" [disabled]="bill_address_check" class="customer-form"
                                                [(ngModel)]="name_bill" type="text" id="name_bill"
                                                name="name_bill" value="" placeholder="Vorname*">
                                        </ion-col>
                                        <ion-col>
                                            <input mode="md" autocomplete="off" maxlength="30" [disabled]="bill_address_check" class="customer-form"
                                                [(ngModel)]="surname_bill" type="text" id="surname_bill"
                                                name="surname_bill" value="" placeholder="Nachname*">
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col>
                                            <input mode="md" autocomplete="off" maxlength="30" [disabled]="bill_address_check" class="customer-form"
                                                [(ngModel)]="postal_code_bill" type="text" id="postal_code_bill" name="postal_code_bill" value=""
                                                placeholder="PLZ*">
                                        </ion-col>
                                        <ion-col>
                                            <input mode="md" autocomplete="off" maxlength="30" [disabled]="bill_address_check" class="customer-form"
                                                [(ngModel)]="city_bill" type="text" id="city_bill" name="city_bill"
                                                value="" placeholder="Ort*">
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col>
                                            <input mode="md" autocomplete="off" maxlength="30" [disabled]="bill_address_check" class="customer-form"
                                                [(ngModel)]="street_bill" type="text" id="street_bill"
                                                name="street_bill" value="" placeholder="Straße*">
                                        </ion-col>
                                        <ion-col>
                                            <input mode="md" autocomplete="off" maxlength="30" [disabled]="bill_address_check" class="customer-form"
                                                [(ngModel)]="house_number_bill" type="text" id="house_number_bill" name="house_number_bill" value=""
                                                placeholder="Hausnummer*">
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col>
                                        </ion-col>
                                        <ion-col>
                                        </ion-col>
                                    </ion-row>
                                </ion-grid>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                    <ion-row class="ion-text-center footer-row">
                        <ion-button mode="md" (click)="openTab(4)">ZURÜCK</ion-button>
                        <ion-button mode="md" (click)="openTab(6)">WEITER</ion-button>
                    </ion-row>
                </div>
                <div *ngIf="currentTab==6">
                    <ion-grid class="maingrid">
                        <ion-row>
                            <ion-col size="4">
                                <ion-card mode="md">
                                    <ion-card-header>
                                        <ion-card-title class="ion-text-center">{{productName}}</ion-card-title>
                                        <ng-container *ngIf="productType == 'Ja';">
                                            <ion-card-subtitle class="ion-text-center">Zu 100% aus regenerativen
                                                Energiequellen
                                            </ion-card-subtitle>
                                        </ng-container>
                                    </ion-card-header>

                                    <ion-card-content class="ion-text-center">
                                        <span class="ecoPrice ion-text-center">
                                            
                                            <span class="underline">{{productPrice}}</span><span
                                                class="year"> €/Jahr*</span><br>
                                            <span class="smaller">*Bei einem Jahresverbrauch von {{kWh}} kWh</span><br>
                                            <span class="smaller small-br" *ngIf="hasBonus == true">Preis beinhaltet {{currentBonus}} € Neukundenbonus im ersten Jahr</span>
                                        </span>
                                    </ion-card-content>

                                    <ion-row class="cardfooter ion-text-center">
                                        <ion-col>
                                            <ng-container *ngIf="productType == 'Ja'; else noEco">
                                                <span class="footerText">Öko Tarif</span>
                                            </ng-container>
                                            <ng-template #noEco>
                                                <span class="footerText">Standard Tarif</span>
                                            </ng-template>                                        </ion-col>
                                    </ion-row>
                                </ion-card>
                            </ion-col>
                            <ion-col size="8">
                                <ion-grid class="form-grid">
                                    <!-- TODO Content für Abschluss-->
                                    <ion-row>
                                        <ion-col>
                                            <ng-container *ngIf="currentProductType == 'Strom'; else gasTemplate">
                                                <input mode="md" autocomplete="off" maxlength="30" [(ngModel)]="agb" type="checkbox" class="checkbox" id="agb"
                                                name="agb">
                                                <span class="ion-margin-start">Kenntnisnahme der <a  class="agb"  (click)="openPDF('agb')">AGB</a>*</span>
                                            </ng-container>
                                            <ng-template #gasTemplate>
                                                <input mode="md" autocomplete="off" maxlength="30" [(ngModel)]="agb" type="checkbox" class="checkbox" id="agb"
                                                name="agb">
                                            <span class="ion-margin-start">Kenntnisnahme der <a  class="agb"  (click)="openPDF('agb_gas')">AGB</a>*</span>
                                            </ng-template>
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col>
                                            <input mode="md" autocomplete="off" maxlength="30" [(ngModel)]="data_privacy" type="checkbox" class="checkbox"
                                                id="privacy" name="privacy">
                                            <span class="ion-margin-start">Kenntnisnahme der
                                                <a  class="agb"  (click)="openPDF('datasecurity')">Datenschutzbestimmungen</a>*</span>
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col>
                                            <input mode="md" autocomplete="off" maxlength="30" [(ngModel)]="withdrawal" type="checkbox" class="checkbox"
                                                id="withdrawal" name="withdrawal">
                                            <span class="ion-margin-start">Kenntnisnahme der
                                                <a class="agb"  (click)="openPDF('refusal')">Widerrufsbelehrung</a >*</span>
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col>
                                            <input mode="md" autocomplete="off" maxlength="30" [(ngModel)]="productinformation" type="checkbox" class="checkbox"
                                                id="product_information" name="product_information">
                                            <span class="ion-margin-start">Kenntnisnahme der
                                                <a class="agb" (click)="openPDF('productinformation')">Produktinformationen</a >*</span>
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col>
                                            <input mode="md" autocomplete="off" maxlength="30" [(ngModel)]="newsletter" type="checkbox" class="checkbox"
                                            id="advertisement" name="advertisement">
                                        <span class="ion-margin-start"><a class="agb" (click)="openPDF('advertisement')">Werbeeinwilligung</a ></span>
                                        </ion-col>
                                    </ion-row>
                                </ion-grid>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                    <ion-row class="ion-text-center footer-row">
                        <ion-button mode="md" class="generateLink" (click)="checkAndRoute(false)">Link generieren</ion-button>
                        <ion-button mode="md" (click)="openTab(5)">ZURÜCK</ion-button>
                        <ion-button mode="md" (click)="checkAndRoute(true)">UNTERSCHRIFT</ion-button>
                    </ion-row>
                </div>
            </div>
        </div>

    </ion-content>
</ion-app>